/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState } from "react";
import { capitalize, extractErrorMessage } from "utils/strings";
import { truncateString, replaceUnderscoresWithSpaces } from "utils/strings";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useUpdateAdminWebinarMutation,
  useDeleteAdminWebinarMutation,
} from "store/services/adminAPI";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Episodes } from "./Episodes";
import axios_instance from "store/constant/axiosInstance";

export const COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.title)}`;
    },
  },

  {
    Header: "Description",
    accessor: "description",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.description))}`;
    },
  },

  {
    Header: "Episodes",
    accessor: "episodes",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${cell?.row?.original?.episodes.length}`;
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const { title, id, description, thumbnail } = cell?.row?.original;
      const [uploading, setUploading] = useState(false);

      const [showEpisodes, setShowEpisodes] = useState(false);
      const [update, setUpdate] = useState(false);

      const [updateAdminWebinar, { isLoading: updatingWebinar }] =
        useUpdateAdminWebinarMutation();

      const [deleteAdminWebinar] = useDeleteAdminWebinarMutation();

      const FormSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
      });

      const handleSubmit = async (values) => {
        await updateAdminWebinar({
          ...values,
          id,
        })
          .unwrap()
          .then((data) => {
            toast.success(`✔️ ${data?.message} !`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setUpdate(false);
          })
          .catch((error) => {
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) ||
                "Error Occured",
              text: error?.data?.detail,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      };

      const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

        if (file) {
          const fileType = file.type;
          if (allowedFileTypes.includes(fileType)) {
            if (file.size > 2 * 1024 * 1024) {
              toast.error("❌ File size exceeds the 2MB limit.!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            } else {
              const formData = new FormData();
              file && formData.append("file", file);
              try {
                setUploading(true);
                const response = await axios_instance.post(
                  "core/upload/file",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                toast.success(`✔️ Image uploaded!`, {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
                formik.setFieldValue("thumbnail", response?.data?.data?.url);
                setUploading(false);
              } catch (e) {
                setUploading(false);
                toast.error("❌ Error occured uploading image!", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              }
            }
          } else {
            toast.error("❌ Only image files are allowed. !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        }
      };

      const formik = useFormik({
        initialValues: {
          title,
          description,
          thumbnail: thumbnail ?? "",
        },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
      });

      const handleDelete = (webinarId) => {
        Swal.fire({
          title: "Notice",
          text: "You are trying to deletea webinar and all its episodes.",
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          showCancelButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Cancel",
          cancelButtonText: "Delete",
        }).then(async (result) => {
          if (!result.isConfirmed) {
            await deleteAdminWebinar(webinarId)
              .unwrap()
              .then((data) => {
                toast.success(`✔️ ${data?.message} !`, {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              })
              .catch((error) => {
                const formattedError = extractErrorMessage(error)
                Swal.fire({
                  title:
                    replaceUnderscoresWithSpaces(error?.data?.code) ||
                    "Error Occured",
                  text: formattedError,
                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                  showCloseButton: true,
                  customClass: {
                    icon: "no-border",
                  },
                  confirmButtonText: "Try Again",
                });
              });
          }
        });
      };

      return (
        <>
          <Episodes
            showEpisodes={showEpisodes}
            setShowEpisodes={setShowEpisodes}
            record={cell?.row?.original}
          />

          <Modal className="modal fade" show={update}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Webinar</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setUpdate(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form className="comment-form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Title</strong>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          placeholder="Title"
                          {...formik.getFieldProps("title")}
                        />

                        {formik.touched.title && formik.errors.title ? (
                          <div className="text-danger mt-2 fs-12">
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Description</strong>
                        </label>

                        <textarea
                          className="w-100 form-control"
                          placeholder="Your answer"
                          id="description"
                          name="description"
                          {...formik.getFieldProps("description")}
                          rows="3"
                        ></textarea>

                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger mt-2 fs-12">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-1">
                        <label htmlFor="formFile" className="form-label">
                          Upload Image
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          accept=".jpeg, .jpg, .png"
                          onChange={handleFileChange}
                        />
                      </div>

                      {formik.errors.thumbnail ? (
                        <div className="text-danger mt-2 fs-12">
                          {formik.errors.thumbnail}
                        </div>
                      ) : null}

                      <div className="d-flex align-items-center mb-3">
                        {uploading && (
                          <>
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                            <p className="m-0 ml-2">Uploading Image....</p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 d-flex justify-content-end">
                      <div className="form-group mb-3">
                        <button
                          type="submit"
                          disabled={updatingWebinar || uploading}
                          className="submit btn btn-pigment-green"
                        >
                          {updatingWebinar  ? (
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                          ) : (
                            `Update Webinar`
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowEpisodes(true)}>
                View Webinar
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setUpdate(true)}>
                Update Webinar
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleDelete(id);
                }}
              >
                Delete Webinar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];
