/** @format */

import React, { useState } from "react";
import AdminLayout from "views/admin/Admin";
import { Col, Tab, Nav } from "react-bootstrap";
import RolesPage from "./Roles";
import PermissionsPage from "./Permissions";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const AdminRoles = () => {
  usePermissionCheck(Page.ROLES)
  const [activeTab, setActive] = useState("roles");

  const tabData = [
    {
      name: "Roles",
      title: "roles",
    },
    {
      name: "Permissions",
      title: "permissions",
    },
  ];

  return (
    <AdminLayout>
      <div className="row">
        <>
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">Roles & Permissions</h3>
          </div>

          <div className="row">
            <Col xl={12}>
              <section className="custom-tab-1">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data) => (
                      <Nav.Item
                        as="li"
                        key={data.title}
                        onClick={() => setActive(data.title)}
                      >
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>

                  {activeTab === "roles" && <RolesPage />}
                  {activeTab === "permissions" && <PermissionsPage />}
                </Tab.Container>
              </section>
            </Col>
          </div>
        </>
      </div>
    </AdminLayout>
  );
};
export default AdminRoles;
