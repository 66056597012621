import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUpdateFellowCertificatesMutation } from "store/services/adminAPI";

const statusOptions = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];

export const UpdateModal = ({ showUpdateModal, setShowUpdateModal, data }) => {
  const [updateCertificate, { isLoading }] = useUpdateFellowCertificatesMutation();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        status: values.status.value,
        id: data.id
      };

      // Only include comment in payload if it's not empty
      if (values.status.value === "rejected" && values.comment.trim()) {
        payload.comment = values.comment;
      }

      await updateCertificate(payload).unwrap();
      toast.success("Status updated successfully");
      setShowUpdateModal(false);
      resetForm();
    } catch (error) {
      toast.error("Failed to update status");
    }
  };

  const formik = useFormik({
    initialValues: {
      status: statusOptions.find(option => option.value === data?.status) || null,
      comment: data?.comment || "",
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <Modal
      show={showUpdateModal}
      onHide={() => setShowUpdateModal(false)}
      className="fade"
      id="progressModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Certificate Status</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Select
              value={formik.values.status}
              onChange={(selectedStatus) => formik.setFieldValue("status", selectedStatus)}
              options={statusOptions}
              isSearchable={false}
              placeholder="Select status"
            />
            {formik.touched.status && formik.errors.status && (
              <Form.Text className="text-danger">{formik.errors.status}</Form.Text>
            )}
          </Form.Group>
          {formik.values.status && formik.values.status.value === "rejected" && (
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...formik.getFieldProps("comment")}
                isInvalid={formik.touched.comment && formik.errors.comment}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comment}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update Status"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const validationSchema = Yup.object().shape({
  status: Yup.object().required("Status is required"),
  comment: Yup.string().when("status", {
    is: (status) => status && status.value === "rejected",
    then: () => Yup.string().required("Please provide a reason for rejection"),
    otherwise: () => Yup.string(),
  }),
});