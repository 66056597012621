import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useGetCohortsQuery, useCreateAdminSurveyMutation, useUpdateAdminSurveyMutation } from "store/services/adminAPI";
import { useGetAllCoursesQuery } from "store/services/notification";
import Select from "react-select";
import { Style } from "utils/select";
import { FellowOptions } from "utils/strings";
import * as XLSX from 'xlsx';

const CreateSurvey = ({ showCreate, setShowCreate, mode = 'create', existingData = null }) => {
  const [newCohort, setNewCohort] = useState([]);
  const [fellowStatuses, setNewFellowStatuses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [targetEmails, setTargetEmails] = useState('');

  const { data: cohortsData } = useGetCohortsQuery("");
  const { data: coursesData } = useGetAllCoursesQuery("");

  const [createSurvey, { isLoading: isCreating }] = useCreateAdminSurveyMutation();
  const [updateSurvey, { isLoading: isUpdating }] = useUpdateAdminSurveyMutation();

  const isLoading = mode === 'create' ? isCreating : isUpdating;

  const formikRef = useRef(null);

  const CohortOptions = useMemo(() => {
    return cohortsData?.data?.cohorts?.map((cohort) => ({
      value: cohort?.id,
      label: `Cohort ${cohort?.rank}`,
    })) || [];
  }, [cohortsData]);

  const CoursesOptions = useMemo(() => {
    return coursesData?.data?.courses?.map((course) => ({
      value: course?.id,
      label: course?.title,
    })) || [];
  }, [coursesData]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      link: "",
      target_cohorts: "",
      target_statuses: "",
      target_courses: ""
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    formikRef.current = formik;
  }, [formik]);

  useEffect(() => {
    if (mode === 'update' && existingData) {
      setNewCohort(existingData?.target_cohorts ? existingData.target_cohorts.split(',').map(id => CohortOptions.find(option => option.value === id)) : []);
      setCourses(existingData?.target_courses ? existingData.target_courses.split(',').map(id => CoursesOptions.find(option => option.value === id)) : []);
      setNewFellowStatuses(existingData?.target_statuses ? existingData.target_statuses.split(',').map(status => FellowOptions.find(option => option.value === status)) : []);
      setTargetEmails(existingData.target_fellows || '');

      formikRef.current.setValues({
        name: existingData.name || "",
        description: existingData.description || "",
        link: existingData.link || "",
        target_cohorts: existingData.target_cohorts || "",
        target_statuses: existingData.target_statuses || "",
        target_courses: existingData.target_courses || ""
      });
    }
  }, [mode, existingData, CohortOptions, CoursesOptions]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
        
        const columnIndex = jsonData[0].indexOf('Email');
        if (columnIndex === -1) {
          toast.error("'Email' column is missing.");
          return;
        }
        
        const emailList = jsonData.slice(1).map(row => row[columnIndex]);
        if (emailList.length > 100) {
          toast.error("More than 100 emails provided.");
          return;
        }

        const emailString = jsonData.slice(1).map(row => row[columnIndex]).join(',');
        setTargetEmails(emailString);
      } catch (error) {
        toast.error("Error processing file: " + error.message);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  async function handleSubmit(values) {
    try {
      const surveyData = {
        ...values,
        target_fellows: targetEmails,
        target_cohorts: values.target_cohorts || '',
        target_courses: values.target_courses || '',
        target_statuses: values.target_statuses || ''
      };
      
      if (mode === 'create') {
        await createSurvey(surveyData).unwrap();
        toast.success("Survey Created!");
      } else {
        await updateSurvey({ id: existingData.id, ...surveyData }).unwrap();
        toast.success("Survey Updated!");
      }
      
      setShowCreate(false);
    } catch (error) {
      toast.error(error.data?.error || 'An error occurred');
    }
  }

  return (
    <Modal className="modal fade" show={showCreate} onHide={() => setShowCreate(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Create New Survey' : 'Update Survey'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <label className="mb-1"><strong>Survey Name</strong></label>
            <input
              type="text"
              className="form-control"
              {...formik.getFieldProps("name")}
              placeholder="Enter the name of this survey"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger mt-2 fs-12">{formik.errors.name}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-1"><strong>Survey Description</strong></label>
            <textarea
              className="form-control"
              {...formik.getFieldProps("description")}
              rows="3"
              placeholder="Enter the description of this survey"
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <div className="text-danger mt-2 fs-12">{formik.errors.description}</div>
            )}
          </div>

          {CohortOptions.length > 0 && (
            <div className="form-group mb-3">
              <label className="mb-1"><strong>Target Cohorts</strong></label>
              <Select
                value={newCohort}
                onChange={(selectedOptions) => {
                  setNewCohort(selectedOptions);
                  formik.setFieldValue('target_cohorts', selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');
                }}
                options={CohortOptions}
                styles={Style}
                isMulti
              />
            </div>
          )}

          {CoursesOptions.length > 0 && (
            <div className="form-group mb-3">
              <label className="mb-1"><strong>Target Courses</strong></label>
              <Select
                value={courses}
                onChange={(selectedOptions) => {
                  setCourses(selectedOptions);
                  formik.setFieldValue('target_courses', selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');
                }}
                options={CoursesOptions}
                styles={Style}
                isMulti
              />
            </div>
          )}

          <div className="form-group mb-3">
            <label className="mb-1"><strong>Target Fellow Statuses</strong></label>
            <Select
              value={fellowStatuses}
              onChange={(selectedOptions) => {
                setNewFellowStatuses(selectedOptions);
                formik.setFieldValue('target_statuses', selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');
              }}
              options={FellowOptions}
              styles={Style}
              isMulti
            />
          </div>

          <div className="form-group mb-3">
            <label className="mb-1" htmlFor="bulkFellowsFile">
              <strong>Target Fellows Emails (upload excel file)</strong>
            </label>
            <input
              className="form-control"
              type="file"
              id="bulkFellowsFile"
              accept=".xls, .xlsx, .csv"
              onChange={handleFileChange}
            />
          </div>

          <div className="form-group mb-3">
            <label className="mb-1"><strong>Survey Link</strong></label>
            <input
              type="text"
              className="form-control"
              {...formik.getFieldProps("link")}
              placeholder="Enter survey link"
            />
            {formik.touched.link && formik.errors.link && (
              <div className="text-danger mt-2 fs-12">{formik.errors.link}</div>
            )}
          </div>

          <div className="d-flex justify-content-end">
            <Button type="submit" className="btn btn-pigment-green" disabled={isLoading}>
              {isLoading ? (mode === 'create' ? 'Creating...' : 'Updating...') : (mode === 'create' ? 'Create Survey' : 'Update Survey')}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSurvey;

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Survey Name is required"),
  description: Yup.string().required("Survey Description is required"),
  link: Yup.string()
    .required("Survey Link is required")
    .url("Invalid URL. Please enter a valid link."),
  target_cohorts: Yup.string(),
  target_statuses: Yup.string(),
  target_courses: Yup.string(),
  target_fellows: Yup.string()
});