/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { capitalize, extractErrorMessage } from "utils/strings";

import PerfectScrollbar from "react-perfect-scrollbar";
import { ReactComponent as MoreIcon } from "images/svg/more.svg";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { replaceUnderscoresWithSpaces } from "utils/strings";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CreateEpisode from "./CreateEpisode";
import { useDeleteWebinarEpisodeMutation } from "store/services/adminAPI";
import UpdateEpisode from "./UpdateEpisode";

export const Episodes = ({ showEpisodes, setShowEpisodes, record }) => {
  const { title, id, description, episodes } = record;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [activeRecord, setActive] = useState(null);

  const [deleteAdminWebinar, { isLoading: isDeleting }] =
    useDeleteWebinarEpisodeMutation();

  return (
    <>
      <Modal
        show={showEpisodes}
        onHide={() => setShowEpisodes(false)}
        className="modal fade"
        id="statusModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title"> Webinar Details</h3>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowEpisodes(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="col-xl-12">
              <div className="card course-dedails-bx">
                <div className="card-header border-0 pb-0">
                  <h3>{capitalize(title)}</h3>
                </div>
                <div className="card-body pt-0">
                  <div className="description">
                    <div className="user-pic mb-3">
                      <span>{capitalize(description)}</span>
                    </div>
                  </div>
                  <div className="description mt-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4>Episodes</h4>
                      <button
                        onClick={() => setCreate(true)}
                        className="btn btn-primary"
                      >
                        + Add
                      </button>
                    </div>

                    {episodes && episodes?.length > 0 ? (
                      <div className="d-flex flex-column mt-4">
                        <PerfectScrollbar
                          id="DZ_W_TimeLine"
                          className="widget-media dlab-scroll height-250 ps ps--active-y"
                        >
                          <ul className="timeline">
                            {episodes?.map((episode) => {
                              return (
                                <li key={episode?.id}>
                                  <div className="timeline-panel">
                                    <a
                                      href={episode?.link}
                                      rel="noreferrer"
                                      target="_blank"
                                      className="media me-2 media-success"
                                    >
                                      <i className="fa fa-film"></i>
                                    </a>
                                    <div className="media-body">
                                      <h5 className="mb-1">{episode?.title}</h5>
                                      <a
                                        className="d-block"
                                        href={episode?.link}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        View on Youtube
                                      </a>
                                    </div>
                                    <Dropdown className="dropdown">
                                      <Dropdown.Toggle
                                        variant="light"
                                        className=" btn-success i-false p-0 sharp"
                                      >
                                        {episode?.id === activeRecord?.id &&
                                        isDeleting ? (
                                          <span
                                            role="status"
                                            aria-hidden="true"
                                            className="spinner-border spinner-border-sm ms-2"
                                          ></span>
                                        ) : (
                                          <MoreIcon />
                                        )}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={() => {
                                            setActive(episode);
                                            setUpdate(true);
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={() => {
                                            setActive(episode);
                                            Swal.fire({
                                              title: "Notice",
                                              text: `You are trying to delete this episode ( ${capitalize(
                                                episode?.title
                                              )}) `,
                                              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                              showCloseButton: true,
                                              showCancelButton: true,
                                              customClass: {
                                                icon: "no-border",
                                              },
                                              confirmButtonText: "Cancel",
                                              cancelButtonText: "Delete",
                                            }).then(async (result) => {
                                              if (!result.isConfirmed) {
                                                await deleteAdminWebinar({
                                                  webinar: id,
                                                  episode: episode?.id,
                                                })
                                                  .unwrap()
                                                  .then((data) => {
                                                    toast.success(
                                                      `✔️ ${data?.message} !`,
                                                      {
                                                        position: "top-right",
                                                        autoClose: 2500,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                      }
                                                    );
                                                  })
                                                  .catch((error) => {
                                                    const formattedError = extractErrorMessage(error)
                                                    Swal.fire({
                                                      title:
                                                        replaceUnderscoresWithSpaces(
                                                          error?.data?.code
                                                        ) || "Error Occured",
                                                      text: formattedError,
                                                      iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                                      showCloseButton: true,
                                                      customClass: {
                                                        icon: "no-border",
                                                      },
                                                      confirmButtonText:
                                                        "Try Again",
                                                    });
                                                  });
                                              }
                                            });
                                          }}
                                        >
                                          {episodes?.id === activeRecord?.id &&
                                          isDeleting ? (
                                            <span
                                              role="status"
                                              aria-hidden="true"
                                              className="spinner-border spinner-border-sm ms-2"
                                            ></span>
                                          ) : (
                                            `Delete`
                                          )}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </PerfectScrollbar>
                      </div>
                    ) : (
                      <div className="d-flex flex-column mt-4">
                        <div className="d-flex justify-content-center">
                          <BagIcon />
                        </div>
                        <div className="d-flex justify-content-center">
                          <p>No episode for this webinar at the moment</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    variant="primary"
                    onClick={(e) => setShowEpisodes(false)}
                  >
                    Close
                  </Button>
                </div> */}
          </div>
        </div>
      </Modal>
      <CreateEpisode create={create} setCreate={setCreate} webinar_id={id} />
      <UpdateEpisode
        update={update}
        setUpdate={setUpdate}
        webinar_id={id}
        record={activeRecord}
      />
    </>
  );
};
