/** @format */

import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { Style } from "utils/select";
import axios_instance from "store/constant/axiosInstance";

export const MatchingModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
  setSelectedFellowIds,
}) => {
  const [selectedCohorts, setCohorts] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const { data } = useGetCohortsQuery("");

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      
      console.log("selected ids", selectedIds);
      formData.append('method', 'select');
      formData.append('cohort_id', selectedCohorts?.value);
      selectedIds.map(item => formData.append('fellows[]', item))
      const response = await axios_instance.post("admin/match/fellows/to/provider", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      toast.success(`✔️ ${response.data.message} !`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
  
      setSelectedIds([]);
      setSelectedFellowIds([]);
      setAction("");
      setIsLoading(false);
    } catch (error) {

     toast.error(`❌ ${error?.response?.data?.detail}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setIsLoading(false);
    }
  };

  const CohortOptions =
    data?.data?.cohorts?.length > 0
      ? data?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  return (
    <Modal
      show={action === "match_fellows" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Match Fellows to a Cohort</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
              <div className="card-header border-0 pb-0">
                <h5>Select Cohort</h5>
              </div>
              <div className="card-body pt-0">
                {/* <p>selectedCohorts:: {JSON.stringify(selectedCohorts)}</p> */}
                <div className="description">
                  <Select
                    value={selectedCohorts}
                    onChange={setCohorts}
                    options={CohortOptions}
                    styles={Style}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={
                selectedCohorts && selectedIds?.length > 0 ? false : true
              }
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Match Fellows`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
