/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "store/reducers/AuthReducer";
import { useDispatch } from "react-redux";
import loginbg from "images/bg-1.svg";
import { useLoginUserMutation } from "store/services/authAPI";
import {
  extractErrorMessage,
  replaceUnderscoresWithSpaces,
} from "utils/strings";
import MMLogo from "images/3MTT/3MM.png";
import { Path } from "utils/constant.data";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFlag } from "context/FeatureFlags";

const LoginPage = () => {
  const { features } = useFlag();

  const dispatch = useDispatch();
  const [isShown, setIsSHown] = useState(false);

  const [loginUser, { isLoading: authenticating }] = useLoginUserMutation();

  const handleSubmit = async (values) => {
    await loginUser(values)
      .unwrap()
      .then((data) => {
        dispatch(login(data));
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error);
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>

                      <h4 className="text-center mb-4 ">Admin Login</h4>

                      <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Email </strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="e.g. example@domainname.com"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group mb-3">
                          <label>
                            {" "}
                            <strong>Password</strong>
                          </label>

                          <div className="password__container">
                            <input
                              type={isShown ? "text" : "password"}
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                              {...formik.getFieldProps("password")}
                            ></input>
                            <button type="button" onClick={togglePassword}>
                              <div class="icon">
                                {isShown ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </div>
                            </button>
                          </div>

                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="row d-flex justify-content-between mt-2 mb-2">
                          <div className="mb-3 basis-half">
                            <div className="form-check custom-checkbox ms-1">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <div className="mb-3 basis-half d-flex justify-content-end align-items-center">
                            <p className="">
                              <Link
                                className="text-primary"
                                to={Path.FORGOT_PASSWORD}
                              >
                                Forgot Password
                              </Link>
                            </p>
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {authenticating ? "Authenticating....." : "Login"}
                          </button>
                        </div>
                        {features?.prodMatchingEnabled && (
                          <p>prod matching</p>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
});
