import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FadeLoader } from "react-spinners";

const VerificationModal = ({ show, handleClose, handleConfirmation, loading, error }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <>
          {error ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <h5 className="text-danger">{error}</h5>
            </div>
          ) : (
            <>
              <Modal.Body>Are you sure you want to proceed with the bulk action?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
                <Button variant="primary" onClick={() => handleConfirmation(true)}>
                  Yes
                </Button>
              </Modal.Footer>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default VerificationModal;
