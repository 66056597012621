/** @format */

import React, { useState, useEffect } from "react";
import { useGetUsersQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import UsersTable from "views/admin/table/Users/UsersTable";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const UsersAdmin = () => {
  usePermissionCheck(Page.USERS);
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState("page=1");

const {
    data,
    isLoading: fetchingUsers,
    isFetching,
  } = useGetUsersQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);



    // console.log('permissions',data?.data?.results)


  return (
    <section className="mt-5">
      {fetchingUsers ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <UsersTable
          users={data?.data?.results?.filter((_) => _?.role?.slug !== "software_developer")}
          metadata={data?.data?.metadata}
          setFilter={setFilter}
          setSkip={setSkip}
          filtering={fetchingUsers || isFetching}
          filter={filter}
        />
      )}
    </section>
  );
};
export default UsersAdmin;
