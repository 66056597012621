export const extractFilterValues = (filter) => {
    const res = {};
  
    for (const key of Object.keys(filter)) {
      if (key === "gender" ||  key === "cohort_id" || key ==="provider_id" || key === "branch_id" || key === "training_progress" ){
        res[key] = filter[key];
      } else {
        const values = filter[key].map((value) => value?.value);
        res[key] = values;
      }
    }
  
    return res;
  };

  export const filterArrayByKey = (searchTerm, array) => {
    const regex = new RegExp(searchTerm, "i"); // 'i' flag for case-insensitive search
    return array.filter((item) => regex.test(item.name));
  };