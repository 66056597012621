import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const CourseChart = ({ course_data }) => {
  const filteredData = course_data.filter(item => item.name !== null);

  const categories = filteredData.map(item => item.name);
  const seriesData = filteredData.map(item => item.count);


  const [chartData] = useState({
    series: [{
      name: 'Count',
      data: seriesData
    }],
    options: {
      chart: {
        type: 'bar',
        height: 1000,
        width: 1000,
        toolbar: {
          show: true,
          export: {
            csv: {
              filename: 'course_data'
            },
            png: {
              filename: 'course_chart'
            },
            svg: {
              filename: 'course_chart'
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categories,
        type: 'category',
      },
      grid: {
        strokeDashArray: 3
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      legend: {
        show: true,
        position: 'top'
      },
      colors: ['#26a65b']
    },
  });

  return (
    <div>
      {
        filteredData.length > 0 ? (
          <div className="bar-chart">
            <Chart options={chartData.options} series={chartData.series} type="bar" height={filteredData.length * 70} width={1000} />
          </div>
        ): (
          <p>No data available under this category</p>
        )
      }
    </div>
  );
};

export default CourseChart;
