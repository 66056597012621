import React, { useState, useEffect } from "react";
import AdminLayout from "views/admin/Admin";
import { FadeLoader } from "react-spinners";
import MatchesTable from "views/admin/table/Matching/MatchesTable";
import { useGetMatchedProviderBranchesQuery } from "store/services/adminAPI";

import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";




const AdminMatching = () => {
  usePermissionCheck(Page.LEARNING_TRACKER)
  const [filter, setFilter] = useState("page=1");
  const [skip, setSkip] = useState(false);


  const {
    data,
    isLoading,
    isFetching: filtering
  } = useGetMatchedProviderBranchesQuery(filter, {
    skip:skip
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);


  return (
    <AdminLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
            <div>
          <MatchesTable
            matchesHistory={data?.data?.results}
            isLoading={isLoading || filtering}
            metadata={data?.data?.metadata}
            filter ={filter}
            setFilter={setFilter}
          />
            </div>
        )}
      </div>
    </AdminLayout>
  );
};
export default AdminMatching;
