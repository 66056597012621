/** @format */

import dayjs from "dayjs";
import "dayjs/locale/en";
import format from "date-fns/format";

export const formatDate = (rawDate) => {
  if (rawDate) {
    const parsedDate = dayjs(rawDate);

    parsedDate.locale("en");

    const formattedDate = parsedDate.format("ddd, D MMMM, HH:mm");

    return formattedDate;
  } else {
    return "";
  }
};

export const formatDateWithoutTime = (rawDate) => {
  if (rawDate) {
    const parsedDate = dayjs(rawDate);

    parsedDate.locale("en");

    const formattedDate = parsedDate.format("ddd, D MMMM, YYYY");

    return formattedDate;
  } else {
    return "";
  }
};



export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'yyyy-MM-dd');
};

export const getWeekDifference = (startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const differenceMs = endDateObj - startDateObj;

  const millisecondsInWeek = 1000 * 60 * 60 * 24 * 7;
  const differenceWeeks = Math.round(differenceMs / millisecondsInWeek);

  return differenceWeeks;

}
