/** @format */

import React, { useState } from "react";
import { Col, Tab, Nav } from "react-bootstrap";
import AdminLayout from "views/admin/Admin";
import Submissions from "./Submissions";
import Assignments from "./Assignments";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const AdminAssesment = () => {
  usePermissionCheck(Page.ASSESMENT);
  const [activeTab, setActive] = useState("assignments");
  // const [activeTab, setActive] = useState("submissions");

  const tabData = [
    {
      name: "Assignments",
      title: "assignments",
    },
    {
      name: "Submissions",
      title: "submissions",
    },
  ];

  return (
    <AdminLayout>
      <div className="row">
        <>
          <div className="widget-heading d-flex justify-content-between align-items-center">
            <h3 className="m-0">
              {activeTab === "assignments" ? "Assignments" : "Submissions"}
            </h3>
          </div>

          <div className="row">
            <Col xl={12}>
              <section className="custom-tab-1">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data) => (
                      <Nav.Item
                        as="li"
                        key={data.title}
                        onClick={() => setActive(data.title)}
                      >
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>

                  {activeTab === "assignments" && <Assignments />}
                  {activeTab === "submissions" && <Submissions />}
                </Tab.Container>
              </section>
            </Col>
          </div>
        </>
      </div>
    </AdminLayout>
  );
};
export default AdminAssesment;
