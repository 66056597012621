/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  capitalize,
  truncateString,
  replaceUnderscoresWithSpaces,
  extractErrorMessage,
} from "utils/strings";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
  useGetAllPermissionsQuery,
  useUpdateAdminRoleMutation,
  useDeleteAdminRoleMutation,
} from "store/services/adminAPI";

import { useFormik } from "formik";
import * as Yup from "yup";

export const COLUMNS = (selectedIds, handleRowSelection) => [
  // {
  //   Header: "",
  //   accessor: "id",
  //   disableSortBy: true,
  //   Cell: ({ row }) => {

  //     return (
  //       <input
  //         type="checkbox"
  //         checked={selectedIds.includes(row?.original?.id) ? true :false}
  //         onChange={() => handleRowSelection(row?.original?.id)}
  //       />
  //     );
  //   },
  // },
  {
    Header: "Role Title",
    accessor: "name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.name, 15))} `;
    },
  },

  {
    Header: "Description",
    accessor: "description",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(
        truncateString(cell?.row?.original?.description, 15)
      )} `;
    },
  },

  {
    Header: "Permissions",
    accessor: "permissions",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${cell?.row?.original?.permissions?.length} `;
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const disabled = ["super_admin", "admin", "software_developer"];
      // const disabled = ["super_admin", "admin"];
      const [showProfile, setShowProfile] = useState(false);
      const [edit, setEdit] = useState();
      const [selectedPermissions, setPermissions] = useState([]);

      const [updateAdminRole, { isLoading: updatingRole }] =
        useUpdateAdminRoleMutation();
      const [deleteProviderBroadcast, { isLoading: deletingRole }] =
        useDeleteAdminRoleMutation();
      const { data: permissions } = useGetAllPermissionsQuery();

      const PermissionOptions = permissions?.data?.permissions?.map((role) => ({
        value: role.id,
        label: role.name,
        slug : role.slug
      }));

      useEffect(() => {
        const _permissions =
          cell?.row?.original?.permissions.map((_) => _.id) ?? [];
        setPermissions(_permissions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleSubmit = async (values) => {
        const payload = {
          ...values,
          roleId: cell?.row?.original?.id,
          permissions: [...selectedPermissions],
        };
        await updateAdminRole(payload)
          .unwrap()
          .then((data) => {
            toast.success(`✔️ ${data?.message} !`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setEdit(false);
          })
          .catch((error) => {
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) ||
                "Error Occured",
              text: error?.data?.detail,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      };

      const FormSchema = Yup.object().shape({
        name: Yup.string().required("Role is required"),
        description: Yup.string().required("Desciption is required"),
      });

      const formik = useFormik({
        initialValues: {
          name: cell?.row?.original?.name,
          description: cell?.row?.original?.description,
        },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
      });


      return (
        <>
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Provider Details</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form>
                  <Col md={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="">Role</h6>
                      <h6 className="text-primary">
                        {cell?.row?.original?.name}{" "}
                      </h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="">Description</h6>
                      <h6 className="text-primary">
                        {cell?.row?.original?.description}{" "}
                      </h6>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <h6 className="text-center">Permissions</h6>
                      {cell?.row?.original.permissions?.length > 0 ? (
                        cell?.row?.original.permissions.map((_) => {
                          return <p key={_.id}>{_.name}</p>;
                        })
                      ) : (
                        <p className="texr-danger">No permissions yet</p>
                      )}
                    </div>
                  </Col>
                </form>
              </div>
            </div>
          </Modal>

          <Modal
            show={edit}
            onHide={() => setEdit(false)}
            className="modal fade"
            id="postModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Role</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setEdit(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="basic-form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Name</strong>
                        </label>

                        <input
                          className="w-100 form-control"
                          placeholder="Your answer"
                          id="name"
                          type="text"
                          name="name"
                          {...formik.getFieldProps("name")}
                          rows="3"
                        ></input>

                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger mt-2 fs-12">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Description</strong>
                        </label>

                        <textarea
                          className="w-100 form-control"
                          placeholder="Your answer"
                          id="description"
                          name="description"
                          {...formik.getFieldProps("description")}
                          rows="3"
                        ></textarea>

                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger mt-2 fs-12">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        {/* <p>
                      selectedPermissions::{" "}
                      {JSON.stringify(selectedPermissions)}
                    </p> */}

                     {/* <p>
                      selectedPermissions::{" "}
                      {JSON.stringify(permissions)}
                    </p> */}
                        <label className="mb-1">
                          <strong>Permissions</strong>
                        </label>

                        {PermissionOptions &&
                          PermissionOptions?.filter(_ => _?.slug !== "dashboard").map((_permission) => {
                            return (
                              <div
                                key={_permission.value}
                                className="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedPermissions.includes(
                                        _permission?.value
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      if (
                                        !selectedPermissions.includes(
                                          _permission.value
                                        )
                                      ) {
                                        setPermissions([
                                          ...selectedPermissions,
                                          _permission.value,
                                        ]);
                                      } else {
                                        setPermissions(
                                          selectedPermissions.filter(
                                            (item) => item !== _permission.value
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <p>{_permission?.label}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="d-flex w-100 justify-content-center align-items-center">
                      <Button
                        type="submit"
                        className="ms-2 pull-right  py-3 btn-primary"
                      >
                        {updatingRole ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2"
                          ></span>
                        ) : (
                          `Update Role`
                        )}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
             
              {deletingRole ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Role
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setEdit(true)}
                disabled={
                  disabled.includes(cell?.row?.original?.slug) ? true : false
                }
              >
                Edit Role
              </Dropdown.Item>
              <Dropdown.Item
                disabled={
                  disabled.includes(cell?.row?.original?.slug) ? true : false
                }
                onClick={async () =>
                  Swal.fire({
                    title: "Notice",
                    iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                    text: "Are you sure you want to delete this role?",
                    showCloseButton: true,
                    customClass: {
                      icon: "no-border",
                    },
                    cancelButtonText: "Delete",
                    showCancelButton: true,
                    confirmButtonText: "Cancel",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  }).then(async (result) => {
                    if (!result.isConfirmed) {
                      await deleteProviderBroadcast(cell?.row?.original?.id)
                        .unwrap()
                        .then((data) => {
                          toast.success(`✔️ ${data?.message} !`, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                          });
                          window.location.reload();
                        })
                        .catch((error) => {
                          const formattedError = extractErrorMessage(error)
                          Swal.fire({
                            title:
                              replaceUnderscoresWithSpaces(error?.data?.code) ||
                              "Error Occured",
                            text: formattedError,
                            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                            showCloseButton: true,
                            customClass: {
                              icon: "no-border",
                            },
                            confirmButtonText: "Try Again",
                          });
                        });
                    }
                  })
                }
              >
                Delete Role
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];
