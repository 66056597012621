/** @format */

import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";

import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import { CreateModal } from "./helpers/CreateModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";



const CohortTable = ({
  cohorts,
  // metadata,
  isLoading,
  // filter,
  // setFilter
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false)

  const [data, setData] = useState([]);
  // const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    setData(cohorts)
  }, [isLoading, cohorts]);


  const columns = useMemo(
    () => COLUMNS(),[]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;


  // const hasNext = metadata?.next > 0;
  // const hasPrev = metadata?.previous > 0;


  return (
    <div>
      <CreateModal
        setShowCreateModal={setShowCreateModal}
        showModal={showCreateModal}
      />

      <div className={`card ${isLoading ? "disabled" : ""}`}>
        <div className={`card-header ${isLoading ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title">Cohorts</h4>
          </div>

          <div className="d-flex  align-items-center"> 
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setShowCreateModal(true)
              }}
            >
              Create Cohort
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {cohorts?.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Next
                      </button>
                    </div> */}
                  </div>
                </div>

              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No cohorts available
                  </h4>

                  
                </div>
              </div>
             )}
          </div>
        )}
      </div>
    </div>
  );
};
export default CohortTable;
