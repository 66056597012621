/** @format */

import React, { useState, useEffect } from "react";
import { useGetAllAssesmentsQuery } from "store/services/notification";
import { FadeLoader } from "react-spinners";
import AssignmentsTable from "./components/AssignmentsTable";
import CreateAssignment from "./components/CreateAssignment";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

const Assignments = () => {
  const [createResource, setCreate] = useState(false);
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState("page=1");

  const { data, isLoading, isFetching } = useGetAllAssesmentsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  const [allowContentAdd] = useFeatureCheck(Checks.can_create_assessment);
  const [allowContentView] = useFeatureCheck(Checks.can_view_assessment);

  // console.log("data", data?.data?.results);

  return (
    <section className="mt-5">
      <div className="d-flex align-items-center justify-content-end mb-4">
        {allowContentAdd && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setCreate(true)}
          >
            Create Assignment
          </button>
        )}
      </div>
      {allowContentView && (
        <div className="row">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div>
              <AssignmentsTable
                resources={data?.data?.results}
                isLoading={isLoading}
                filtering={isFetching}
                metadata={data?.data?.metadata}
                filter={filter}
                setFilter={setFilter}
                setSkip={setSkip}
              />
            </div>
          )}
        </div>
      )}

      <CreateAssignment createResource={createResource} setCreate={setCreate} />
    </section>
  );
};
export default Assignments;
