/** @format */
import { decrypt } from "utils/secure";

const axios = require("axios");
const accessToken = decrypt(window.localStorage.getItem("3MTAAT"));

var headers = {
  Authorization: accessToken,
  "Content-Type": "application/json",
};

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: headers,
});

axios_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
          window.localStorage.removeItem("3MTAAT");
          window.localStorage.removeItem("3MTAUSER");
          window.location.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);

export default axios_instance;
