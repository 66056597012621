/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { StatusModal } from "./helpers/StatusModal";
import CreateRoleModal from "./helpers/CreateRole";

const RolesTable = ({
  users,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,
}) => {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [clear, setClear] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [action, setAction] = useState("");
  const [create, setCreate] = useState(false);

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  useEffect(() => {
    setData([...users]);
    setSkip(true);
  }, [users, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(
    () => COLUMNS(selectedIds, handleRowSelection),
    [selectedIds, handleRowSelection]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreProviders = () => {
    setClear(!clear);
    setFiltered(false);
    setFilter("page=1");
  };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <CreateRoleModal showModal={create} setCreate={setCreate} />
      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4 className="card-title">Roles</h4>
            <button
              type="button"
              className="btn btn-primary ms-2 "
              onClick={() => {
                setCreate(true);
              }}
            >
              Create Role
            </button>
          </div>
        </div>

        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <StatusModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No users {filtered ? "for the specified filter" : "yet"}
                  </h4>
                  <button
                    onClick={() => restoreProviders()}
                    className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default RolesTable;
