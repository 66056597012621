/** @format */

import React from "react";
import { formatDateWithoutTime } from "utils/date";
import { capitalize } from "utils/strings";





export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "Initiator",
    accessor: "initiated_by.email",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
          <p>{value}</p>
      );
    },

  },
  {
    Header: "Date initiated",
    accessor: "created_at",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
          <p>{formatDateWithoutTime(value)}</p>
      );
    },

  },
  {
    Header: "Cohort",
    accessor: "cohort.rank",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{`Cohort ${value}`}</p>
    );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{capitalize(value)}</p>
    );
    },
  },
];
