/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";

import { StatusOptions } from "utils/strings";
import { Nigerian_States, LocalGov } from "utils/constant.data";

export const ProviderFilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  clearFilter,
}) => {
  const [selectedStatus, setStatus] = useState([]);
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);


  useEffect(() => {
    setStatus([]);
    setStates([]);
    setLGAs([]);
  }, [clearFilter]);

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (
      selectedStatus.length === 0 &&
      selectedStates.length === 0 &&
      selectedLGAs.length === 0
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        status: selectedStatus.length > 0 ? selectedStatus : [],
        selectedStates: selectedStates.length > 0 ? selectedStates : [],
        selectedLGAs: selectedLGAs.length > 0 ? selectedLGAs : [],
      });
    }
  };

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Providers</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Status</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedStatus}
                      onChange={setStatus}
                      options={StatusOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Filter by States</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedStates}
                      onChange={setStates}
                      options={StateOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Filter by LGAs</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLGAs}
                      onChange={setLGAs}
                      options={getLGData()}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setStatus([]);
                  setStates([]);
                  setLGAs([]);
                  // restoreFellows();
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Providers"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProviderFilterModal;
