/** @format */

import { Dropdown, Modal, Button, Col } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState } from "react";
import { capitalize, extractErrorMessage, mapStatusClass, StatusOptions } from "utils/strings";
import { useUpdateFellowStatusMutation } from "store/services/adminAPI";
import swal from "sweetalert";
import { replaceSpace, mapLevel } from "utils/strings";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "first_name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.first_name)} ${capitalize(
        cell?.row?.original?.last_name
      )}`;
    },
  },
  // {
  //   Header: "Last Name",
  //   accessor: "last_name",
  //   Filter: ColumnFilter,
  //   disableFilters: true,
  //   sortable: false,
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },
  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  // {
  //   Header: "Phone Number",
  //   accessor: "phone",
  //   Filter: ColumnFilter,
  //   disableFilters: true,
  //   sortable: false,
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },

  // {
  //   Header: "Age",
  //   accessor: "age",
  //   Filter: ColumnFilter,
  //   disableFilters: true,
  //   sortable: false,
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },

  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Filter: ColumnFilter,
    Cell: ({ cell: { value } }) => {
      let _class = mapStatusClass(replaceSpace(value));
      return (
        <span className={`badge light badge-${_class}`}>
          <i className="fa fa-circle text-danger me-1" />
          {value}
        </span>
      );
    },
  },

  {
    Header: "Level",
    accessor: "skill_level",
    disableFilters: true,
    Filter: ColumnFilter,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(mapLevel(value))}`;
    },
  },

  // {
  //   Header: "Gender",
  //   accessor: "gender",
  //   disableFilters: true,
  //   Filter: ColumnFilter,
  //   sortable: false,
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },

  {
    Header: "Cohort",
    accessor: "age",
    disableFilters: true,
    Filter: ColumnFilter,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `Cohort 1`;
    },
  },

  {
    Header: "Course",
    accessor: "phone",
    disableFilters: true,
    Filter: ColumnFilter,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `UI/UX & Project Management`;
    },
  },

  // {
  //   Header: "Gender",
  //   accessor: "gender",
  //   disableFilters: true,
  //   Filter: ColumnFilter,
  //   sortable: false,
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },


  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const [showProfile, setShowProfile] = useState(false);
      const [status, setStatus] = useState("");
      const [showStatusModal, setStatusModal] = useState(false);
      const [updateFellowStatus, { isLoading: updating }] =
        useUpdateFellowStatusMutation();

      const handleStatus = async () => {
        const payload = {
          id: cell?.row?.original?.id,
          status: status,
        };

        await updateFellowStatus(payload)
          .unwrap()
          .then((data) => {
            swal({
              title: "Success",
              text: data?.detail,
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  className: "btn btn-success btn-block",
                  closeModal: true,
                },
              },
              icon: "success",
            }).then(() => {
              setStatusModal(false);
            });
          })
          .catch((error) => {
            const formattedError = extractErrorMessage(error)
            swal({
              title: "Error occured",
              text: formattedError,
              buttons: {
                confirm: {
                  text: "Try Again",
                  value: true,
                  visible: true,
                  className: "btn btn-primary btn-block",
                  closeModal: true,
                },
              },
              icon: "error",
            });
          });
      };

      const { first_name, last_name, email, phone, age, skill_level, gender } =
        cell?.row?.original;

      return (
        <>
          {/* ProfileModal */}
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"> Fellow Profile</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="col-xl-12">
                  <div className="card  course-dedails-bx">
                    <div className="card-header border-0 pb-0">
                      <h2>
                        {capitalize(first_name)} &nbsp; {capitalize(last_name)}
                      </h2>
                    </div>
                    <div className="card-body pt-0">
                      <div className="description">
                        <p>{capitalize(email)}</p>
                        <ul className="d-flex align-items-center raiting flex-wrap">
                          <li>
                            <span className="font-w500">
                              {capitalize(phone)}
                            </span>
                          </li>
                          <li>{capitalize(gender)}</li>
                          <li>{capitalize(age)}</li>
                        </ul>
                        <div className="user-pic mb-3">
                          <span>{capitalize(skill_level)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    variant="primary"
                    onClick={(e) => setShowProfile(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Profile Modal */}

          {/* StatusModal */}
          <Modal
            show={showStatusModal}
            onHide={() => setStatusModal(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Fellow Status</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setStatusModal(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Col md={12}>
                      <p className="p-1 m-0">Status</p>
                      <div className="form-group mb-3">
                        <select
                          defaultValue={"option"}
                          id="inputState"
                          className="form-control"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          {StatusOptions.map((_status) => {
                            return (
                              <option
                                disabled={
                                  cell?.row?.original?.status?.toLowerCase() ===
                                  _status?.value.toLowerCase()
                                }
                                value={_status?.value}
                              >
                                {_status?.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </form>
                </div>

                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    onClick={() => {
                      handleStatus();
                    }}
                    type="button"
                    className="ms-2 pull-right  py-3 btn-pigment-green"
                    disabled={status?.length < 1}
                  >
                    {updating ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          {/* StatusModal */}

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setStatusModal(true)}>
                Update Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Profile
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Birth",
        Footer: "Date of  Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];
