/** @format */

import React, { useState } from "react";
import { formatDateWithoutTime } from "utils/date";
import { truncateString } from "utils/strings";
import { Dropdown } from "react-bootstrap";
import { UpdateModal } from "../modals/UpdateModal";




export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "Fellow ID",
    accessor: "fellow_id",
    disableFilters: true,
    sortable: false,

  },
  {
    Header: "Name",
    accessor: "fellow_name",
    disableFilters: true,
    sortable: false,

  },
  {
    Header: "File URL",
    accessor: "certificate_url",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <a href={`https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/certificates/${value}`} target="_blank" rel="noopener noreferrer" className="text-primary text-bold">
          {truncateString(`https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/certificates/${value}`)}
        </a>
      );
    },
  },

  {
    Header: "Course",
    accessor: "fellow_course",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return truncateString(value)
    },
  },

  {
    Header: "Uploaded At",
    accessor: "uploaded_at",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return formatDateWithoutTime(value)
    },
  },

  {
    Header: "Updated By",
    accessor: "status_updated_by",
    disableFilters: true,
    sortable: false,

  },

  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return value
    },
  },
  {
    Header: "Actions",
    accessor: "",
    disableFilters: true,
    sortable: false,
    Cell: ({ row }) => {
      const [showUpdateModal, setShowUpdateModal] = useState(false)
      return(
        <>
        <UpdateModal 
          showUpdateModal = {showUpdateModal}
          setShowUpdateModal = {setShowUpdateModal}
          data = {row.original}
        />
        <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowUpdateModal(true)}>
                Update Certificate
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </>
      )
    },
  },
];
