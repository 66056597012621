import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ActivityChart = ({ activity_data }) => {
  const filteredData = activity_data.filter(item => item.name !== null);

  const series = filteredData.map(item => item.count);
  const labels = filteredData.map(item => item.name);

  const [chartData] = useState({
    series: series,
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true,
          tools: {
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
          },
          export: {
            csv: {
              filename: "activity_data"
            },
            png: {
              filename: "activity_data",
              width: 1366,
              height: 720,
              scale: 8
            },
            svg: {
              filename: "activity_data",
              width: 1366,
              height: 720,
              scale: 8
            }
          }
        }
      },
      labels: labels,
      colors: ['#A6D693', '#B0B0B0'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(0) + "%"
        }
      }
    },
  });

  return (
    <div>
      {
        filteredData?.length > 0 ? (
          <div className="pie-chart">
            <Chart options={chartData.options} series={chartData.series} type="pie" width="600" height="600" />
          </div>
        ) : (
          <p>No data available under this category</p>
        )
      }
      
    </div>

  );
};

export default ActivityChart;