/** @format */

import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import { useInviteUserMutation } from "store/services/adminAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useGetAllRolesQuery } from "store/services/adminAPI";
import { useFormik } from "formik";
import * as Yup from "yup";

export const InviteModal = ({ showModal, setShowModal }) => {
  const { data } = useGetAllRolesQuery();
  const [selectedRole, setRole] = useState(null);
  const [inviteUser, { isLoading: updatingUser }] = useInviteUserMutation();


  const RoleOptions = data?.data?.roles?.map((role) => ({
      value: role.id,
      label: role.name,
      slug: role.slug,
    }))
    ?.filter(
      (_) =>  {
      return  _.slug !==  "software_developer" && _.slug !== "super_admin"
      }
    );

  // accept_invitation_page: "https://data.3mtt.training/accept_invitation_page?invitation_code=[invitation_code]"

  const handleSubmit = async (values,{resetForm}) => {

    if(selectedRole) {
      await inviteUser({
        email: values.email,
        role_id: selectedRole?.value,
        accept_invitation_page:
          "https://data.3mtt.training/accept_invitation_page?invitation_code=[invitation_code]",
      })
        .unwrap()
        .then((data) => {
          toast.success(`✔️ ${data?.message} !`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          resetForm();
          setRole(null)
          setShowModal(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error)
          Swal.fire({
            title:
              replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
            text: formattedError,
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        });
    }else {
      toast.success(`Please selct a role !`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
   
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal fade"
        id="statusModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Invite User</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowModal(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Email</strong>
                  </label>

                  <input
                    className="w-100 form-control"
                    placeholder="example@fmcide.gov.ng"
                    id="email"
                    type="text"
                    name="email"
                    {...formik.getFieldProps("email")}
                    rows="3"
                  ></input>

                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>

              <Col md={12}>
                <p className="p-1 m-0">Role</p>
                <div className="form-group mb-3">
                  <Select
                    value={selectedRole}
                    onChange={setRole}
                    options={RoleOptions?.filter((_) => _.slug !== "software_developer" || _.slug !== "super_admin") ?? []}
                    styles={Style}
                  />
                </div>
              </Col>

              <div className="row">
                <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                  <button
                    type="button"
                    className="btn btn-outline ms-2 "
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <Button variant="primary" type="submit">
                    {updatingUser ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Send Invite`
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    // .matches(/fmcide\.gov\.ng$/, "Email must end with @fmcide.gov.ng")
    .required("Email is required"),
});
