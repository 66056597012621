/** @format */

import { decrypt } from "utils/secure";

const useFeatureCheck = (permissionToCheck) => {
    const _user = decrypt(window.localStorage.getItem("3MTAUSER"));
    const currentUser = JSON.parse(_user) ?? null;
    const permissions = currentUser?.role?.permissions?.map((_) => _?.slug);
    // console.log('permissions',permissions)
    const access = permissions?.includes(permissionToCheck);
    return [access];
};

export default useFeatureCheck;
