/** @format */

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { useCreateLearningContentMutation } from "store/services/notification";
import { Style } from "utils/select";
import Select from "react-select";
import { useGetAllCoursesQuery } from "store/services/notification";
import axios_instance from "store/constant/axiosInstance";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { skills, levels } from "utils/constant.data";

const CreateContent = ({ createResource, setCreate }) => {
  const [createLearningContent, { isLoading }] =
    useCreateLearningContentMutation();
  const { data, isLoading: loadingCourses } = useGetAllCoursesQuery();
  const { data: allCohorts, isLoading: loadingCohorts } = useGetCohortsQuery();
  const [selectedCategory, setCategory] = useState(null);
  const [selectedCohorts, setCohorts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitted(true);

    if (selectedCategory?.length > 0 && selectedCohorts?.length > 0) {
      setSubmitted(false);
      await createLearningContent({
        ...values,
        categories: selectedCategory?.map((item) => item.value),
        cohorts: selectedCohorts?.map((item) => item.value),

        skill: values?.skill,
        duration: values?.duration,
        level_of_difficulty: values?.level_of_difficulty,
        main_course: values?.main_course,
      })
        .unwrap()
        .then((data) => {
          toast.success(`✔️ Resource Created!`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
          });

          resetForm();
          setCreate(false);
          window.location.reload();
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error)
          Swal.fire({
            title:
              replaceUnderscoresWithSpaces(error?.data?.code) ||
              "Error Occured",
            text: formattedError,
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        });
    } else {
      toast.error(
        "❌ Please select relevant category or/and relevant cohort!",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      link: "",
      image: "",
      skill: "",
      duration: "",
      level_of_difficulty: "",
      topic_level: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const Categories = data?.data?.courses
    ?.filter((_) => {
      return _?.status;
    })
    ?.map((role) => ({
      value: role?.id,
      label: role?.title,
      code: role?.code,
    }));

  const Cohorts = allCohorts?.data?.cohorts?.map((role) => ({
    value: role?.id,
    label: role?.name + " " + role?.rank,
  }));

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        if (file.size > 2 * 1024 * 1024) {
          toast.error("❌ File size exceeds the 2MB limit.!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          const formData = new FormData();
          file && formData.append("file", file);
          try {
            setUploading(true);
            const response = await axios_instance.post(
              "core/upload/file",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            toast.success(`✔️ Image uploaded!`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            formik.setFieldValue("image", response?.data?.data?.url);
            setUploading(false);
          } catch (e) {
            setUploading(false);
            toast.error("❌ Error occured uploading image!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        }
      } else {
        toast.error("❌ Only image files are allowed. !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <Modal className="modal fade" show={createResource}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create New Learning Content </h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Learning Content Name</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Resource Name"
                    {...formik.getFieldProps("name")}
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <p> category:: {JSON.stringify(selectedCategory)}</p> */}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Learning Content Link</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    placeholder="Course Link"
                    {...formik.getFieldProps("link")}
                  />

                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.link}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mb-1">
                <label htmlFor="formFile" className="form-label">
                  Upload Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".jpeg, .jpg, .png"
                  onChange={handleFileChange}
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                {uploading && (
                  <>
                    <span
                      role="status"
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ms-2"
                    ></span>
                    <p className="m-0 ml-2">Uploading Image....</p>
                  </>
                )}
              </div>

              <div className="col-lg-12 disabled">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Learning Content Image</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="image"
                    name="image"
                    placeholder="Image Url"
                    {...formik.getFieldProps("image")}
                  />

                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.image}
                    </div>
                  ) : null}
                </div>
              </div>

              {loadingCourses ? (
                <p>Loading Courses.....</p>
              ) : (
                Categories && (
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label> Learning Content Course</label>
                      <Select
                        value={selectedCategory}
                        onChange={setCategory}
                        options={Categories}
                        styles={Style}
                        isMulti
                      />

                      {submitted && !selectedCategory ? (
                        <div className="text-danger mt-2 fs-12">
                          Please select relevant category
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}

              {loadingCohorts ? (
                <p>Loading Cohorts.....</p>
              ) : (
                Cohorts && (
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label>Cohorts</label>
                      <Select
                        value={selectedCohorts}
                        onChange={setCohorts}
                        options={Cohorts}
                        styles={Style}
                        isMulti
                      />

                      {submitted && !selectedCohorts ? (
                        <div className="text-danger mt-2 fs-12">
                          Please select relevant cohort(s)
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}

              <>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Skill</strong>
                    </label>

                    <select
                      id="skill"
                      name="skill"
                      className="form-control"
                      {...formik.getFieldProps("skill")}
                    >
                      <option value="">Select type</option>

                      {skills.map((_) => {
                        return <option value={_?.toLowerCase()}>{_}</option>;
                      })}
                    </select>

                    {formik.touched.skill && formik.errors.skill ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.skill}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Level Of Difficulty</strong>
                    </label>

                    <select
                      id="level_of_difficulty"
                      name="level_of_difficulty"
                      className="form-control"
                      {...formik.getFieldProps("level_of_difficulty")}
                    >
                      <option value="">Select type</option>

                      {levels.map((_) => {
                        return <option value={_?.toLowerCase()}>{_}</option>;
                      })}
                    </select>

                    {formik.touched.level_of_difficulty &&
                    formik.errors.level_of_difficulty ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.level_of_difficulty}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Duration(in Minutes)</strong>
                    </label>

                    <input
                      className="form-control"
                      id="duration"
                      name="duration"
                      placeholder="Duration in Minutes"
                      {...formik.getFieldProps("duration")}
                      type="text"
                      pattern="[0-9]*"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />

                    {formik.touched.duration && formik.errors.duration ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.duration}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Topic Level</strong>
                    </label>

                    <select
                      id="topic_level"
                      name="topic_level"
                      className="form-control"
                      {...formik.getFieldProps("topic_level")}
                    >
                      <option value="">Select type</option>

                      {[
                        {
                          value: "foundational",
                          label: "Foundational Course",
                        },
                        {
                          value: "main",
                          label: "Main Course",
                        },
                      ].map((_) => {
                        return <option value={_?.value}>{_?.label}</option>;
                      })}
                    </select>

                    {formik.touched.topic_level && formik.errors.topic_level ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.topic_level}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create  Learning Content`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateContent;

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Learning Content Name is required"),
  link: Yup.string()
    .required("Learning Content Link is required")
    .url("Invalid URL. Please enter a valid link."),
  image: Yup.string()
    .required(" Learning Content Image Link is required")
    .url("Invalid URL. Please enter a valid link."),
  skill: Yup.string().required("Skill is required"),
  duration: Yup.string().required("Duration is required"),
  level_of_difficulty: Yup.string().required("Level of Difficulty is required"),
  topic_level: Yup.string().required("Topic level is required"),
});
