import React, { useState } from "react";
import { FadeLoader } from "react-spinners";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import { useGetProviderAnalysisQuery } from "store/services/adminAPI";
import Check from "./../../../../images/vector/check.png";
import StateChart from "./components/charts/StateChart";
import ProviderFellowsChart from "./components/charts/ProviderFellowsChart";
import CourseChart from "./components/charts/CourseChart";
import ProviderFilterModal from "./components/modals/ProviderFilterModal";
import { Button } from "react-bootstrap";

function RightIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 0.25H4C3.00544 0.25 2.05161 0.645088 1.34835 1.34835C0.645088 2.05161 0.25 3.00544 0.25 4V19C0.25 19.9946 0.645088 20.9484 1.34835 21.6517C2.05161 22.3549 3.00544 22.75 4 22.75H19C19.9946 22.75 20.9484 22.3549 21.6517 21.6517C22.3549 20.9484 22.75 19.9946 22.75 19V4C22.75 3.00544 22.3549 2.05161 21.6517 1.34835C20.9484 0.645088 19.9946 0.25 19 0.25ZM16.875 8.5125L11.1625 16.0125C11.0461 16.1638 10.8965 16.2864 10.7253 16.3709C10.5542 16.4554 10.3659 16.4995 10.175 16.5C9.98513 16.501 9.79753 16.4588 9.62643 16.3765C9.45532 16.2942 9.30522 16.174 9.1875 16.025L6.1375 12.1375C6.03655 12.0078 5.96212 11.8595 5.91848 11.7011C5.87484 11.5426 5.86283 11.3772 5.88314 11.2141C5.90346 11.051 5.95569 10.8935 6.03687 10.7506C6.11804 10.6077 6.22657 10.4822 6.35625 10.3812C6.61815 10.1774 6.95032 10.0859 7.27968 10.1269C7.44276 10.1472 7.60025 10.1994 7.74314 10.2806C7.88604 10.3618 8.01155 10.4703 8.1125 10.6L10.15 13.2L14.875 6.95C14.9751 6.81868 15.1002 6.70836 15.2429 6.62536C15.3857 6.54235 15.5434 6.48828 15.7071 6.46622C15.8707 6.44417 16.0371 6.45457 16.1968 6.49682C16.3564 6.53908 16.5062 6.61237 16.6375 6.7125C16.7688 6.81263 16.8791 6.93765 16.9621 7.08042C17.0451 7.22318 17.0992 7.3809 17.1213 7.54456C17.1433 7.70823 17.1329 7.87463 17.0907 8.03427C17.0484 8.19392 16.9751 8.34368 16.875 8.475V8.5125Z"
        fill="#26a65b"
      />
    </svg>
  );
}

const AllProvidersAnalytics = () => {
  usePermissionCheck(Page.LEARNING_TRACKER);
  const [showFilter, setShowFilter] = useState(false);
  const [filterString, setFilterString] = useState("");

  const { data, isLoading: analyzing, isFetching: filtering } = useGetProviderAnalysisQuery(filterString);

  const ProviderAnalysisCardsData = [
    {
      coloumClass: "col-sm-6",
      classChange: "bg-secondary",
      image: Check,
      imgClass: "",
      title: "All Providers",
      number: data?.data?.selected_providers,
      svgicon: <RightIcon />,
      filter_tag: "selected"
    },
  ];

  const isLoading = analyzing || filtering;

  return (
    <>
      <ProviderFilterModal 
        showModal={showFilter}
        setShowFilter={setShowFilter}
        filtering={filtering}
        setFilterString={setFilterString}
      />
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
            <h4 className="mt-2 ml-3">Computing...</h4>
          </div>
        ) : (
          <>
            <div className="flex gap-4 mb-4">
              <Button onClick={() => setShowFilter(true)}>
                Further Analyze
              </Button>
            </div>
            
            <div className="row">
              {ProviderAnalysisCardsData.map((item, ind) => (
                <div className={`col-xl-4 ${item.coloumClass}`} key={ind} role="button">
                  <div className={`dlab-cource ${item.classChange}`}>
                    <div className="d-flex align-items-center">
                      <span className="course-icon">{item.svgicon}</span>
                      <div className="ms-2">
                        <h4 className="mb-0">{item.number}</h4>
                        <span>{item.title}</span>
                      </div>
                    </div>
                    <img src={item.image} alt="" className={item.imgClass} />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <div className="row mb-4">
                <h4>Distribution of ALCs across states</h4>
                <StateChart state_data={data?.data?.state_breakdown} />
              </div>

              <div className="row mt-3 mb-4">
                <h4>Distribution of fellows across ALCs</h4>
                <ProviderFellowsChart provider_fellows_data={data?.data?.providers_fellows_breakdown} />
              </div>

              <div className="row mt-3 mb-4">
                <h4>Distribution of ALCs across Cohorts</h4>
                <CourseChart course_data={data?.data?.provider_in_cohort_breakdown} />
              </div>

              <div className="row mt-3 mb-4">
                <h4>Distribution of courses across ALCs</h4>
                <StateChart state_data={data?.data?.providers_in_courses_breakdown} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllProvidersAnalytics;