/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize } from "utils/strings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import {
  useGetAllCoursesQuery,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
} from "store/services/notification";
import { extractFilterValues } from "utils/functions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FadeLoader } from "react-spinners";
import FilterModal from "./helpers/FilterModal";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

const CoursesTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  filtering,
}) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { data: coursesList } = useGetAllCoursesQuery();

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `page=${_page}${
      values?.course_ids?.length > 0
        ? `&course_ids=${values?.course_ids?.join(",")}`
        : ""
    }`;

    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Course Name",
      accessor: "title",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.title)}`;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        const status = cell?.row?.original?.status ?? false;
        return status ? "Active" : "Inactive";
      },
    },

    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const { status, id, description, title, code } = cell?.row?.original;
        const [showProfile, setShowProfile] = useState(false);
        const [showUpdate, setShowUpdate] = useState(false);
        const [activeId, setActive] = useState(null);
        const [allowCourseUpdate] = useFeatureCheck(Checks.can_add_course);
        const [allowCourseDelete] = useFeatureCheck(Checks.can_add_course);

        const [updateCourse, { isLoading: updatingResource }] =
          useUpdateCourseMutation();
        const [deleteCourse, { isLoading: isDeleting }] =
          useDeleteCourseMutation();

        const FormSchema = Yup.object().shape({
          title: Yup.string().required("Course title is required"),
          code: Yup.string().required("Course code is required"),
          description: Yup.string().required("Course description is required"),
        });

        const handleSubmit = async (values) => {
          await updateCourse({
            ...values,
            id,
          })
            .unwrap()
            .then((data) => {
              toast.success(`✔️ ${data?.message} !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });
              setShowUpdate(false);
              setSkip(false);
              // window.location.reload();
            })
            .catch((error) => {
              Swal.fire({
                title:
                  replaceUnderscoresWithSpaces(error?.data?.code) ||
                  "Error Occured",
                text: error?.data?.detail,
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        const handleStatus = async (status) => {
          await updateCourse({
            status,
            id,
          })
            .unwrap()
            .then((data) => {
              toast.success(`✔️ ${data?.message} !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });
              setShowUpdate(false);
              setSkip(false);
              // window.location.reload();
            })
            .catch((error) => {
              Swal.fire({
                title:
                  replaceUnderscoresWithSpaces(error?.data?.code) ||
                  "Error Occured",
                text: error?.data?.detail,
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        const formik = useFormik({
          initialValues: {
            title,
            code,
            description,
          },
          validationSchema: FormSchema,
          onSubmit: handleSubmit,
          enableReinitialize: true,
        });

        return (
          <>
            {/* Profile Modal */}
            <Modal
              show={showProfile}
              onHide={() => setShowProfile(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Course</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowProfile(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                          <h2 className="mb-0">{capitalize(title)}</h2>
                        </div>
                      </div>

                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <p>{description}</p>
                        </div>
                      </div>

                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Status</h6>

                          {updatingResource ? (
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                          ) : (
                            <button
                              className="btn btn-outline"
                              disabled={allowCourseUpdate ? false : true}
                              onClick={() => {
                                Swal.fire({
                                  title: "Confirm Action",
                                  text: "Are you sure you want to change the statusof this resource",
                                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                  showCloseButton: true,
                                  customClass: {
                                    icon: "no-border",
                                  },

                                  showCancelButton: true,
                                  reverseButtons: true,
                                  confirmButtonText: "Proceed",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    handleStatus(status ? false : true);
                                  }
                                });
                              }}
                            >
                              {status ? "Disable" : "Enable"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100 justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Profile Modal */}

            {/* Update Modal */}
            <Modal
              show={showUpdate}
              onHide={() => setShowUpdate(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update Course</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowUpdate(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <form className="comment-form" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Title</strong>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            placeholder="Title"
                            {...formik.getFieldProps("title")}
                          />

                          {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Code</strong>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="code"
                            name="code"
                            placeholder="Code"
                            {...formik.getFieldProps("code")}
                          />

                          {formik.touched.code && formik.errors.code ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.code}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Description</strong>
                          </label>

                          <textarea
                            className="w-100 form-control"
                            placeholder="Your answer"
                            id="description"
                            name="description"
                            {...formik.getFieldProps("description")}
                            rows="3"
                          ></textarea>

                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex justify-content-end">
                        <div className="form-group mb-3">
                          <button
                            type="submit"
                            className="submit btn btn-pigment-green"
                          >
                            {updatingResource ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              `Update Course`
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
            {/* Update Modal */}

            <Dropdown className="dropdown ms-auto text-right">
              {isDeleting && activeId === id ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                <Dropdown.Toggle
                  variant=""
                  className="btn-link i-false"
                  data-toggle="dropdown"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </Dropdown.Toggle>
              )}

              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                <Dropdown.Item onClick={() => setShowProfile(true)}>
                  View
                </Dropdown.Item>

                {allowCourseDelete && (
                  <Dropdown.Item onClick={() => setShowUpdate(true)}>
                    Update
                  </Dropdown.Item>
                )}

                {allowCourseDelete && (
                  <Dropdown.Item
                    onClick={async () => {
                      setActive(id);
                      Swal.fire({
                        title: "Notice",
                        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                        text: "Are you sure you want to delete this record?",
                        showCloseButton: true,
                        customClass: {
                          icon: "no-border",
                        },
                        cancelButtonText: "Delete",
                        showCancelButton: true,
                        confirmButtonText: "Cancel",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      }).then(async (result) => {
                        if (!result.isConfirmed) {
                          await deleteCourse(id)
                            .unwrap()
                            .then((data) => {
                              setSkip(false);
                              toast.success(`✔️ ${data?.message} !`, {
                                position: "top-right",
                                autoClose: 2500,
                                hideProgressBar: false,
                                closeOnClick: true,
                              });
                            })
                            .catch((error) => {
                              Swal.fire({
                                title:
                                  replaceUnderscoresWithSpaces(
                                    error?.data?.code
                                  ) || "Error Occured",
                                text: error?.data?.detail,
                                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                showCloseButton: true,
                                customClass: {
                                  icon: "no-border",
                                },
                                confirmButtonText: "Try Again",
                              });
                            });
                        }
                      });
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        coursesList={coursesList}
        // restoreFellows={restoreFellows}
        clearFilter={clear}
      />
      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Courses</h4>

            {(filtered || searched) && (
              <button
                onClick={() => {
                  setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1`);
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by name"
            />

            <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button>

            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No Resources for the specified filter
                  </h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CoursesTable;
