/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { ProviderOptions } from "utils/strings";
import { Nigerian_States, LocalGov } from "utils/constant.data";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { extractFilterValues } from "utils/functions";

export const ProviderFilterModal = ({
  showModal,
  setShowFilter,
  setFilterString,
  filtering,
  clearFilter
}) => {
  const [selectedStatus, setStatus] = useState([]);
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);
  const [selectedCohorts, setCohorts] = useState(null);

  const { data: cohortsData } = useGetCohortsQuery("");

  useEffect(() => {
    setStatus([]);
    setStates([]);
    setLGAs([]);
    setCohorts([]);
  }, [clearFilter]);

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (
      selectedStatus.length === 0 &&
      selectedStates.length === 0 &&
      selectedLGAs.length === 0 &&
      selectedCohorts.length === 0
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const newFilter = {
      status: selectedStatus,
      selectedStates: selectedStates,
      selectedLGAs: selectedLGAs,
      selectedCohorts: selectedCohorts,

    };

    const values = extractFilterValues(newFilter);

    const filterString = `${
      values?.status?.length > 0 ? `status=${values?.status?.join(",")}` : ""
    }${
      values?.selectedStates?.length > 0
        ? `&selectedStates=${values?.selectedStates?.join(",")}`
        : ""
    }${
      values?.selectedLGAs?.length > 0
        ? `&lga=${values?.selectedLGAs?.join(",")}`
        : ""
    }${values?.selectedCohorts?.length > 0 ? `&cohort_id=${values?.selectedCohorts?.join(",")}` : ""}`;

    console.log("filterString ll alcs", filterString);
    setFilterString(filterString);
    setShowFilter(false)
  };

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const CohortOptions =
    cohortsData?.data?.cohorts?.length > 0
      ? [
          ...cohortsData?.data?.cohorts?.map((cohort) => ({
            value: cohort?.id,
            label: `Cohort ${cohort?.rank}`,
          })),
        ]
      : [];

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Analyze ALCs</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                {CohortOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Analyze using Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohorts}
                        onChange={setCohorts}
                        options={CohortOptions}
                        styles={Style}
                        isMulti
                      />
                    </div>
                  </Col>
                )}
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using Status</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedStatus}
                      onChange={setStatus}
                      options={ProviderOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Analyze using States</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedStates}
                      onChange={setStates}
                      options={StateOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Analyze using LGAs</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLGAs}
                      onChange={setLGAs}
                      options={getLGData()}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline"
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setStatus([]);
                  setStates([]);
                  setLGAs([]);
                }}
              >
                Clear Parameters
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right py-3 btn-pigment-green"
              >
                {filtering ? "Analyzing ...." : "Analyze ALCs"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProviderFilterModal;
