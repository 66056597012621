import React, { useState, useEffect } from "react";
import AdminLayout from "views/admin/Admin";
import { FadeLoader } from "react-spinners";
import MatchesReportTable from "views/admin/table/Matching/MatchesReportTable";
import { useGetMatchedProviderBranchesReportsQuery } from "store/services/adminAPI";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const AdminMatchingReports = () => {
  usePermissionCheck(Page.LEARNING_TRACKER)
  const [filter, setFilter] = useState("page=1");
  const [skip, setSkip] = useState(true);  // Start with skip as true

  const {
    data,
    isLoading,
  } = useGetMatchedProviderBranchesReportsQuery(filter, { skip });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false); 
    }
  }, [filter]);

  return (
    <AdminLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div>
            <MatchesReportTable
              matchesHistory={data?.data?.results}
              isLoading={isLoading}
              metadata={data?.data?.metadata}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminMatchingReports;