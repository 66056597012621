/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
        const accessToken = window.localStorage.getItem("3MTAAT");
        headers.set("Content-Type", "application/json");
        if (accessToken) {
            headers.set("Authorization", `${accessToken}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        window.localStorage.removeItem("3MTAAT");
        window.localStorage.removeItem("3MTAUSER");
        window.location.replace("/login");
    }
    return result;
};


export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        profileKyb: builder.query({
            query: () => `provider/verify`,
            invalidatesTags: [API_TAGS.FETCH_PROVIDER_INFO],
        }),
        profileInfo: builder.query({
            query: () => `account/profile`,
            providesTags: [API_TAGS.FETCH_PROVIDER_INFO],
        }),
    }),
});



export const {
    // useProfileKybMutation,
    useProfileKybQuery,
    useProfileInfoQuery,
} = profileApi;
