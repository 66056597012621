import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LGAAttendanceChart = ({lga_attendance_data}) => {
  const filteredData = lga_attendance_data.filter(item => item.x !== null);

  const options = {
    legend: { show: false },
    chart: { height: 350, type: 'treemap' },
    plotOptions: {
      treemap: {
        distributed: false,
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const item = filteredData[opts.dataPointIndex];
            return `${item.x}: ${item.y}%`;
          },
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#fff']
          },
        },
        colorScale: {
          ranges: [
            { from: 0, to: 20, color: '#006400' },  
            { from: 21, to: 40, color: '#228B22' },  
            { from: 41, to: 60, color: '#32CD32' },  
            { from: 61, to: 80, color: '#50B172' }, 
            { from: 81, to: 100, color: '#26A65B' }  
          ]
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + "%";
        }
      }
    }
  };

  const series = [
    {
      name: "Attendance",
      data: filteredData
    }
  ];

  return (
    <div>
      {filteredData.length > 0 ? (
        <div id="chart">
          <ReactApexChart options={options} series={series} type="treemap" height={500} />
        </div>
      ) : (
        <p>No data available under this category</p>
      )}
    </div>
  );
};

export default LGAAttendanceChart;