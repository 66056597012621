/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize } from "utils/strings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
// import Switch from "react-switch";
import { useDeleteAdminSurveyMutation } from "store/services/adminAPI";
import { extractFilterValues } from "utils/functions";
import { FadeLoader } from "react-spinners";
import FilterModal from "./helpers/FilterModal";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";
import CreateSurvey from "../components/CreateSurvey";


const SurveysTable = ({ resources, metadata, setFilter, setSkip, filtering }) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [showFilter, setShowFilter] = useState(false);


  const [allowContentUpdate] = useFeatureCheck(Checks.can_update_survey);
  const [allowContentView] = useFeatureCheck(Checks.can_view_survey_page_link);
  const [allowContentDelete] = useFeatureCheck(Checks.can_delete_survey)


  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `page=${_page}${
      values?.course_ids?.length > 0
        ? `&course_ids=${values?.course_ids?.join(",")}`
        : ""
    }${
      values?.cohort_ids?.length > 0
        ? `&cohort_ids=${values?.cohort_ids?.join(",")}`
        : ""
    }`;

    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Survey Name",
      accessor: "name",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.name)}`;
      },
    },

    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const { name, id, link, description } =
          cell?.row?.original;
        const [showProfile, setShowProfile] = useState(false);
        const [showUpdate, setShowUpdate] = useState(false);
        // const [_status, setStatus] = useState(status);

        const [deleteSurvey] = useDeleteAdminSurveyMutation(id)


        const handleDelete = async () => {
          try {
            await deleteSurvey(id).unwrap();
            toast.success("Survey deleted successfully!", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            // Optionally, update the UI after deletion, e.g., refetch data
            // Or you can use React Query's invalidation mechanism if necessary
          } catch (error) {
            Swal.fire({
              title: replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occurred",
              text: error?.data?.detail,
              iconHtml: `<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          }
        };

        return (
          <>
            {/* Profile Modal */}
            <Modal
              show={showProfile}
              onHide={() => setShowProfile(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Survey Details</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowProfile(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                          <h2 className="mb-0">{capitalize(name)}</h2>
                          <button
                            className="btn btn-outline"
                            onClick={() => window.open(link, "_blank")}
                          >
                            Go To Survey
                          </button>
                        </div>
                      </div>

                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="me-4">Description</h6>
                          <p>{description}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="d-flex w-100 justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Profile Modal */}
            {
              showUpdate && (
                <CreateSurvey 
                  showCreate={showUpdate}
                  setShowCreate={setShowUpdate}
                  mode="update"
                  existingData={cell.row.original}
                />
              )
            }

            <Dropdown className="dropdown ms-auto text-right">
              <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                {allowContentView && (
                  <Dropdown.Item onClick={() => setShowProfile(true)}>
                    View
                  </Dropdown.Item>
                )}

                {allowContentUpdate && (
                  <Dropdown.Item onClick={() => setShowUpdate(true)}>
                    Update
                  </Dropdown.Item>
                )}

                <Dropdown.Item onClick={() => window.open(link, "_blank")}>
                  Go to Survey
                </Dropdown.Item>
                {
                  allowContentDelete && (
                      <Dropdown.Item onClick={() => {handleDelete()}}>
                      Delete
                    </Dropdown.Item>
                  )
                }
               
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        // restoreFellows={restoreFellows}
        clearFilter={clear}
      />
      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Surveys</h4>

            {(filtered || searched) && (
              <button
                onClick={() => {
                  setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1`);
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center d-none">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by name"
            />

            <button
              type="button"
              className="btn btn-outline ms-2 d-none "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button>

            <button
              type="button"
              className="btn btn-primary ml ms-2 d-none"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No Surveys avalaible.
                  </h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SurveysTable;
