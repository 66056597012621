import React, { useState } from "react";
import AdminLayout from "views/admin/Admin";
import { FadeLoader } from "react-spinners";
import CertificateTable from "./components/tables/CertificateTable";
import { useGetFellowCertificatesQuery } from "store/services/adminAPI";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";



const AdminCertificates = () => {
  usePermissionCheck(Page.LEARNING_TRACKER)
  const [filter, setFilter] = useState("page=1");

  const {
    data,
    isLoading,
  } = useGetFellowCertificatesQuery(filter);


  return (
    <AdminLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
            <div>
          <CertificateTable
            certificates={data?.data?.results}
            isLoading={isLoading}
            metadata={data?.data?.metadata}
            filter ={filter}
            setFilter={setFilter}
          />
            </div>
        )}
      </div>
    </AdminLayout>
  );
};
export default AdminCertificates;
