import React from "react";
import { Modal } from "react-bootstrap";
import {capitalize,mapStatusHeading} from "utils/strings";


export const AnalysisModal = ({
    showAnalysisModal,
    setShowAnalysisModal,
    data
}) => {
    return (
        <Modal
            show={showAnalysisModal}
            onHide={() => setShowAnalysisModal(false)}
            className="fade"
            id="progressModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Upload report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <h5>Upload Status:</h5>
                        <p>{capitalize(mapStatusHeading(data?.status))}</p>

                    </div>
                    <div className="d-flex justify-content-between">
                        <h5>Total Fellows Uploaded:</h5>
                        <p>{data?.total}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <h5>Number of processed entries:</h5>
                        <p>{data?.processed}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <h5>Number of failed entries:</h5>
                        <p>{data?.failed}</p>
                    </div>
                    <div>
                        <h5>Error:</h5>
                        <div style={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                            border: '1px solid #ccc',
                            padding: '10px',
                            marginTop: '5px'
                        }}>
                            <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{data?.error}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};