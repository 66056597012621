/** @format */

import { useEffect } from "react";
import { Path } from "utils/constant.data";
import { decrypt } from "utils/secure";
import { useNavigate } from "react-router-dom";

const usePermissionCheck = (permissionToCheck) => {
  const navigate = useNavigate();

  useEffect(() => {
    const _user = decrypt(window.localStorage.getItem("3MTAUSER"));
    const currentUser = JSON.parse(_user) ?? null;
    const permissions = currentUser?.role?.permissions?.map((_) => _?.slug);
    const access = permissions?.includes(permissionToCheck);
    if (!access) {
      navigate(Path.ADMIN_DASHBOARD);
    } 
  }, [navigate, permissionToCheck]);
};

export default usePermissionCheck;
