import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const AttendanceChart = ({attendance_data}) => {
    const present_fellows = attendance_data.map(item => item.present)
    const absent_fellows = attendance_data?.map(item => item.absent)
    const weeks = attendance_data?.map(item => item.week)

  const [chartData] = useState({
    series: [
      {
        data: present_fellows,
        name: "Present"
      },
      {
        data: absent_fellows,
        name: "Absent"
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      colors: ['#A6D693', '#B0B0B0'],
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: weeks,
      },
      yaxis: {
        categories: weeks, 

      }
      
      
    },
  });

  return (

    <div>
      {
        attendance_data?.length > 0 ? (
          <div>
            <div id="chart">
              <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={weeks.length *70 + 50} />
            </div>
          <div id="html-dist"></div>
         </div>
        ) : (
          <p>No data available under this category</p>
        )
      }
    </div>
  );
};

export default AttendanceChart;