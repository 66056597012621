/** @format */

import React, { useState, useEffect } from "react";
import {
  useGetAllPermissionsQuery,
} from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import PermissionsTable from "views/admin/table/Roles/PermissionsTable";

const PermissionsPage = () => {
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState("page=1");

  const {
    data,
    isLoading: fetchingUsers,
    isFetching,
  } = useGetAllPermissionsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);


  return (
    <section className="mt-5">
      {fetchingUsers ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <PermissionsTable
          users={data?.data?.permissions}
          metadata={data?.data?.metadata}
          setFilter={setFilter}
          setSkip={setSkip}
          filtering={fetchingUsers || isFetching}
          filter={filter}
        />
      )}
    </section>
  );
};
export default PermissionsPage;
