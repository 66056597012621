/** @format */

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./reducers/AuthReducer";
import UserReducer from "./reducers/UserReducer";
import ProfileReducer from "./reducers/ProfileReducer";

import { authApi } from "./services/authAPI";
import { adminApi } from "./services/adminAPI";
import { profileApi } from "./services/profileAPI";
import { notificationApi } from "./services/notification";

const middleware = applyMiddleware(
  thunk,
  authApi.middleware,
  adminApi.middleware,
  profileApi.middleware,
  notificationApi.middleware
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  profile: ProfileReducer,

  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
});

export const store = createStore(reducers, composeEnhancers(middleware));
