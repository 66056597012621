/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize, extractErrorMessage } from "utils/strings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import { useGetAllCoursesQuery } from "store/services/notification";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FadeLoader } from "react-spinners";
import FilterModal from "./helpers/FilterModal";
import { Style } from "utils/select";
import Select from "react-select";
import { useGetCohortsQuery } from "store/services/adminAPI";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import { useGetSingleCourseModuleQuery } from "store/services/notification";
import {
  useUpdateAssesmentMutation,
  useDeleteAssesmentMutation,
} from "store/services/notification";
import { formatDateToYYYYMMDD } from "utils/date";
import Switch from "react-switch";
import { weekNumbers } from "utils/constant.data";

const AssignmentsTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  filtering,
}) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [allowContentDelete] = useFeatureCheck(Checks.can_delete_assessment);
  const [allowContentView] = useFeatureCheck(Checks.can_view_assessment);
  const [allowContentUpdate] = useFeatureCheck(Checks.can_update_assessment);

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const filterString = `page=${_page}${
      modalFilter?.course_id?.length > 0
        ? `&course_id=${modalFilter?.course_id}`
        : ""
    }${
      modalFilter?.cohort_id?.length > 0
        ? `&cohort_id=${modalFilter?.cohort_id}`
        : ""
    }${
      modalFilter?.module_id?.length > 0
        ? `&module_id=${modalFilter?.module_id}`
        : ""
    }`;

    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Assement Name",
      accessor: "name",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.title)}`;
      },
    },

    {
      Header: "Cohort",
      accessor: "cohort",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value?.rank ? `Cohort ${value?.rank}` : "General";
      },
    },

    {
      Header: "Week Number",
      accessor: "week_number",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return `${value}`;
      },
    },

    {
      Header: "Start Date",
      accessor: "start_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${format(new Date(value), "d MMMM, yyyy")}` : "Null";
      },
    },

    {
      Header: "Duration",
      accessor: "duration",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${value} Days` : "Null";
      },
    },

    {
      Header: "End Date",
      accessor: "end_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${format(new Date(value), "d MMMM, yyyy")}` : "Null";
      },
    },
    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const {
          id,
          link,
          is_general,
          description,
          start_date,
          course_id,
          module_id,
          cohort_id,
          title,
          duration,
          course,
          cohort,
          status,
          module,
          week_number,
        } = cell?.row?.original;
        // console.log("cell?.row?.original", cell?.row?.original);
        const [showProfile, setShowProfile] = useState(false);
        const [showUpdate, setShowUpdate] = useState(false);
        const [selectedCategory, setCategory] = useState(null);
        const [selectedCohorts, setCohorts] = useState(null);
        const [selectedModule, setModule] = useState(null);
        const [submitted, setSubmitted] = useState(true);
        const [skip, setSkip] = useState(true);
        const [activeRecord, setActive] = useState(null);
        const [_status, setStatus] = useState(status);
        const [selectedWeek, setWeek] = useState("");

        const { data: modules, isLoading: loadingModules } =
          useGetSingleCourseModuleQuery(selectedCategory?.value, {
            skip: skip,
          });

        const { data: coursesList, isLoading: loadingCourses } =
          useGetAllCoursesQuery();
        const { data: cohortsList, isLoading: loadingCohorts } =
          useGetCohortsQuery();

        const Categories =
          coursesList?.data?.courses
            ?.filter((_) => {
              return _?.status;
            })
            ?.map((role) => ({
              value: role?.id,
              label: role?.title,
              code: role?.code,
            })) ?? [];

        // console.log("Categories", Categories);

        const Cohorts =
          cohortsList?.data?.cohorts?.map((role) => ({
            value: role?.id,
            label: role?.name + " " + role?.rank,
          })) ?? [];

        useEffect(() => {
          const _course = cell?.row?.original?.course;
          const _cohort = cell?.row?.original?.cohort;
          const _module = cell?.row?.original?.module;
          const _week = cell?.row?.original?.week_number;


          setWeek({
            value: _week,
            label: _week,
          });
          if (_course) {
            const preSelected = {
              value: _course?.id,
              label: _course?.title,
              code: _course?.code,
            };
            setCategory({ ...preSelected });
          }
          if (_cohort) {
            const _preSelected = {
              value: _cohort?.id,
              label: `${_cohort?.name} ${_cohort?.rank}`,
            };
            setCohorts({ ..._preSelected });
          }
          if (_module) {
            const _preSelected = {
              value: _module?.id,
              label: `${_module?.name} `,
            };
            setModule({ ..._preSelected });
          }
        }, [cell?.row]);

        useEffect(() => {
          if (selectedCategory?.value) {
            setSkip(false);
          } else {
            setSkip(true);
          }
        }, [selectedCategory]);

        const [updateAssesment, { isLoading: updatingAssesment }] =
          useUpdateAssesmentMutation();
        const [deleteAssesment, { isLoading: isDeleting }] =
          useDeleteAssesmentMutation();

        const FormSchema = Yup.object().shape({
          //   course_id: Yup.string().required("Course is required"),
          //   module_id: Yup.string().required("Course Module is required"),
          //   cohort_id: Yup.string().required("Cohort is required"),

          title: Yup.string().required("Assignment title is required"),
          description: Yup.string().required(
            "Assignment description is required"
          ),
          link: Yup.string()
            .required("Assignment Link is required")
            .url("Invalid URL. Please enter a valid link."),
          duration: Yup.number()
            .required("Please set a duration for the Assignment")
            .positive("Duration must be a positive number"),
          start_date: Yup.string().required(
            "Please set a start date for the Assignment"
          ),
        });

        const handleSubmit = async (values) => {
          setSubmitted(true);

          if (selectedCategory && selectedCohorts && selectedModule) {
            await updateAssesment({
              ...values,
              course_id: selectedCategory?.value,
              cohort_id: selectedCohorts?.value,
              module_id: selectedModule?.value,
              start_date: formatDateToYYYYMMDD(values.start_date),
              id,
            })
              .unwrap()
              .then((data) => {
                toast.success(`✔️ ${data?.message} !`, {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
                setShowUpdate(false);
                window.location.reload();
              })
              .catch((error) => {
                const formattedError = extractErrorMessage(error);
                Swal.fire({
                  title:
                    replaceUnderscoresWithSpaces(error?.data?.code) ||
                    "Error Occured",
                  text: formattedError,
                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                  showCloseButton: true,
                  customClass: {
                    icon: "no-border",
                  },
                  confirmButtonText: "Try Again",
                });
              });
          } else {
            toast.error(
              "❌ Please select relevant category or/and relevant cohort!",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              }
            );
          }
        };

        const handleStatus = async (status) => {
          await updateAssesment({
            status,
            id,
          })
            .unwrap()
            .then((data) => {
              toast.success(`✔️ ${data?.message} !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });
            })
            .catch((error) => {
              const formattedError = extractErrorMessage(error);
              Swal.fire({
                title:
                  replaceUnderscoresWithSpaces(error?.data?.code) ||
                  "Error Occured",
                text: formattedError,
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        const formik = useFormik({
          initialValues: {
            course_id,
            module_id,
            cohort_id,
            week_number,
            title,
            description,
            link,
            duration,
            start_date: new Date(start_date),
          },
          validationSchema: FormSchema,
          onSubmit: handleSubmit,
          enableReinitialize: true,
        });

        const Modules = modules?.data?.results
          ?.filter((_) => {
            return _?.status === "active";
          })
          ?.map((_) => {
            return {
              value: _?.id,
              label: _?.name,
              week_number: _?.week_number,
            };
          });

        return (
          <>
            {/* Profile Modal */}
            <Modal
              show={showProfile}
              onHide={() => setShowProfile(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Assignment</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowProfile(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
                          <h2 className="mb-0">{capitalize(title)}</h2>
                          <button
                            className="btn btn-outline"
                            onClick={() => window.open(link, "_blank")}
                          >
                            Go To Assignment
                          </button>
                        </div>
                      </div>

                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Category</h6>
                          <p>{course ? course?.title : "General"}</p>
                        </div>
                      </div>

                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Cohort</h6>
                          <p>{cohort ? `Cohort ${cohort?.rank}` : "General"}</p>
                        </div>
                      </div>
                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Module</h6>
                          <p>{module?.name}</p>
                        </div>
                      </div>
                      <div className="card-body mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>General Course</h6>
                          <p>{is_general ? "Yes" : "No"}</p>
                        </div>
                      </div>
                      <div className="card-body mt-2">
                        <div
                          className={`d-flex align-items-center justify-content-between ${
                            false ? "" : "disabled"
                          }`}
                        >
                          <h6>Status</h6>

                          {updatingAssesment ? (
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                          ) : (
                            <Switch
                              checked={_status}
                              onChange={() => {
                                Swal.fire({
                                  title: "Confirm Action",
                                  text: "Are you sure you want to change the statusof this resource",
                                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                  showCloseButton: true,
                                  customClass: {
                                    icon: "no-border",
                                  },

                                  showCancelButton: true,
                                  reverseButtons: true,
                                  confirmButtonText: "Proceed",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setStatus(!_status);
                                    handleStatus(!_status);
                                  }
                                });
                              }}
                              checkedIcon={false}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100 justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Profile Modal */}

            {/* Update Modal */}
            <Modal
              show={showUpdate}
              onHide={() => setShowUpdate(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update Assignment</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowUpdate(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <form className="comment-form" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="mb-1">Title</label>

                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            placeholder="Title"
                            {...formik.getFieldProps("title")}
                          />

                          {formik.touched.title && formik.errors.title ? (
                            <div classtitle="text-danger mt-2 fs-12">
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="mb-1">Assesment Link</label>

                          <input
                            type="text"
                            className="form-control"
                            id="link"
                            name="link"
                            placeholder="Course Link"
                            {...formik.getFieldProps("link")}
                          />

                          {formik.touched.link && formik.errors.link ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.link}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">Assesment Description</label>

                          <textarea
                            className="w-100 form-control"
                            placeholder="Your answer"
                            id="description"
                            name="description"
                            {...formik.getFieldProps("description")}
                            rows="3"
                          ></textarea>

                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {loadingCourses ? (
                        <p>Loading Courses.....</p>
                      ) : (
                        Categories && (
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <label>Course</label>

                              <Select
                                value={selectedCategory}
                                onChange={(_) => {
                                  setCategory(_);
                                  formik.setFieldValue("course_id", _?.value);
                                  //   setSkip(true);
                                }}
                                options={Categories}
                                styles={Style}
                              />

                              {submitted && !selectedCategory ? (
                                <div className="text-danger mt-2 fs-12">
                                  Please select relevant category
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      )}

                      {loadingCohorts ? (
                        <p>Loading Cohorts.....</p>
                      ) : (
                        Cohorts && (
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <label>Cohorts</label>
                              <Select
                                value={selectedCohorts}
                                onChange={(_) => {
                                  setCohorts(_);
                                  formik.setFieldValue("cohort_id", _?.value);
                                }}
                                options={Cohorts}
                                styles={Style}
                              />

                              {submitted && !selectedCategory ? (
                                <div className="text-danger mt-2 fs-12">
                                  Please select relevant category
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      )}

                      {/* <p>ddd: {JSON.stringify(selectedCategory)}</p> */}

                      {loadingModules ? (
                        <p>Loading Modules.....</p>
                      ) : (
                        modules && (
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <label>Modules</label>
                              <Select
                                value={selectedModule}
                                onChange={(_) => {
                                  setModule(_);
                                  formik.setFieldValue("module_id", _?.value);
                                }}
                                options={Modules}
                                styles={Style}
                              />

                              {formik.touched.module_id &&
                              formik.errors.module_id ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.module_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      )}

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Duration(in Minutes)</strong>
                          </label>

                          <input
                            className="form-control"
                            id="duration"
                            name="duration"
                            placeholder="Duration in Days"
                            {...formik.getFieldProps("duration")}
                            type="text"
                            pattern="[0-9]*"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />

                          {formik.touched.duration && formik.errors.duration ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.duration}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label>Week Number</label>

                          <Select
                            value={selectedWeek}
                            onChange={(_) => {
                              setWeek(_);
                              formik.setFieldValue("week_number", _?.value);
                            }}
                            options={weekNumbers}
                            styles={Style}
                          />

                          {formik.touched.week_number &&
                          formik.errors.week_number ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.week_number}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mb-3 w-full w-100">
                          <label className="d-block">Start Date</label>
                          <DatePicker
                            selected={formik.values.start_date}
                            onChange={(start_date) =>
                              formik.setFieldValue("start_date", start_date)
                            }
                            className="form-control bg-transparent w-full w-100"
                          />
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.start_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex justify-content-end">
                        <div className="form-group mb-3">
                          <button
                            type="submit"
                            className="submit btn btn-pigment-green"
                          >
                            {updatingAssesment ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              `Update Assignment`
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
            {/* Update Modal */}

            <Dropdown className="dropdown ms-auto text-right">
              <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                {allowContentView && (
                  <Dropdown.Item onClick={() => setShowProfile(true)}>
                    View
                  </Dropdown.Item>
                )}

                {allowContentUpdate && (
                  <Dropdown.Item onClick={() => setShowUpdate(true)}>
                    Update
                  </Dropdown.Item>
                )}

                <Dropdown.Item onClick={() => window.open(link, "_blank")}>
                  Go to Assignment
                </Dropdown.Item>

                {allowContentDelete && (
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => {
                      setActive(cell?.row?.original);
                      Swal.fire({
                        title: "Notice",
                        text: `You are trying to delete this Assignment ( ${capitalize(
                          title
                        )}) `,
                        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                        showCloseButton: true,
                        showCancelButton: true,
                        customClass: {
                          icon: "no-border",
                        },
                        confirmButtonText: "Cancel",
                        cancelButtonText: "Delete",
                      }).then(async (result) => {
                        if (!result.isConfirmed) {
                          await deleteAssesment({
                            id,
                          })
                            .unwrap()
                            .then((data) => {
                              toast.success(`✔️ ${data?.message} !`, {
                                position: "top-right",
                                autoClose: 2500,
                                hideProgressBar: false,
                                closeOnClick: true,
                              });
                            })
                            .catch((error) => {
                              Swal.fire({
                                title:
                                  replaceUnderscoresWithSpaces(
                                    error?.data?.code
                                  ) || "Error Occured",
                                text: error?.data?.detail,
                                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                                showCloseButton: true,
                                customClass: {
                                  icon: "no-border",
                                },
                                confirmButtonText: "Try Again",
                              });
                            });
                        }
                      });
                    }}
                  >
                    {cell?.row?.original?.id === activeRecord?.id &&
                    isDeleting ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Delete`
                    )}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        clearFilter={clear}
      />
      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Assignments</h4>

            {(filtered || searched) && (
              <button
                onClick={() => {
                  setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1`);
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by name"
            />

            <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button>

            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        setFilter(`page=${_newPage}`);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">No data for the specified filter</h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AssignmentsTable;
