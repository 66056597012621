/** @format */

import axios_instance from "store/constant/axiosInstance";
import {
  PROVIDER_PROFILE_REQUEST,
  PROVIDER_PROFILE_INFO_SUCCESS,
  PROVIDER_PROFILE_KYB_SUCCESS,
  PROVIDER_PROFILE_FAILURE,
} from "../actionTypes";

export const providerProfileRequest = () => ({
  type: PROVIDER_PROFILE_REQUEST,
});

export const providerProfileInfoSuccess = (data) => ({
  type: PROVIDER_PROFILE_INFO_SUCCESS,
  payload: data,
});

export const providerProfileKybSuccess = (data) => ({
  type: PROVIDER_PROFILE_KYB_SUCCESS,
  payload: data,
});

export const providerProfileFailure = (error) => ({
  type: PROVIDER_PROFILE_FAILURE,
  payload: error,
});

//get all provider profile
export const getProviderProfile = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(providerProfileRequest());
    axios_instance
      .get(`admin/profile`, {})
      .then(function (response) {
        dispatch(providerProfileInfoSuccess(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        var error_message = "";

        if (error.response === undefined) {
          error_message = error.message;
        } else {
          error_message = error.response.data.detail;
        }
        dispatch(providerProfileFailure(error_message));
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
};

//complete provider kyb
export const providerProfileKyb = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(providerProfileRequest());
    axios_instance
      .get(`provider/verify`, {})
      .then(function (response) {
        dispatch(providerProfileKybSuccess(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        var error_message = "";

        if (error.response === undefined) {
          // network error
          error_message = error.message;
        } else {
          error_message = error.response.data.detail;
        }
        dispatch(providerProfileFailure(error_message));
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
};
