import React, {useState} from "react";
import AdminLayout from "views/admin/Admin";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import AllProvidersAnalytics from "./AllProvidersAnalytics";
import ProviderFellowAnalytics from "./ProviderFellowAnalytics"
import { Link } from "react-router-dom";


const ProviderAnalytics = () => {
  usePermissionCheck(Page.LEARNING_TRACKER)
  const [activeToggle, setActiveToggle] = useState("alcs");

  return (
    <>
    <AdminLayout>
      <div className="mb-4">
        <div className="custom-tab-1">
        <ul className="nav nav-tabs">
            <li
              className="nav-item"
              onClick={() => setActiveToggle("alcs")}
            >
              <Link
                to="#alcs"
                data-toggle="tab"
                className={`nav-link text-pigment-green ${
                  activeToggle === "alcs" ? "active show" : ""
                } text-sm`}
              >
                ALC Analytics
              </Link>
              
            </li>
            <li
              className="nav-item"
              onClick={() => setActiveToggle("alcfellows")}
            >
              <Link
                to="#alcfellows"
                data-toggle="tab"
                className={`nav-link text-pigment-green ${
                  activeToggle === "alcfellows" ? "active show" : ""
                } text-sm`}
              >
                ALC Fellow's Analytics
              </Link>
            </li>
          </ul>
          </div>
        </div>
        {
          activeToggle === "alcs" && (
            <AllProvidersAnalytics />
          )
        }
        {
          activeToggle === "alcfellows" && (
            <ProviderFellowAnalytics />
          )
        }
    </AdminLayout>
    </>
  );
};
export default ProviderAnalytics;
