import React, {useState} from "react";
import AdminLayout from "views/admin/Admin";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import { useGetAttendanceAnalysisQuery } from "store/services/adminAPI";

import AttendanceFilterModal from "./components/modals/AttendanceFilterModal";
import ProviderAttendanceChart from "./components/charts/ProviderAttendanceChart";
import LGAttendanceChart from "./components/charts/LGAttendanceChart";
import StateAttendanceChart from "./components/charts/StateAttendanceChart";
import { Button } from "react-bootstrap";
import { FadeLoader } from "react-spinners";





const AttendanceAnalytics = () => {
  usePermissionCheck(Page.LEARNING_TRACKER)
  const [showFilter, setShowFilter] = useState(false);
  const [filterString, setFilterString] = useState("");

  const { data, isLoading: analyzing, isFetching: filtering } = useGetAttendanceAnalysisQuery(filterString);

  const isLoading = analyzing || filtering;


  return (



    
    <AdminLayout>
      <div className="mb-4">
      <>
      <AttendanceFilterModal 
        showModal={showFilter}
        setShowFilter={setShowFilter}
        filtering={filtering}
        setFilterString={setFilterString}
      />
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
            <h4 className="mt-2 ml-3">Computing...</h4>
          </div>
        ) : (
          <>
            <div className="flex gap-4 mb-4">
              <Button onClick={() => setShowFilter(true)}>
                Further Analyze
              </Button>
            </div>

            <div className="mt-4">
              <div className="row mb-4">
                <h4>Distribution of Attendance activity across states</h4>
                <StateAttendanceChart state_attendance_data={data?.data?.state_attendance_breakdown} />
              </div>

              <div className="row mt-3 mb-4">
                <h4>Distribution of Attendance activity across LGAs</h4>
                <LGAttendanceChart lga_attendance_data={data?.data?.lga_attendance_breakdown} />
              </div>

              <div className="row mt-3 mb-4">
                <h4>Distribution of Attendance activity across ALCs</h4>
                <ProviderAttendanceChart provider_attendance_data={data?.data?.provider_attendance_breakdown} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
        </div>
     
       
    </AdminLayout>
    
  );
};
export default AttendanceAnalytics;