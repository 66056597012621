/** @format */

// import { ColumnFilter } from "./ColumnFilter";
import { useState, useEffect } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize } from "utils/strings";
import { formatDateWithoutTime, getWeekDifference } from "utils/date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUpdateCohortMutation } from "store/services/adminAPI";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";


import * as Yup from 'yup'
import { useFormik } from "formik";
import {formatDateToYYYYMMDD} from "utils/date"
import { toast } from "react-toastify";





export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "Title",
    accessor: "name",
    disableFilters: true,
    sortable: false,

  },
  {
    Header: "Rank",
    accessor: "rank",
    disableFilters: true,
    sortable: false,
  },

  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
  },

  {
    Header: "Start Date",
    accessor: "start_date",
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(formatDateWithoutTime(cell?.row?.original?.start_date))}`;
    },
  },

  {
    Header: "End date",
    accessor: "end_date",
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(formatDateWithoutTime(cell?.row?.original?.end_date))}`;
    },
  },
  {
    Header: "Actions",
    sortable: false,
    accessor: "",
    Cell: ({ cell }) => {
      const {start_date, end_date} =  cell?.row?.original;
      const [showEditCohortModal, setShowEditCohortModal] = useState(false)
      const [initialValues, setInitialValues] = useState(null);

      const [UpdateCohort, {isLoading}] = useUpdateCohortMutation()

      const handleSubmit = async (values, { resetForm, initialValues }) => {
        try {
            const cleaned_date = formatDateToYYYYMMDD(values.start_date);

            const updatedFields ={}

            if (values.start_date !== cell?.row?.original.start_date || values.duration !== initialValues.duration) {
              updatedFields.start_date = values.start_date !== cell?.row?.original.start_date ? cleaned_date : cell?.row?.original.start_date;
              updatedFields.duration = values.duration;
            } else {
              toast.info('No changes made', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });

              return
            }
        

            await UpdateCohort({
                id: cell?.row?.original?.id,
                ...updatedFields
            })
            .unwrap();
            toast.success(`✔️ Cohort Updated!`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            resetForm()
            setTimeout(() => {
                setShowEditCohortModal(false);
            }, 1500);
    
        } catch (error) {
            console.error(error);
            Swal.fire({
                title:
                    replaceUnderscoresWithSpaces(error?.data?.code) ||
                    "Error Occurred",
                text: error?.data?.detail,
                iconHtml: `<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                    icon: "no-border",
                },
                confirmButtonText: "Try Again",
            });
        }
    };



    const FormSchema = Yup.object().shape({
      duration: Yup.number().required('Please set a duration for the cohort').positive('Duration must be a positive number'),
      start_date: Yup.string().required('Please set a start date for the cohort')
  
    })
    
    const isCohortEditable = (cohort_start_date) => {
      const currentDate = new Date();
      const cohortStartDate = new Date(cohort_start_date);
      
      return cohortStartDate < currentDate;
    };

    const formik = useFormik({
        initialValues: {
            duration: getWeekDifference(start_date, end_date),
            start_date: new Date(cell?.row?.original.start_date)
        },
        validationSchema: FormSchema,
        onSubmit: (values, { resetForm }) => handleSubmit(values, { resetForm, initialValues }),

        enableReinitialize:true
    })

    useEffect(() => {
      setInitialValues(formik.values);
    }, [formik.values]);

      return(
        <>
          <Modal 
            show={showEditCohortModal}
            onHide={()=>setShowEditCohortModal(false)}
            className="modal fade"
            id ="cohortModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Cohort</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowEditCohortModal(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="form-group mb-3">
                        <label>Duration (in weeks)</label>
                        <input
                            type="text"
                            className="form-control bg-transparent"
                            placeholder="Duration"
                            id="duration"
                            name="duration"
                            {...formik.getFieldProps("duration")}
                            pattern="[0-9]*" 
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />

                        {formik.touched.duration && formik.errors.duration ? (
                            <div className="text-danger mt-2 fs-12">
                                {formik.errors.duration}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group mb-3">
                        <label className="d-block">Start Date</label>
                        <DatePicker
                            selected={formik.values.start_date} 
                            onChange={(start_date) => formik.setFieldValue('start_date', start_date)} 
                            className="form-control bg-transparent"
                            />
                        {formik.touched.start_date && formik.errors.start_date ? (
                            <div className="text-danger mt-2 fs-12">
                                {formik.errors.start_date}
                            </div>
                        ) : null}
                    </div>
                </div>
                <button
                    className="btn btn-primary btn-sl-sm "
                    type="submit"
                    disabled={!formik.dirty} 
                >
                      {isLoading ? (
                        <span
                        role="button"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                        ></span>
                    ) : (
                        `Update Cohort`
                    )}
                </button>
              </form>
              </div>
            </div>
          </Modal>

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowEditCohortModal(true)} disabled={isCohortEditable(start_date)}>
                Edit Cohort
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>

      )
    }

  }
];
