/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { Nigerian_States, LocalGov, SkillLevel } from "utils/constant.data";
import {
  FellowOptions,
  LearningProgressOptions,
} from "utils/strings";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { extractFilterValues } from "utils/functions";
import { useGetAllCoursesQuery } from "store/services/notification";


const GenderOptions = [
  { value: "", label: "Select Gender" },
  { value: "male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilterString,
  filtering,
  clearFilter,
}) => {
  const [selectedStatus, setStatus] = useState([]);
  const [selectedGender, setGender] = useState([]);
  const [selectedLearning, setLearning] = useState([]);
  const [selectedAge, setAge] = useState([]);
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);
  const [selectedLevel, setLevel] = useState([]);
  const [selectedCourse, setCourses] = useState([]);
  const [selectedCohorts, setCohorts] = useState([]);

  const { data } = useGetCohortsQuery("");
  const {data:courses} = useGetAllCoursesQuery()


  useEffect(() => {
    setStatus([]);
    setGender([]);
    setAge([]);
    setLevel([]);
    setStates([]);
    setCohorts([]);
    setLGAs([]);
    setLearning([]);
  }, [clearFilter]);

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (
        selectedStatus.length === 0 &&
        selectedAge.length === 0 &&
        selectedLevel.length === 0 &&
        !selectedGender &&
        selectedStates.length === 0 &&
        selectedLGAs.length === 0 &&
        selectedCourse.length === 0 &&
        selectedCohorts.length === 0 &&
        selectedLearning.length === 0
      ) {
        toast.warn("✔️ Please Select a Parameter !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return;
    }

    const newFilter = {
        age: selectedAge,
        gender: selectedGender.value,
        training_progress: selectedLearning,
        cohort_id: selectedCohorts.map(cohort => cohort.value),
        proficiency_level: selectedLevel,
        status: selectedStatus,
        selectedStates: selectedStates,
        selectedLGAs: selectedLGAs,
        selectedCourse: selectedCourse,
    };


    const values = extractFilterValues(newFilter);
    
    const filterString = `${
        values?.status?.length > 0 ? `status=${values?.status?.join(",")}` : ""
    }${values?.gender ? `&gender=${values?.gender}` : ""}${
        values?.selectedStates?.length > 0
            ? `&selectedStates=${values?.selectedStates?.join(",")}`
            : ""
    }${
        values?.selectedLGAs?.length > 0
            ? `&lga=${values?.selectedLGAs?.join(",")}`
            : ""
    }${
        values?.selectedCourse?.length > 0
            ? `&course=${values?.selectedCourse?.join(",")}`
            : ""
    }${
        values?.proficiency_level?.length > 0
            ? `&proficiency_level=${values?.proficiency_level?.join(",")}`
            : ""
    }${
        values?.provider_id ? `&provider_id=${values?.provider_id}` : ""
    }${values?.cohort_id ? `&cohort_id=${values?.cohort_id.join(",")}` : ""}${
        values?.training_progress
            ? `&training_progress=${values?.training_progress}`
            : ""
    }`;

    setFilterString(filterString);
    setShowFilter(false)
};

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  const CourseOptions =
  courses?.data?.courses?.length > 0
    ? courses?.data?.courses?.map((course) => ({
        value: course?.id,
        label: course?.title,
      }))
    : [];

  const CohortOptions =
    data?.data?.cohorts?.length > 0
      ? data?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Analyze Applicants</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using Status</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedStatus}
                      onChange={setStatus}
                      options={FellowOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Analyze using Learning Progress</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLearning}
                      onChange={setLearning}
                      options={LearningProgressOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>{" "}
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using Gender</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedGender}
                      onChange={setGender}
                      options={GenderOptions}
                      styles={Style}
                    />
                  </div>
                </Col>
                {
                  CourseOptions?.length > 0 && (
                    <Col md={12}>
                    <p className="p-1 m-0">Analyze using Course</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCourse}
                        onChange={setCourses}
                        options={CourseOptions}
                        styles={Style}
                        isMulti
                      />
                    </div>
                  </Col>
                  )
                }
               
                {/* <Col md={12}>
                  <p className="p-1 m-0">Analyze using Age</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedAge}
                      onChange={setAge}
                      options={AgeOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                */}
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using Skill Level</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLevel}
                      onChange={setLevel}
                      options={SkillLevel}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using State of Residence</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedStates}
                      onChange={setStates}
                      options={StateOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
                {CohortOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Analyze using Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohorts}
                        onChange={setCohorts}
                        options={CohortOptions}
                        styles={Style}
                        isMulti
                      />
                    </div>
                  </Col>
                )}
                <Col md={12}>
                  <p className="p-1 m-0">Analyze using LGAs</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLGAs}
                      onChange={setLGAs}
                      options={getLGData()}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setStatus([]);
                  setGender([]);
                  setLearning([]);
                  setAge([]);
                  setStates([]);
                  setLGAs([]);
                  setLevel([]);
                  setCourses([]);
                  setCohorts([]);
                }}
              >
                Clear Parameters
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Analyzing ...." : "Analyze Applicants"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
