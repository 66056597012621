/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";

import { COLUMNS } from "./helpers/Columns";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import FilterModal from "./helpers/FilterModal";
import { extractFilterValues } from "utils/functions";
import ImportModal from "./helpers/ImportModal";


const MatchesTable = ({
  matchesHistory,
  metadata,
  isLoading,
  filter,
  setFilter,
  filtering
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [filtered, setFiltered] = useState(false)


  const [data, setData] = useState([]);


  const handleAllFilter = useCallback((modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);
    const filterString = `page=${_page}${
      values?.selectedStates?.length > 0
        ? `&state=${values?.selectedStates?.join(",")}`
        : ""
    }${
      values?.selectedLGAs?.length > 0
        ? `&lga=${values?.selectedLGAs?.join(",")}`
        : ""
    }${
      values?.selectedCourse?.length > 0
        ? `&course_id=${values?.selectedCourse?.join(",")}`
        : ""
    }${
      values?.provider_id
        ? `&provider_id=${values?.provider_id}`
        : ""
    }${values?.cohort_id ? `&cohort_id=${values?.cohort_id}` : ""}`;

    setFilter(filterString);
    setShowFilter(false);
  }, [setFilter]); // Only depends on setFilter, which shouldn't change

  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
  }, [modalFilter, handleAllFilter]);


  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
  }, [modalFilter, handleAllFilter]);

  useEffect(() => {
    setData(matchesHistory)
  }, [isLoading, matchesHistory]);

  
  const columns = useMemo(
    () => COLUMNS(),[]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;


  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <div>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        clearFilter={clear}
        setClear = {setClear}
      />
      <ImportModal
        setShowImportModal={setShowImportModal}
        showModal={showImportModal}
        />

      <div className={`card ${isLoading ? "disabled" : ""}`}>
        <div className={`card-header ${isLoading ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title">Matches</h4>
            {filtered && (
              <button
                onClick={() => {
                  setFiltered(false);
                  setFilter(`page=1`);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>
          
          <div className="d-flex  align-items-center"> 
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={()=>setShowImportModal(true)}
            >
              Upload
            </button>
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={()=>setShowFilter(true)}
            >
              Filter
            </button>
          </div>       
        </div>

        {isLoading || filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {matchesHistory?.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No matches available.
                  </h4>
                </div>
              </div>
             )}
          </div>
        )}
      </div>
    </div>
  );
};
export default MatchesTable;
