/** @format */

import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import axios_instance from "store/constant/axiosInstance";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { useBulkAssignMutation } from "store/services/adminAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";

// useBulkAssignCohortMutation

export const AssignModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
  setSelectedFellowIds,
}) => {
  const [provider, setProvider] = useState(null);
  const [bulkAssign, { isLoading }] = useBulkAssignMutation();

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    axios_instance
      .get(`admin/providers?status=Selected&search=${inputValue}`)
      .then((response) => {
        const results = response?.data?.data?.results;
        results?.length > 0
          ? callback(
              results.map((i) => {
                return {
                  label: i.name,
                  value: i.id,
                };
              })
            )
          : callback([]);
      })
      .catch((error) => {
        toast.error("❌ Error seraching for provider");
        callback([]);
      });
  }, 3000);

  const handleSubmit = async () => {
    await bulkAssign({
      provider_id: provider,
      fellows: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedIds([]);
        setSelectedFellowIds([]);
        setAction("");
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <Modal
      show={action === "assign" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Assign/Reassign Fellows</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
              <div className="card-header border-0 pb-0">
                <h5>Update Fellow(s) ALC</h5>
              </div>
              <div className="card-body pt-0">
                <div className="description">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={(inputValue, callback) =>
                      debouncedLoadOptions(inputValue, callback)
                    }
                    defaultOptions
                    onChange={(selectedOption) =>
                      setProvider(selectedOption?.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={provider && selectedIds?.length > 0 ? false : true}
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Assign/Reassign Fellows`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
