/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

import { useCreateAdminWebinarMutation } from "store/services/adminAPI";
import axios_instance from "store/constant/axiosInstance";

const CreateWebinar = ({ create, setCreate }) => {
  const [createAdminWebinar, { isLoading }] = useCreateAdminWebinarMutation();
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    await createAdminWebinar({
      ...values,
      episodes: [],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      thumbnail:""
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        if (file.size > 2 * 1024 * 1024) {
          toast.error("❌ File size exceeds the 2MB limit.!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          const formData = new FormData();
          file && formData.append("file", file);
          try {
            setUploading(true);
            const response = await axios_instance.post(
              "core/upload/file",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            toast.success(`✔️ Image uploaded!`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            formik.setFieldValue("thumbnail", response?.data?.data?.url);
            setUploading(false);
          } catch (e) {
            setUploading(false);
            toast.error("❌ Error occured uploading image!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        }
      } else {
        toast.error("❌ Only image files are allowed. !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <Modal className="modal fade" show={create}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create new Webinar</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form
            className={`comment-form ${
              isLoading || uploading ? "disabled" : ""
            } `}
            onSubmit={formik.handleSubmit}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Title</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Title"
                    {...formik.getFieldProps("title")}
                  />

                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Description</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="description"
                    name="description"
                    {...formik.getFieldProps("description")}
                    rows="3"
                  ></textarea>

                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="mb-1">
                  <label htmlFor="formFile" className="form-label">
                    Upload Image
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept=".jpeg, .jpg, .png"
                    onChange={handleFileChange}
                  />
                </div>

                {formik.errors.thumbnail ? (
                  <div className="text-danger mt-2 fs-12">
                    {formik.errors.thumbnail}
                  </div>
                ) : null}

                <div className="d-flex align-items-center mb-3">
                  {uploading && (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                      <p className="m-0 ml-2">Uploading Image....</p>
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading || uploading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create Webinar`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateWebinar;

const FormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  thumbnail: Yup.string().required("Thumbnail is required"),
  description: Yup.string().required("Description is required"),
});
