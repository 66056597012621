/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "views/admin/Admin";
import { useGetAdminForumsQuery } from "store/services/adminAPI";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import bg5 from "images/card/card22.jpg";
import { Path } from "utils/constant.data";
import useDebounce from "hooks/useDebounce";
import { filterArrayByKey } from "utils/functions";
import { useSelector } from "react-redux";
import { userSelector } from "store/reducers/UserReducer";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const AdminForum = () => {
  usePermissionCheck(Page.FORUM)
  const navigate = useNavigate();
  const urlParams = useParams();
  const [activeType] = useState(() => {
    return urlParams && urlParams?.activeType ? urlParams?.activeType : null;
  });
  // eslint-disable-next-line no-unused-vars
  const [activeRooms, setActiveRooms] = useState([]);
  const { data, isLoading: fetchingFellows } = useGetAdminForumsQuery();
  const { activeRoom } = useSelector(userSelector);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const filteredData = filterArrayByKey(debouncedSearchTerm, activeRooms);

  const filterByRoomType = (data, suppliedRoomType) => {
    const lowerSuppliedRoomType = suppliedRoomType.toLowerCase();
    return data.filter(
      (item) => item.room_type.toLowerCase() === lowerSuppliedRoomType
    );
  };

  useEffect(() => {
    if (data?.data) {
      if (activeType || activeRoom) {
        const filteredArray = filterByRoomType(
          data?.data,
          activeRoom || activeType
        );
        setActiveRooms([...filteredArray]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeType, activeRoom]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-sm-12 mb-3">
          <div className="d-flex justify-content-end align-items-center">
            <div className="form-group mb-3 forum-search">
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                placeholder="Search"
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : filteredData?.length > 0 ? (
          filteredData?.map((room, index) => {
            return (
              <div className="col-xl-4 col-lg-12 col-sm-12" key={room?.id}>
                <div className="card overflow-hidden">
                  <div
                    className={`text-center p-5 overlay-box ${
                      index === 0 ? "zero" : index === 1 ? "one" : "two"
                    }`}
                    style={{ backgroundImage: `url(${bg5})` }}
                  >
                    <figure>
                      <i
                        class="fa fa-comments text-white chat-icon"
                        aria-hidden="true"
                      ></i>
                    </figure>
                    <h3 className="mt-3 mb-0 text-white">{room?.name}</h3>
                    <p className="text-white"> {room?.description} </p>
                  </div>
                  <div className="card-body">
                    <div className="row text-center">
                      <div className="col-6">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">Members</h4>
                          <small>&nbsp; - &nbsp;</small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">Manager</h4>
                          <small>
                            {room?.room?.manager
                              ? room?.room?.manager
                              : "Unassigned"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer mt-0">
                    <button
                      onClick={() =>
                        navigate(
                          `${Path.ADMIN_FORUM}/${activeType}/${room?.id}`
                        )
                      }
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Enter Forum
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">No Forum yet</h4>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};
export default AdminForum;
