/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, {useEffect, useState } from "react";
import { capitalize, mapStatusClass, mapStatusHeading, truncateString } from "utils/strings";
import { replaceSpace } from "utils/strings";
import { useUpdateProviderDetailsMutation } from "store/services/adminAPI";
import { toast } from "react-toastify";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";
import ChangeEmailModal from "./ChangeEmailModal";
import BranchesModal from "./BranchesModal";
import { useFormik } from "formik";
import * as Yup from "yup";


export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <input 
          type="checkbox" 
          checked={selectedIds.includes(row?.original?.id) ? true :false} 
          onChange={() => handleRowSelection(row?.original?.id)} 
        />
      );
    },
  },
  {
    Header: "Name",
    accessor: "name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.name, 15))} `;
    },
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(
        truncateString(cell?.row?.original?.poc_email, 15)
      )} `;
    },
  },

  {
    Header: "Phone Number",
    accessor: "poc_phone",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(
        truncateString(cell?.row?.original?.poc_phone, 15)
      )} `;
    },
  },

  {
    Header: "Provider ID",
    accessor: "provider_id",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.provider_id)} `;
    },
  },

  {
    Header: "Fellow COunt",
    accessor: "fellow_count",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${cell?.row?.original?.fellow_count} `;
    },
  },


  {
    Header: "State",
    accessor: "state",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },

  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Filter: ColumnFilter,
    Cell: ({ cell: { value } }) => {
      let _class = mapStatusClass(replaceSpace(value));
      return (
        <span className={`badge light badge-${_class}`}>
          <i className="fa fa-circle text-danger me-1" />
          { mapStatusHeading(value)}
        </span>
      );
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {


      const { name, poc_phone, poc_email, state, provider_id, id, organisation_email } =
      cell?.row?.original;
      const [allowEdit]= useFeatureCheck(Checks.update_provider_profile)
      const [allowProviderEmailChange] = useFeatureCheck(Checks.update_update_provider_email)
      const [showProfile, setShowProfile] = useState(false);
      const [editMode, setEditMode] = useState(false);
      const [showEmailChangelModal, setShowEmailChangeModal] = useState(false)
      const [showBranchesModal, setShowBranchesModal] = useState(false)

      const [UpdateProviderDetails, {isLoading}] = useUpdateProviderDetailsMutation();

      const [currentName, setCurrentName] = useState(name);
      const [currentPOCPhone, setCurrentPOCPhone] = useState(name);
      const [currentOrganisationEmail, setCurrentOrganisationEmail] = useState(organisation_email)

      useEffect(()=>{
        setCurrentName(name);
        setCurrentPOCPhone(poc_phone);
        setCurrentOrganisationEmail(organisation_email)
      }, [name, poc_phone, organisation_email])

      const hideEmailChangeModal = () => {
        setShowEmailChangeModal(false)
      }

      const hideBranchesModal = () => {
        setShowBranchesModal(false)
      }
      
      const handleSubmit = async(values)=>{

        if (!formik.dirty) {
          toast.error("❌ Please update at least one field before submitting.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return;
        }

          try {
           await UpdateProviderDetails({
              id: cell?.row?.original?.id,
              ...values,
            });
            toast.success(`✔️ Profile updated successfully!`, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setEditMode(false)
            
            setTimeout(() => {
              setShowProfile(false);
            }, 1000);
  
          } catch (error) {
            toast.error("❌ Could not update profile, an error occured;", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });        
          }
      }

      const formik = useFormik({
        initialValues: {
          poc_phone: currentPOCPhone ? currentPOCPhone : "",
          name: currentName ? currentName : "",
          organisation_email: currentOrganisationEmail ? currentOrganisationEmail : "",
        },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
      });

      return (
        <>
          {/* ProfileModal */}
          <ChangeEmailModal 
            showEmailChangelModal={showEmailChangelModal}
            hideEmailChangeModal = {hideEmailChangeModal}
            providerId = {id}
          />
          <BranchesModal 
            showBranches={showBranchesModal}
            hideBranches={hideBranchesModal}
            providerId={id}
          />
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Provider Details</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                {editMode ? (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label>Update POC Name</label>
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          placeholder="Change name"
                          id="name"
                          name="name"
                          {...formik.getFieldProps("name")}
                        />

                          {formik.touched.name &&
                          formik.errors.name ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.name}
                            </div>
                          ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label>Update POC Number</label>
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          placeholder="Change Phone"
                          id="poc_phone"
                          name="poc_phone"
                          {...formik.getFieldProps("poc_phone")}
                        />
                          {formik.touched.poc_phone &&
                            formik.errors.poc_phone ? (
                              <div className="text-danger mt-2 fs-12">
                                {formik.errors.poc_phone}
                              </div>
                            ) : null}
                      </div>

                      <div className="form-group mb-3">
                        <label>Update Organisation Email</label>
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          placeholder="Change organisation email"
                          id="organisation_email"
                          name="organisation_email"
                          {...formik.getFieldProps("organisation_email")}
                        />
                          {formik.touched.organisation_email &&
                              formik.errors.organisation_email ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.organisation_email}
                                </div>
                              ) : null}
                      </div>
                      <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                      <Button
                          variant="primary"
                          type="submit"
                          onClick={(e) => setEditMode(false)}
                        >
                          Discard
                        </Button>
                        <Button
                          variant="outline"
                          type="submit"
                        >
                          {isLoading ? (
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                          ) : (
                            ` Update`
                          )}
                        </Button>
                        
                      </div>
                    </div>
                    
                  </form>
                ) : (
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <h5>
                          {capitalize(name)} ({provider_id})
                        </h5>
                      </div>
                      <div className="card-body pt-0">
                        <div className="description">
                          <p>{capitalize(poc_email)}</p>
                          <ul className="d-flex align-items-center raiting flex-wrap">
                            {/* <li>
                            <span className="font-w500">
                              Email: {poc_email}
                            </span>
                          </li> */}
                            <li>
                              <span className="font-w500">
                                Number: {poc_phone}
                              </span>
                            </li>
                          </ul>
                          <div className="user-pic mb-3">
                            <span>State: {capitalize(state)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!editMode && (
                  <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>

                    {allowEdit && (
                      <Button
                        variant="outline"
                        onClick={(e) => setEditMode(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal>
          {/* Profile Modal */}

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowBranchesModal(true)}>
                View Branches
              </Dropdown.Item>
              {
                allowProviderEmailChange && (
                  <Dropdown.Item onClick={() => setShowEmailChangeModal(true)}>
                    Change Email
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];

const FormSchema = Yup.object().shape({
  organisation_email: Yup.string().email("Invalid email address"),
  name: Yup.string().required("Organisation Name is required"),
  poc_phone: Yup.string().required("POC phone number is required"),
});