/** @format */

import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";

import { useCreateAdminFAQMutation } from "store/services/adminAPI";

const CreateFAQ = ({ createBroadcast, setCreate, setSkip, data }) => {
  const [createAdminFAQ, { isLoading }] = useCreateAdminFAQMutation();

  useEffect(() => {
    setSkip(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    await createAdminFAQ({
      ...values,
    })
      .unwrap()
      .then((data) => {
        setSkip(false);
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      faq_type_id: "",
      question: "",
      answer: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const types =
    data?.data && data?.data?.length > 0
      ? data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [];

  return (
    <Modal className="modal fade" show={createBroadcast}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create new faq</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Type</strong>
                  </label>

                  <select
                    id="faq_type_id"
                    name="faq_type_id"
                    className="form-control"
                    {...formik.getFieldProps("faq_type_id")}
                  >
                    <option value="">Select type</option>

                    {types
                      ? types.map((_) => {
                          return <option value={_.value}>{_.label}</option>;
                        })
                      : null}
                  </select>

                  {formik.errors.faq_type_id ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.faq_type_id}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Question</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="question"
                    name="question"
                    placeholder="Title"
                    {...formik.getFieldProps("question")}
                  />

                  {formik.touched.question && formik.errors.question ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.question}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Answer</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="answer"
                    name="answer"
                    {...formik.getFieldProps("answer")}
                    rows="3"
                  ></textarea>

                  {formik.touched.answer && formik.errors.answer ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.answer}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create FAQ`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFAQ;

const FormSchema = Yup.object().shape({
  faq_type_id: Yup.string().required("Type is required"),
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answeris required"),
});
