/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { StatusModal } from "./helpers/StatusModal";
import { InviteModal } from "./helpers/InviteModal";

const UsersTable = ({
  users,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  // const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [invite, setInvite] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState("");

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  // eslint-disable-next-line no-unused-vars
  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = users.map((_F) => _F?.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (modalFilter) {
      // setSearch("");
      const values = extractFilterValues(modalFilter);

      const filterString = `page=1${
        values?.status?.length > 0 ? `&status=${values?.status?.join(",")}` : ""
      }${
        values?.selectedStates?.length > 0
          ? `&state=${values?.selectedStates?.join(",")}`
          : ""
      }${
        values?.selectedLGAs?.length > 0
          ? `&lga=${values?.selectedLGAs?.join(",")}`
          : ""
      }`;

      setFiltered(true);
      setFilter(filterString);
      setShowFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    setData([...users]);
    setSkip(true);
  }, [users, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(
    () => COLUMNS(selectedIds, handleRowSelection),
    [selectedIds, handleRowSelection]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreProviders = () => {
    setClear(!clear);
    setFiltered(false);
    // setSearch("");
    setSearched(false);
    setFilter("page=1");
  };

  // const handleInputChange = (event) => {
  //   setSearch(event.target.value);
  // };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreProviders={restoreProviders}
        clearFilter={clear}
      />

      <InviteModal showModal={invite} setShowModal={setInvite} />
      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            {/* <div className="mr-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll()}
              />
            </div> */}

            <h4 className="card-title">Users</h4>
            {/* {selectedIds?.length > 0 && (
              <div className="ml-2">
                <select
                  className="form-control bulk-select"
                  id="action"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                >
                  <option value="">Choose Bulk Option</option>
                  <option value="update_status">Update Provider Status</option>
                </select>
              </div>
            )} */}
            {(filtered || searched) && (
              <button
                onClick={() => {
                  // setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1`);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            {/* <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by company name, Reg number and email "
            />

            <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button> 

            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>*/}

            <button
              type="button"
              style={{
                // width: "200px",
              }}
              className="btn btn-outline ml ms-2"
              onClick={() => setInvite(true)}
            >
              Invite User
            </button>
          </div>
        </div>

        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          setFilter(`page=${_newPage}`);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <StatusModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No users {filtered ? "for the specified filter" : "yet"}
                  </h4>
                  <button
                    onClick={() => restoreProviders()}
                    className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default UsersTable;
