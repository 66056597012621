/** @format */

import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Button } from "react-bootstrap";

import { GlobalFilter } from "./helpers/GlobalFilter";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import swal from "sweetalert";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { extractErrorMessage } from "utils/strings";

import {
  useFilterFellowsMutation,
  useExportFellowsMutation,
} from "store/services/adminAPI";

export const ProviderFellowsTable = ({ fellows }) => {
  const [filter, setFilter] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);

  const [filterFellows, { isLoading: filtering }] = useFilterFellowsMutation();
  const [exportFellows, { isLoading: exporting }] = useExportFellowsMutation();

  const handleFellowsFilter = async (values) => {
    const payload = {
      age: values?.age?.length > 0 ? values?.age : [],
      status: values?.status?.length > 0 ? values?.status : [],
      gender: values?.gender?.length > 0 ? values?.gender : "",
      skill: values?.skill_level?.length > 0 ? values?.skill_level : [],
    };

    await filterFellows(payload)
      .unwrap()
      .then((data) => {
        setData(data?.data);
        setShowFilter(false);
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: "Error occured filtering records",
          buttons: {
            confirm: {
              text: "Try Again",
              value: true,
              visible: true,
              className: "btn btn-primary btn-block",
              closeModal: true,
            },
          },
          icon: "error",
        });
      });
  };

  const handleFellowsExport = async () => {
    let payload = {};

    if (filter === null) {
      payload = {
        type: "EXCEL",
      };
    } else {
      const values = extractFilterValues(filter);
      payload = {
        age: values?.age?.length > 0 ? values?.age : [],
        status: values?.status?.length > 0 ? values?.status : [],
        gender: values?.gender?.length > 0 ? values?.gender : "",
        skill: values?.skill_level?.length > 0 ? values?.skill_level : [],
        type: "EXCEL",
      };
    }

    await exportFellows(payload)
      .unwrap()
      .then((data) => {
        const downloadURL = data?.data?.url;
        window.open(downloadURL);
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)

        swal({
          title: "Error",
          text: formattedError,
          buttons: {
            confirm: {
              text: "Try Again",
              value: true,
              visible: true,
              className: "btn btn-danger btn-block",
              closeModal: true,
            },
          },
          icon: "error",
        });
      });
  };

  useEffect(() => {
    setData([...fellows]);
  }, [fellows]);

  useEffect(() => {
    if (filter) {
      const values = extractFilterValues(filter);
      handleFellowsFilter(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => fellows, [fellows]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    // gotoPage,
    // pageCount,
    pageOptions,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    setGlobalFilter,
    // page,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const restoreFellows = () => {
    setData(fellows);
  };

  return (
    <>
      <FilterModal
        setFilter={setFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
      />

      <div className="card">
        <div className="card-header">
          <h4 className="card-title text-pigment-green">All Fellows</h4>
        </div>
        <div className="card-body">
          {data.length > 0 ? (
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <div className="d-flex justify-content-between mb-5">
                  <div className="w-50">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>

                  <div>
                    <Button
                      className="ms-2 me-2 py-3 btn-pigment-green-outline "
                      style={{ width: "100% !important" }}
                      onClick={() => setShowFilter(true)}
                    >
                      Filter
                    </Button>

                    <Button
                      onClick={() => handleFellowsExport()}
                      className="ms-2 pull-right  py-3 btn-pigment-green"
                    >
                      {exporting ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        "Export"
                      )}
                    </Button>
                  </div>
                </div>

                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            {/* <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i
                                  className="fa fa-arrow-down ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : (
                                <i
                                  className="fa fa-arrow-up ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              )
                            ) : (
                              <i
                                className="fa fa-sort ms-2 fs-14"
                                style={{ opacity: "0.3" }}
                              />
                            )}
                          </span> */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
              </div>
              {/* <div className="text-center mb-5">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div> */}
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon  text-primary">
                <BagIcon />
                <h4 className="my-2">No fellows for the specified filter</h4>
                <button
                  onClick={() => setShowFilter(true)}
                  className="btn my-2 btn-primary btn-lg px-4"
                >
                  Filter
                </button>
                <button
                  onClick={() => restoreFellows()}
                  className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                >
                  Clear Filter
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProviderFellowsTable;
