/** @format */

import React, { useState } from "react";
import AdminLayout from "views/admin/Admin";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import ProviderFilterModal from "./components/ProviderFilter";
import { Path } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios_instance from "store/constant/axiosInstance";
import { useNavigate } from "react-router-dom";
import { StyleTwo } from "utils/select";
import Select from "react-select";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { fellowStatus, providerStatus } from "utils/constant.data";

const AdminCreateEmail = () => {
  usePermissionCheck(Page.EMAIL);
  const [richText, setRichText] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const [selectedStatus, setStatus] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSampleMode, setisSampleMode] = useState(false);
  const [sampleRecipientEmails, setSampleRecipientEmails] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [focused, setFocused] = useState(true);

  const clearForm = () => {
    formik.resetForm();
    setRichText(null);
    setSelectedFile(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitted(true);

    if (selectedStatus.length < 1 && !isSampleMode) {
      setSubmitted(false);
      toast.error("❌ Please select relevant status!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    if (!richText) {
      toast.error("❌ Email body is required!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      selectedFile && formData.append("flier", selectedFile);
      formData.append("subject", values.subject);
      formData.append("body", richText);
      if (!isSampleMode) {
        formData.append(
          "status",
          selectedStatus.map((item) => item.value).join(",")
        );
        formData.append("receiver_type", values.receiver_type);
      }
      if (isSampleMode) {
        formData.append("sample_recipients", sampleRecipientEmails);
        formData.append("is_sample", true);
        formData.append("receiver_type", "fellow");
        formData.append("status", "Selected");
      }

      await axios_instance.post("emails/send", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(`✔️ Email sent successfully!`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setLoading(false);
      clearForm();
      navigate(Path.ADMIN_EMAILS);
    } catch (error) {
      toast.error("❌ Error sending email. !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        setSelectedFile(file);
      } else if (file.size > 2 * 1024 * 1024) {
        toast.error("❌ File size exceeds the 2MB limit.!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedFile(null);
      } else {
        toast.error("❌ Only image files are allowed. !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedFile(null);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      receiver_type: "",
      status: "",
      subject: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleEditorChange = (content) => {
    setRichText(content);
  };

  return (
    <AdminLayout>
      <div className="row">
        <section className="d-flex flex-column bg-white">
          <div className="d-flex align-items-center justify-content-between mb-4 pt-5">
            <h4>To:</h4>
            <div className="toolbar" role="toolbar">
              <div className="btn-group mb-1 me-2">
                <button
                  onClick={() => clearForm()}
                  type="button"
                  className="btn btn-primary light px-3"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <section className="d-flex flex-column">
            <div className="col-xl-12 col-lg-12">
              <div className="email-right-box ms-0 ms-sm-4 ms-sm-0">
                <div className="compose-content">
                  {/* TEST EMAIL BUTTON */}
                  <div className="row mb-3">
                    <div className="col-md-6 d-flex align-items-center col-gap-4">
                      <h4 className="mb-0 mr-4">Enable test mode:</h4>
                      <label className="toggle-switch ml-3 mb-0">
                        <input
                          type="checkbox"
                          checked={isSampleMode}
                          onChange={() => setisSampleMode(!isSampleMode)}
                        />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    {/* SHOW THE STANDARD RECIPIENT SELECTION IF ON LIVE MODE AND EMAIL ADDRESSES IF ON TEST MODE */}
                    {isSampleMode ? (
                      <div className="row">
                        <div className="form-group mb-3">
                          <label>Recipient</label>
                          <ReactMultiEmail
                            placeholder="Enter email addresses"
                            emails={sampleRecipientEmails}
                            inputClassName="form-control"
                            onChange={(sampleRecipientEmails) => {
                              setSampleRecipientEmails(sampleRecipientEmails);
                            }}
                            autoFocus={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  <div data-tag-item>{email}</div>
                                  <span
                                    data-tag-handle
                                    onClick={() => removeEmail(index)}
                                  >
                                    ×
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>Recipient</label>
                            <select
                              className="form-control"
                              id="receiver_type"
                              name="receiver_type"
                              {...formik.getFieldProps("receiver_type")}
                              onChange={(e) => {
                                setStatus([]);
                                formik.setFieldValue(
                                  "receiver_type",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="">Select Recipient</option>;
                              <option value="fellow">Fellow</option>;
                              <option value="provider">Provider</option>;
                            </select>

                            {formik.touched.receiver_type &&
                            formik.errors.receiver_type ? (
                              <div className="text-danger mt-2 fs-12">
                                {formik.errors.receiver_type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                        <div className="form-group mb-3">
                          <lable>Status</lable>
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                            {...formik.getFieldProps("status")}
                          >
                            <option value="">Select Status</option>;
                            <option value="Selected">Selected</option>;
                            <option value="Enrolled">Enrolled</option>;
                          </select>

                          {formik.touched.status && formik.errors.status ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>Status</label>
                            <Select
                              value={selectedStatus}
                              onChange={setStatus}
                              options={
                                formik.values.receiver_type === "provider"
                                  ? providerStatus
                                  : formik.values.receiver_type === "fellow"
                                  ? fellowStatus
                                  : []
                              }
                              styles={StyleTwo}
                              isMulti
                            />

                            {submitted && selectedStatus.length < 1 ? (
                              <div className="text-danger mt-2 fs-12">
                                Please select relevant status!
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control bg-transparent"
                        placeholder=" Subject:"
                        id="subject"
                        name="subject"
                        {...formik.getFieldProps("subject")}
                      />

                      {formik.touched.subject && formik.errors.subject ? (
                        <div className="text-danger mt-2 fs-12">
                          {formik.errors.subject}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group mb-3">
                      <Editor
                        apiKey="kprm34bkn1d4b0j7fjo1hhqt9mf5dzhxlbkn6ugj9b262exg"
                        initialValue={null}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link  charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],

                          toolbar:
                            "undo redo | formatselect  |link | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                            "bullist numlist outdent indent | removeformat | help ",
                          content_style: "body { color: #828282 }",
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">
                        Upload Image
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        accept=".jpeg, .jpg, .png"
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="text-left mt-4 mb-5">
                      <button
                        className="btn btn-danger light btn-sl-sm me-2"
                        type="button"
                        onClick={() => clearForm()}
                      >
                        <span className="me-2">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                        Discard
                      </button>
                      <button
                        className="btn btn-primary btn-sl-sm "
                        type="submit"
                      >
                        {loading ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2 text-white"
                          ></span>
                        ) : (
                          <>
                            <span className="me-2">
                              <i className="fa fa-paper-plane"></i>
                            </span>
                            Send
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      <ProviderFilterModal />
    </AdminLayout>
  );
};
export default AdminCreateEmail;

const FormSchema = Yup.object().shape({
  // receiver_type: Yup.string().required("Reciever required"),
  receiver_type: Yup.string(),

  subject: Yup.string().required("Message subject is required"),
  // status: Yup.string().required("Message status is required"),
});

// plugins: [
//   "advlist autolink lists link image code charmap print preview anchor",
//   "searchreplace visualblocks code fullscreen",
//   "insertdatetime media table paste code help wordcount",
// ],
// toolbar:
//   "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
//   "bullist numlist outdent indent | removeformat | help ",
