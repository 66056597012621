/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
  capitalize,
  truncateString,
} from "utils/strings";

export const COLUMNS = (selectedIds, handleRowSelection) => [
  // {
  //   Header: "",
  //   accessor: "id",
  //   disableSortBy: true,
  //   Cell: ({ row }) => {

  //     return (
  //       <input
  //         type="checkbox"
  //         checked={selectedIds.includes(row?.original?.id) ? true :false}
  //         onChange={() => handleRowSelection(row?.original?.id)}
  //       />
  //     );
  //   },
  // },
  {
    Header: "Role Title",
    accessor: "name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.name, 15))} `;
    },
  },
  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const [showProfile, setShowProfile] = useState(false);

      return (
        <>
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Provider Details</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form>
                  <Col md={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="">Role</h6>
                      <h6 className="text-primary">
                        {cell?.row?.original?.name}{" "}
                      </h6>
                    </div>
                  </Col>
                </form>
              </div>
            </div>
          </Modal>

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Permission
              </Dropdown.Item> 
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];
