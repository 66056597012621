import React from "react";
import { Link } from "react-router-dom";
import MMLogo from "images/3MTT/3MM.png";
import loginbg from "images/bg-1.svg"; 
import { Path } from "utils/constant.data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "store/services/authAPI";
import { toast } from "react-toastify";


const ForgotPasswordPage = ({ history }) => {

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   history.push("/dashboard");
  // };


  const [forgotPassword, {isLoading}] = useForgotPasswordMutation();


  const handleSubmit = async (values, {resetForm}) => {
    await forgotPassword(values)

    .unwrap()
    .then((data)=>{
      toast.success(`✔️ ${data?.detail}`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      resetForm()
    })
    .catch((error)=>{
        // Swal.fire()
    })
  }

  const formik = useFormik({
    initialValues: {
        email: "",
        redirect_url: "https://data.3mtt.training/reset-password"
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,

  })

  return (
    <div className="vh-100"> 
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg + 
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}> 
                          <img src={MMLogo} alt="" /> 
                        </Link>
                      </div>
                      <h4 className="text-center mb-4">Forgot Password</h4> 
                      <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="form-group mb-3"> 
                          <label>
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control" 
                            id="email"
                            name="email"
                            placeholder="e.g. admin@fmcide.gov.ng"
                            {...formik.getFieldProps("email")}
                          />
                           {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.email}
                            </div>
                          ) : null}
                          <div className="row d-flex justify-content-between mt-2 mb-2">
                          <p className="">
                            <Link className="text-primary" to={Path.LOGIN}>
                              Back to Login
                            </Link>
                          </p>
                        </div>
                        </div>
                        <div className="text-center">
                          <button
                              type="submit"
                              className="btn btn-primary btn-block"
                          >
                            {isLoading ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              `Request Link`
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      // .matches(
      //   /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+(gov\.ng)$/,
      //   "Email must end with gov.ng"
      // ), 
  });
