/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { Modal, Button } from "react-bootstrap";
import SubmissionsTable from "./components/SubmissionTable";
import Select from "react-select";
import { Style } from "utils/select";
import { Page } from "utils/constant.data";
import {
  useGetAllCoursesQuery,
  useGetSingleCourseModuleQuery,
  useGetAllAssesmentSubmissionQuery,
} from "store/services/notification";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { AssesmentStatus } from "utils/strings";
import { toast } from "react-toastify";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

const ClearIndicator = (props) => {
  const {
    children = "Clear Status",
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const Submissions = () => {
  const [allowContentView] = useFeatureCheck(
    Checks.can_view_assessment_submissions
  );
  const [skip, setSkip] = useState(Page.ASSESMENT);
  const [filterString, setFilterString] = useState("page=1");
  const [createResource, setCreate] = useState(false);
  const [_skip, _setSkip] = useState(true);
  const [selectedCategory, setCategory] = useState(null);
  const [selectedModule, setModule] = useState(null);
  const [selectedCohort, setCohort] = useState(null);
  const [selectedStatus, setStatus] = useState(null);
  const [filtered, setFiltered] = useState(null);

  const {
    data: courseModules,
    isLoading,
    isFetching: isFetchingAssesments,
  } = useGetAllAssesmentSubmissionQuery(filterString, {
    skip: skip,
  });

  useEffect(() => {
    if (filterString && filterString?.length > 0) {
      setSkip(false);
    }
  }, [filterString]);


  const { data: courses, isLoading: loadingCourses } = useGetAllCoursesQuery();
  const {
    data: modules,
    isLoading: loadingModules,
    isFetching,
  } = useGetSingleCourseModuleQuery(selectedCategory?.value, {
    skip: _skip,
  });
  const { data: allCohorts, isLoading: loadingCohorts } = useGetCohortsQuery();

  const Categories = courses?.data?.courses
    ?.filter((_) => {
      return _?.status;
    })
    ?.map((role) => ({
      value: role?.id,
      label: role?.title,
      code: role?.code,
    }));

  const Modules = modules?.data?.results
    ?.filter((_) => {
      return _?.status === "active";
    })
    ?.map((_) => {
      return {
        value: _?.id,
        label: _?.name,
        week_number: _?.week_number,
      };
    });

  const Cohorts = allCohorts?.data?.cohorts?.map((role) => ({
    value: role?.rank,
    label: role?.name + " " + role?.rank,
  }));

  useEffect(() => {
    if (selectedCategory?.value) {
      _setSkip(false);
    } else {
      _setSkip(true);
    }
  }, [selectedCategory]);

  const result = courseModules?.data?.results ?? [];

  return (
    allowContentView && (
      <section className="mt-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            {filtered && (
              <button
                onClick={() => {
                  // setSearch("");
                  // setSearched(false);
                  setFiltered(false);
                  setFilterString(`page=1`);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <button
            type="button"
            className="btn btn-primary ml ms-2"
            onClick={() => {
              setCreate(true);
            }}
          >
            Filter
          </button>
        </div>
        {isLoading || isFetchingAssesments ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : result.length > 0 ? (
          <SubmissionsTable
            resources={courseModules?.data?.results}
            metadata={courseModules?.data?.metadata}
            setFilter={setFilterString}
            setSkip={setSkip}
            filtering={isLoading || isFetchingAssesments}
          />
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon  text-primary">
              <BagIcon />
              <h4 className="my-2">No data available</h4>
            </div>
          </div>
        )}

        <Modal className="modal fade" show={createResource}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Filter</h5>
              <Button
                variant=""
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setCreate(false)}
              >
                <span>×</span>
              </Button>
            </div>

            <div className="modal-body">
              <form className="comment-form">
                <div className="row">
                  {loadingCourses ? (
                    <p>Loading Courses.....</p>
                  ) : (
                    Categories && (
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label> Course</label>
                          <Select
                            value={selectedCategory}
                            onChange={(_) => {
                              setCategory(_);
                            }}
                            options={Categories}
                            styles={Style}
                          />
                        </div>
                      </div>
                    )
                  )}
                  {loadingModules || isFetching ? (
                    <p>Loading Modules.....</p>
                  ) : (
                    modules && (
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label>Modules</label>
                          <Select
                            value={selectedModule}
                            onChange={(_) => {
                              setModule(_);
                            }}
                            options={[
                              { value: null, label: "Select Module" },
                              ...Modules,
                            ]}
                            styles={Style}
                          />
                        </div>
                      </div>
                    )
                  )}
                  {loadingCohorts ? (
                    <p>Loading Cohorts.....</p>
                  ) : (
                    Cohorts && (
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label>Cohorts</label>
                          <Select
                            value={selectedCohort}
                            onChange={(_) => {
                              setCohort(_);
                            }}
                            options={Cohorts}
                            styles={Style}
                          />
                        </div>
                      </div>
                    )
                  )}
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label>Status</label>
                      <Select
                        components={{ ClearIndicator }}
                        value={selectedStatus}
                        onChange={setStatus}
                        options={AssesmentStatus}
                        styles={Style}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-end">
                    <div className="form-group mb-3">
                      <button
                        // disabled={!selectedCategory || !selectedModule}
                        type="button"
                        className="submit btn btn-pigment-green"
                        onClick={() => {
                          if (
                            !selectedStatus &&
                            !selectedCohort &&
                            !selectedModule
                          ) {
                            toast.warn("✔️ Please Select a Filter !", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                            return;
                          }
                          const newFilter = {
                            status: selectedStatus?.value ?? null,
                            module_id: selectedModule?.value ?? null,
                            cohort_number: selectedCohort?.value ?? null,
                          };

                          // console.log("newFilter", newFilter);

                          const _filterString = `page=1${
                            newFilter?.status?.length > 0
                              ? `&status=${newFilter?.status}`
                              : ""
                          }${
                            newFilter?.module_id?.length > 0
                              ? `&module_id=${newFilter?.module_id}`
                              : ""
                          }${
                            newFilter?.cohort_number?.length > 0
                              ? `&cohort_number=${newFilter?.cohort_number}`
                              : ""
                          }`;
                          // console.log("filterString:", _filterString);
                          setFilterString(_filterString);
                          setFiltered(true);
                          setCreate(false);
                        }}
                      >
                        {isLoading ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2"
                          ></span>
                        ) : (
                          `Fetch Submissions`
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </section>
    )
  );
};
export default Submissions;
