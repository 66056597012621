/** @format */

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";
import {
  useGetAllPermissionsQuery,
  useCreateAdminRoleMutation,
} from "store/services/adminAPI";

export const CreateRoleModal = ({ showModal, setCreate }) => {
  const [createAdminRole, { isLoading }] = useCreateAdminRoleMutation();
  const [selectedPermissions, setPermissions] = useState([]);

  const { data: permissions } = useGetAllPermissionsQuery();

  const handleSubmit = async (values, { resetForm }) => {
    if (selectedPermissions.length > 0) {
      await createAdminRole({
        ...values,
        permissions: [...selectedPermissions],
      })
        .unwrap()
        .then((data) => {
          toast.success(`✔️ ${data?.message} !`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
          });

          resetForm();
          setCreate(false);
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error)
          Swal.fire({
            title:
              replaceUnderscoresWithSpaces(error?.data?.code) ||
              "Error Occured",
            text: formattedError,
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        });
    } else {
      toast.error("❌ Please select at least one permission for this role!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const PermissionOptions = permissions?.data?.permissions?.map((role) => ({
    value: role.id,
    label: role.name,
    slug: role.slug,
  }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setCreate(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Role</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setCreate(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={formik.handleSubmit}>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Name</strong>
                    </label>

                    <input
                      className="w-100 form-control"
                      placeholder="Your answer"
                      id="name"
                      type="text"
                      name="name"
                      {...formik.getFieldProps("name")}
                      rows="3"
                    ></input>

                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Description</strong>
                    </label>

                    <textarea
                      className="w-100 form-control"
                      placeholder="Your answer"
                      id="description"
                      name="description"
                      {...formik.getFieldProps("description")}
                      rows="3"
                    ></textarea>

                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    {/* <p>
                      selectedPermissions::{" "}
                      {JSON.stringify(selectedPermissions)}
                    </p> */}
                    <label className="mb-1">
                      <strong>Permissions</strong>
                    </label>

                    {PermissionOptions &&
                      PermissionOptions?.filter(
                        (_) => _?.slug !== "dashboard"
                      )?.map((_permission) => {
                        return (
                          <div
                            key={_permission.id}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <input
                                type="checkbox"
                                checked={
                                  selectedPermissions.includes(
                                    _permission?.value
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  if (
                                    !selectedPermissions.includes(
                                      _permission.value
                                    )
                                  ) {
                                    setPermissions([
                                      ...selectedPermissions,
                                      _permission.value,
                                    ]);
                                  } else {
                                    setPermissions(
                                      selectedPermissions.filter(
                                        (item) => item !== _permission.value
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                            <p>{_permission?.label}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    type="submit"
                    className="ms-2 pull-right  py-3 btn-primary"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create Role`
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateRoleModal;

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Role is required"),
  description: Yup.string().required("Desciption is required"),
  // permissions: Yup.array()
  //   .of(Yup.string())
  //   .min(1, "At least one permission is required")
  //   .required("At least one permission is required"),
});
