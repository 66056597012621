/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { useCreateJobMutation } from "store/services/notification";
import axios_instance from "store/constant/axiosInstance";

const CreateContent = ({ createResource, setCreate }) => {
  const [createJob, { isLoading }] = useCreateJobMutation();
  // eslint-disable-next-line no-unused-vars
  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [type, setType] = useState("Jobs");

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitted(true);
    await createJob({
      ...values,
      status: true,
      type:type?.toLowerCase()
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ Resource Created!`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      id: "",
      link: "",
      image: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        if (file.size > 2 * 1024 * 1024) {
          toast.error("❌ File size exceeds the 2MB limit.!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          const formData = new FormData();
          file && formData.append("file", file);
          try {
            setUploading(true);
            const response = await axios_instance.post(
              "core/upload/file",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            toast.success(`✔️ Image uploaded!`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            formik.setFieldValue("image", response?.data?.data?.url);
            setUploading(false);
          } catch (e) {
            setUploading(false);
            toast.error("❌ Error occured uploading image!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        }
      } else {
        toast.error("❌ Only image files are allowed. !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  return (
    <Modal className="modal fade" show={createResource}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create New Job </h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Job Name</strong>
                    {/* {
                      JSON.stringify(formik.values)
                    } */}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Job Name"
                    {...formik.getFieldProps("name")}
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Job Description</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="description"
                    name="description"
                    {...formik.getFieldProps("description")}
                    rows="3"
                  ></textarea>

                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Job Type</strong>
                  </label>

                  <select
                    className="form-control bulk-select"
                    id="inputState"
                    defaultValue="option-1"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    {["Jobs", "Gigs"].map((_) => {
                      return <option value={_}>{_}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Job ID</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="id"
                    name="id"
                    placeholder="Job ID"
                    {...formik.getFieldProps("id")}
                  />

                  {formik.touched.id && formik.errors.id ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.id}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Job Link</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    placeholder="Job Link"
                    {...formik.getFieldProps("link")}
                  />

                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.link}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mb-1">
                <label htmlFor="formFile" className="form-label">
                  Upload Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".jpeg, .jpg, .png"
                  onChange={handleFileChange}
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                {uploading && (
                  <>
                    <span
                      role="status"
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ms-2"
                    ></span>
                    <p className="m-0 ml-2">Uploading Image....</p>
                  </>
                )}
              </div>

              <div className="col-lg-12 disabled">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Job Image</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="image"
                    name="image"
                    placeholder="Image Url"
                    {...formik.getFieldProps("image")}
                  />

                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.image}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create  Job`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateContent;

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Job Name is required"),
  description: Yup.string().required("Job Description is required"),
  id: Yup.string().required("Job ID is required"),
  link: Yup.string()
    .required("Job Link is required")
    .url("Invalid URL. Please enter a valid link."),
  image: Yup.string()
    .required(" Job Image Link is required")
    .url("Invalid URL. Please enter a valid link."),
});
