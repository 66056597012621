/** @format */

import React, {useState} from "react";

import { Link } from "react-router-dom";

import AllAnalytics from "views/admin/pages/Analytics/components/AllApplicants";
import FellowAnalytics from "views/admin/pages/Analytics/FellowAnalytics";





export default function CourseBlog() {

  // const [filter, setFilter] = useState("");

  const [activeToggle, setActiveToggle] = useState("allapplicants");

  return (
    <>
    <div className="mb-4">
    <div className="custom-tab-1">
    <ul className="nav nav-tabs">
        <li
          className="nav-item"
          onClick={() => setActiveToggle("allapplicants")}
        >
          <Link
            to="#allapplicants"
            data-toggle="tab"
            className={`nav-link text-pigment-green ${
              activeToggle === "allapplicants" ? "active show" : ""
            } text-sm`}
          >
            All Applicants Analytics
          </Link>
          
        </li>
        <li
          className="nav-item"
          onClick={() => setActiveToggle("fellows")}
        >
          <Link
            to="#fellows"
            data-toggle="tab"
            className={`nav-link text-pigment-green ${
              activeToggle === "fellows" ? "active show" : ""
            } text-sm`}
          >
            Fellows Analytics
          </Link>
        </li>
      </ul>
      </div>
      </div>
      {
  activeToggle === "allapplicants" && (
      <AllAnalytics/>
  )
}
{
  activeToggle === "fellows" && (
    <>
      <FellowAnalytics />
    </>
  )
}

    </>
  );
}
