/** @format */

import { createSlice } from "@reduxjs/toolkit";
// import { getAllUsers } from "services/adminService";

const initialState = {
  fistUser: {
    name: "ola",
    rt: 23,
  },
  activeRoom: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setFirstUser: (state, { payload }) => {
      state.fistUser = payload;
    },
    setActiveRoom: (state, { payload }) => {
      state.activeRoom = payload;
    },
  },
});

export const { setFirstUser, setActiveRoom } = userSlice.actions;
export const userSelector = (state) => state.user;
export default userSlice.reducer;

export const fetchSingleUser = () => async (dispatch, getState) => {
  // const userState = getState().user;
  // 'userState', userState)
  // dispatch(setFetchingAllUsers(true));
  // try {
  //   const Response = await getAllUsers(page, size);
  //   const _pagination = Response.data.data.pagination;
  //   await dispatch(setAllUsers(Response.data.data.users));
  //   await dispatch(
  //     setAllUsersMeta({
  //       ...userState.allUsersMeta,
  //       currentPage: _pagination.currentPage,
  //       total: _pagination.total,
  //       totalPages: _pagination.totalPages,
  //     })
  //   );
  // } catch (err) {
  //   notification.error({
  //     message: "Error",
  //     description: "Error occured",
  //   });
  // }
  // dispatch(setFetchingAllUsers(false));
};
