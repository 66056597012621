import React, { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { useGetCohortsQuery, useGetProviderBranchesQuery, useMatchProviderBranchToCourseMutation } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import { formatDateWithoutTime } from "utils/date";
import EmptyNotice from "component/ErrorNotice";
import { useGetAllCoursesQuery } from "store/services/notification";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { extractErrorMessage } from "utils/strings";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const BranchesModal = ({ hideBranches, showBranches, providerId }) => {
  const { data, isLoading, error } = useGetProviderBranchesQuery({ id: providerId }, { skip: !showBranches });
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [matchMode, setMatchMode] = useState(false);

  const {data:cohorts} = useGetCohortsQuery();
  const {data:courses} = useGetAllCoursesQuery();
  const [matchBranch, {isLoading:isMatching}] = useMatchProviderBranchToCourseMutation()

  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const res = await matchBranch({
        branch_id: values.branch_id,
        cohort_id: values.cohort_id,
        course_id: values.course_id
      }).unwrap();
      toast.success(res?.data?.message);
      resetForm();
      setMatchMode(false);
    } catch (error) {
      const formattedErrorMessage = extractErrorMessage(error);
      toast.error(formattedErrorMessage);
    } finally {
      setSubmitting(false);
    }
  };


  const CohortOptions = cohorts?.data?.cohorts?.map((cohort) => ({
    value: cohort?.id,
    label: `Cohort ${cohort?.rank}`,
  })) || [];

  const CourseOptions = courses?.data?.courses?.map((course) => ({
    value: course?.id,
    label: course?.title
  })) || [];

  return (
    <Modal show={showBranches} onHide={hideBranches} className="modal fade" id="branchesModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Provider Branches</h5>
          <Button variant="" type="button" className="close" data-dismiss="modal" onClick={hideBranches}>
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : error ? (
            <div className="text-danger">Error occurred while fetching branches.</div>
          ) : (
            <>
              {data?.data?.results.length > 0 ? (
                <div className="accordion" id="branchesAccordion">
                  {data.data.results.map((branch) => (
                    <div className="accordion-item" key={branch.id}>
                      <h2 className={`accordion-header ${activeAccordion === branch.id ? "" : "collapsed"}`} id={branch.id}>
                        <button
                          className="accordion-button"
                          data-bs-toggle="collapse"
                          type="button"
                          onClick={() => toggleAccordion(branch.id)}
                        >
                          {branch.branch_name}
                        </button>
                      </h2>
                      <div
                        id={`collapse-${branch.id}`}
                        className={`accordion-collapse collapse ${activeAccordion === branch.id ? "show" : ""}`}
                        aria-labelledby={`heading-${branch.id}`}
                      >
                        <div className="accordion-body m-auto">
                          {matchMode ? (
                            <Formik
                              initialValues={{ branch_id: branch.id, course_id: '', cohort_id: '' }}
                              validationSchema={validationSchema}
                              onSubmit={handleSubmit}
                            >
                              {({ values, setFieldValue }) => (
                                <Form>
                                  <h5>Match {branch.branch_name} branch to course</h5>
                                  <Col md={12}>
                                    <p className="p-1 m-0">Set Course</p>
                                    <div className="form-group mb-3">
                                      <Select
                                        value={CourseOptions.find(option => option.value === values.course_id)}
                                        onChange={(option) => setFieldValue('course_id', option.value)}
                                        options={CourseOptions}
                                        styles={Style}
                                      />
                                      <ErrorMessage name="course_id" component="div" className="text-danger" />
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <p className="p-1 m-0">Set Cohort</p>
                                    <div className="form-group mb-3">
                                      <Select
                                        value={CohortOptions.find(option => option.value === values.cohort_id)}
                                        onChange={(option) => setFieldValue('cohort_id', option.value)}
                                        options={CohortOptions}
                                        styles={Style}
                                      />
                                      <ErrorMessage name="cohort_id" component="div" className="text-danger" />
                                    </div>
                                  </Col>
                                  <div className="mt-3 mb-4 d-flex justify-content-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={isMatching}
                                    >
                                      {isMatching ? (
                                        <span
                                          role="status"
                                          aria-hidden="true"
                                          className="spinner-border spinner-border-sm me-2"
                                        ></span>
                                      ) : null}
                                      {isMatching ? 'Submitting...' : 'Submit'}
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          ) : (
                            <div>
                              <p><strong>Branch Code:</strong> {branch.branch_code}</p>
                              <p><strong>Phone Number:</strong> {branch.phone_number}</p>
                              <p><strong>State:</strong> {branch.state}</p>
                              <p><strong>Address:</strong> {branch.address}</p>
                              <p><strong>LGA:</strong> {branch.lga}</p>
                              <p><strong>Status:</strong> {branch.status}</p>
                              <p><strong>Email:</strong> {branch.email}</p>
                              <p><strong>Staff Count:</strong> {branch.staff_count}</p>
                              <p><strong>Physical Infrastructure Description:</strong> {branch.physical_infrastructure_description}</p>
                              <p><strong>Experience in Implementing Projects in More Than One State:</strong> {branch.experience_impl_projects_more_than_one_state}</p>
                              <p><strong>Experience in Training PWDs in the State:</strong> {branch.experience_training_plwds_in_state}</p>
                              <p><strong>Faculty Relevance Experience:</strong> {branch.faculty_relevance_experience}</p>
                              <p><strong>Created At:</strong> {formatDateWithoutTime(branch.created_at)}</p>
                            </div>
                          )}
                          <div className="mt-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setMatchMode(!matchMode)}
                            >
                              {matchMode ? "Branch Details" : "Match To Course"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <EmptyNotice text="No branches available for this provider" />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BranchesModal;


const validationSchema = Yup.object().shape({
  course_id: Yup.string().required('Course is required'),
  cohort_id: Yup.string().required('Cohort is required'),
});