import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ResidenceAttendanceChart = ({ state_attendance_data }) => {
  const residences = state_attendance_data.map(item => item.residence);
  const presentPercentages = state_attendance_data.map(item => item.present_percentage);
  const absentPercentages = state_attendance_data.map(item => item.absent_percentage);

  const options = {
    series: [{
      name: 'Present',
      data: presentPercentages
    }, {
      name: 'Absent',
      data: absentPercentages
    }],
    chart: {
      type: 'bar',
      height: state_attendance_data.length * 70 + 100,
      stacked: true,
      stackType: '100%'
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },

    xaxis: {
      categories: residences,
      labels: {
        formatter: function (val) {
          return val + "%"
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%"
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'right',
      offsetX: 40
    },
    colors: ['#A6D693', '#B0B0B0'],

  };

  return (
    <div>
      {state_attendance_data?.length > 0 ? (
        <div id="chart">
          <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={options.chart.height}
          />
        </div>
      ) : (
        <p>No data available for residence attendance</p>
      )}
    </div>
  );
};

export default ResidenceAttendanceChart;