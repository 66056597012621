/** @format */

import React, { useState, useEffect } from "react";
import { useGetAllRolesQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import RolesTable from "views/admin/table/Roles/RolesTable";

const RolesPage = () => {
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState("page=1");

  const {
    data,
    isLoading: fetchingRoles,
    isFetching,
  } = useGetAllRolesQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <section className="mt-5">
      {fetchingRoles ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <RolesTable
          // users={data?.data?.roles.filter(
          //   (_) => _.slug !== "software_developer"
          // )}
          users={data?.data?.roles}
          metadata={data?.data?.metadata}
          setFilter={setFilter}
          setSkip={setSkip}
          filtering={fetchingRoles || isFetching}
          filter={filter}
        />
      )}
    </section>
  );
};
export default RolesPage;

// {
// "id": "9ad34878-bfbe-4107-9532-c68d9b59aa9b",
//  "email": "developer1@fmcide.gov.ng",
//   "code": "XVSLSL5LM8",
//   "expires_at": "2024-03-11T13:58:30.472958Z",
//    "sent_by": {
//     "first_name": "Test",
//      "last_name": "Test",
//      "email": "test@admin.com",
//       "is_active": true,
//        "is_admin": true,
//         "account_id": null,
//         "account_type": "ADMIN"}}
