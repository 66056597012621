/** @format */

import { Dropdown, Modal, Button } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import {
  capitalize,
  truncateString,
  replaceUnderscoresWithSpaces,
  extractErrorMessage,
} from "utils/strings";
import {
  useUpdateAdminUserMutation,
  useResendInviteMutation,
} from "store/services/adminAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useGetAllRolesQuery } from "store/services/adminAPI";
import { format } from "date-fns";

export const COLUMNS = (selectedIds, handleRowSelection) => [
  // {
  //   Header: "",
  //   accessor: "id",
  //   disableSortBy: true,
  //   Cell: ({ row }) => {

  //     return (
  //       <input
  //         type="checkbox"
  //         checked={selectedIds.includes(row?.original?.id) ? true :false}
  //         onChange={() => handleRowSelection(row?.original?.id)}
  //       />
  //     );
  //   },
  // },
  {
    Header: "Email",
    accessor: "name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.email, 15))} `;
    },
  },
  {
    Header: "Name",
    accessor: "first_name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${cell?.row?.original?.first_name} ${cell?.row?.original?.last_name} `;
    },
  },

  {
    Header: "Status",
    accessor: "is_active:",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return (
        <p
          className={
            cell?.row?.original?.is_active ? "text-primary" : "text-danger"
          }
        >
          {cell?.row?.original?.is_active ? `Active` : `InActive`}
        </p>
      );
    },
  },

  {
    Header: "Last Login",
    accessor: "last_login",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return cell?.row?.original?.last_login
        ? `${format(
            new Date(cell?.row?.original?.last_login),
            "dd/MM/yyyy HH:mm"
          )}`
        : "Null";
    },
  },

  {
    Header: "Role",
    accessor: "role",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.role?.name)}`;
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const [showProfile, setShowProfile] = useState(false);
      const [showEnable, setEnable] = useState(false);
      const [selectedRole, setRole] = useState();
      const [selectedStatus, setStatus] = useState(null);
      const { data } = useGetAllRolesQuery();
      const [updateAdminUser, { isLoading: updatingUser }] =
        useUpdateAdminUserMutation();

      const RoleOptions = data?.data?.roles
        ?.filter((_) =>  _.slug !==  "software_developer" )
        ?.map((role) => ({
          value: role.id,
          label: role.name,
        }));

      const statusOptions = [
        {
          value: true,
          label: "Active",
        },
        {
          value: false,
          label: "InActive",
        },
      ];

      const handleSubmit = async (source) => {
        const payload =
          source === "update_status"
            ? {
                userId: cell?.row?.original?.id,
                active: selectedStatus?.value,
                source: "update_status",
              }
            : {
                userId: cell?.row?.original?.id,
                role: selectedRole?.value,
                source: "update_role",
              };
        await updateAdminUser(payload)
          .unwrap()
          .then((data) => {
            toast.success(`✔️ ${data?.message} !`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setShowProfile(false);
            setEnable(false);
          })
          .catch((error) => {
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) ||
                "Error Occured",
              text: error?.data?.detail,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      };

      const _default = cell?.row?.original?.role?.id
        ? RoleOptions?.find(
            (item) => item.value === cell?.row?.original?.role?.id
          )
        : null;

      const _defaultStatus = statusOptions?.find(
        (item) => item.value === cell?.row?.original?.is_active
      );

      return (
        <>
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update User</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form>
                  <Col md={12}>
                    <p className="p-1 m-0">Role</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedRole}
                        onChange={setRole}
                        options={RoleOptions}
                        styles={Style}
                        defaultValue={_default}
                      />
                    </div>
                  </Col>

                  <div className="row">
                    <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                      <button
                        type="button"
                        className="btn btn-outline ms-2 "
                        onClick={() => setShowProfile(false)}
                      >
                        Discard
                      </button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handleSubmit("update_role")}
                      >
                        {updatingUser ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2"
                          ></span>
                        ) : (
                          `Update`
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          <Modal
            show={showEnable}
            onHide={() => setEnable(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Provider Details</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form>
                  <Col md={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="">User Status</h6>
                      <h6
                        className={
                          cell?.row?.original?.is_active
                            ? "text-primary"
                            : "text-danger"
                        }
                      >
                        {cell?.row?.original?.is_active ? "Active" : "Inactive"}{" "}
                      </h6>
                    </div>
                  </Col>
                  <Col md={12}>
                    <p className="p-1 m-0">Update Status</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedStatus}
                        onChange={setStatus}
                        options={statusOptions}
                        styles={Style}
                        defaultValue={_defaultStatus}
                      />
                    </div>
                  </Col>

                  <div className="row">
                    <div className="d-flex w-100 justify-content-center align-items-center gap-1">
                      <button
                        type="button"
                        className="btn btn-outline ms-2 "
                        onClick={() => setEnable(false)}
                      >
                        Discard
                      </button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handleSubmit("update_status")}
                      >
                        {updatingUser ? (
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2"
                          ></span>
                        ) : (
                          `Update`
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                Update Role
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setEnable(true)}>
                Disable\Enable User
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];

export const PENDING_COLUMNS = [
  {
    Header: "Code",
    accessor: "code",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.code))}`;
    },
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(truncateString(cell?.row?.original?.email))}`;
    },
  },

  {
    Header: "Sender",
    accessor: "sent_by",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      // return `${capitalize(truncateString(cell?.row?.original?.sent_by?.first_name))} ${capitalize(truncateString(cell?.row?.original?.sent_by?.last_name))}`;
      return `${capitalize(truncateString(cell?.row?.original?.sent_by?.email))}`;
    },
  },
  
  {
    Header: "Expires At",
    accessor: "expires_at",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return cell?.row?.original?.expires_at
        ? `${format(
            new Date(cell?.row?.original?.expires_at),
            "dd/MM/yyyy HH:mm"
          )}`
        : "Null";
    },
  },


  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const [resendInvite, { isLoading: resending }] =
        useResendInviteMutation();

      return (
        <>
          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              {resending ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item
                onClick={async () =>
                  Swal.fire({
                    title: "Notice",
                    iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                    text: "Are you sure you want to resend this invite?",
                    showCloseButton: true,
                    customClass: {
                      icon: "no-border",
                    },
                    cancelButtonText: "Delete",
                    // showCancelButton: true,
                    confirmButtonText: "Resend",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await resendInvite({
                        email: cell?.row?.original?.email,
                        accept_invitation_page:
                          "https://data.3mtt.training/accept_invitation_page?invitation_code=[invitation_code]",
                      })
                        .unwrap()
                        .then((data) => {
                          toast.success(`✔️ ${data?.message} !`, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                          });
                        })
                        .catch((error) => {
                          const formattedError = extractErrorMessage(error)
                          Swal.fire({
                            title:
                              replaceUnderscoresWithSpaces(error?.data?.code) ||
                              "Error Occured",
                            text: formattedError,
                            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                            showCloseButton: true,
                            customClass: {
                              icon: "no-border",
                            },
                            confirmButtonText: "Try Again",
                          });
                        });
                    }
                  })
                }
              >
                Resend Invite
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];
