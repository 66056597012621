/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { capitalize, truncateString } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import { extractFilterValues } from "utils/functions";
import { FadeLoader } from "react-spinners";
// import useFeatureCheck from "hooks/useFeatureCheck";
// import { Checks, skills, levels } from "utils/constant.data";
import { Dropdown, Modal, Button } from "react-bootstrap";

const SubmissionsTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  filtering,
}) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);

  // const [allowContentDelete] = useFeatureCheck(
  //   Checks.can_delete_online_learning_content
  // );

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `page=${_page}${
      values?.course_ids?.length > 0
        ? `&course_id=${values?.course_ids?.join(",")}`
        : ""
    }${
      values?.cohort_ids?.length > 0
        ? `&cohort_id=${values?.cohort_ids?.join(",")}`
        : ""
    }`;

    setFiltered(true);
    setFilter(filterString);
    // setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Assement Name",
      accessor: "id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.assessment?.title)}`;
      },
    },

    {
      Header: "Fellow Name",
      accessor: "fellow",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return truncateString(
          `${capitalize(cell?.row?.original?.fellow?.first_name)} ${capitalize(
            cell?.row?.original?.fellow?.last_name
          )}`
        );
      },
    },

    {
      Header: "Fellow ID",
      accessor: "assessment_id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.fellow?.fellow_id)}`;
      },
    },

    {
      Header: "Course",
      accessor: "start_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original.assessment?.course?.title)}`;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original.status)}`;
      },
    },
    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const { reason } = cell?.row?.original;
        const [show, setShow] = useState(true);

        <Modal className="modal fade" show={show}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ALC's Comment</h5>
              <Button
                variant=""
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setShow()}
              >
                <span>×</span>
              </Button>
            </div>
            <div className="modal-body">
              <p>{capitalize(reason ?? "")}</p>
            </div>
          </div>
        </Modal>;
        return (
          <>
            <Dropdown className="dropdown ms-auto text-right">
              <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                {cell?.row?.original.status?.toLowerCase() ===
                  "disapproved" && (
                  <Dropdown.Item onClick={() => setShow(true)}>
                    View Reason
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() =>
                    window.open(cell?.row?.original?.assessment?.link, "_blank")
                  }
                >
                  View Assesment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <div className={`card ${filtering ? "disabled" : ""}`}>
      <div className={`card-header ${filtering ? "disabled" : ""}`}>
        <div className="d-flex  align-items-center">
          <h4 className="card-title text-pigment-green">All Submissions</h4>

          {(filtered || searched) && (
            <button
              onClick={() => {
                setSearch("");
                setSearched(false);
                setFiltered(false);
                setClear(!clear);
                setFilter(`page=1`);
                setModalFilter(null);
              }}
              className="btn text-danger"
            >
              Reset Filter
            </button>
          )}
        </div>

        <div className="d-flex  align-items-center">
          <input
            className="ml-2 input-search form-control w-100"
            value={search}
            onChange={handleInputChange}
            placeholder="Search by name"
          />

          <button
            type="button"
            className="btn btn-outline ms-2 "
            onClick={() => {
              if (search?.length > 0) {
                setClear(!clear);
                setFilter(`page=1&search=${search}`);
                setFiltered(false);
                setSearched(true);
              }
            }}
          >
            Search
          </button>
        </div>
      </div>
      {filtering ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <div className="card-body">
          {data?.length > 0 ? (
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                  <button
                    className={`btn btn-outline ${hasPrev ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page - 1) || 1;
                      setFilter(`page=${_newPage}`);
                    }}
                  >
                    Previous
                  </button>

                  {metadata?.count && (
                    <div className="d-flex">
                      <p className="mb-0">
                        Page <strong>{metadata?.page}</strong> of{" "}
                        <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                        &nbsp;
                      </p>
                      <p className="mb-0">
                        Total <strong>{metadata?.count}</strong>
                      </p>
                    </div>
                  )}

                  <button
                    className={`btn btn-outline ${hasNext ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page || 0) + 1;
                      setFilter(`page=${_newPage}`);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon  text-primary">
                <BagIcon />
                <h4 className="my-2">No data for the specified filter</h4>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmissionsTable;
