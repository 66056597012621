import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { extractErrorMessage } from "utils/strings";
import axios_instance from "store/constant/axiosInstance";
import { useGetCohortsQuery } from "store/services/adminAPI";

export const ImportModal = ({ showModal, setShowImportModal }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [newCohort, setNewCohort] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: cohortsData } = useGetCohortsQuery("");

  const CohortOptions =
    cohortsData?.data?.cohorts?.length > 0
      ? cohortsData?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
    } else {
      toast.error("❌ Only CSV files are allowed!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setSelectedFile(null);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile || !newCohort) {
      toast.error("❌ Please select both a file and a cohort.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("cohort_id", newCohort.value);

      await axios_instance.post("admin/bulk/match/course/to/provider/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("✔️ Processing complete!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setLoading(false);
      setShowImportModal(false);
    } catch (error) {
      const formattedError = extractErrorMessage(error);
      toast.error(`❌ ${formattedError}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={() => setShowImportModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Match Courses to Provider Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col md={12}>
          <div className="form-group mb-3">
            <label htmlFor="bulkFellowsFile" className="form-label">
              Upload CSV File
            </label>
            <input
              className="form-control"
              type="file"
              id="bulkFellowsFile"
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
        </Col>
        <Col md={12}>
          <p className="p-1 m-0">Select Cohort</p>
          <div className="form-group mb-3">
            <Select
              value={newCohort}
              onChange={setNewCohort}
              options={CohortOptions}
              styles={Style}
            />
          </div>
        </Col>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? "Processing..." : "Submit"}
        </Button>
      </Modal.Body>
       
    </Modal>
  );
};

export default ImportModal;