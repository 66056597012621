/** @format */

import React, { useState, useEffect } from "react";
import AdminLayout from "views/admin/Admin";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import { useGetAllCoursesQuery } from "store/services/notification";
import CreateCourse from "./components/CreateCourse";
import CoursesTable from "./table/CoursesTable";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

const AllCourses = () => {
  usePermissionCheck(Page.COURSES);
  const [skip, setSkip] = useState(false);
  const [createResource, setCreate] = useState(false);
  const [filter, setFilter] = useState(`page=1`);
  const [allowCourseAdd] = useFeatureCheck(Checks.can_add_course);

  const {
    data,
    isLoading: fetchingResources,
    isFetching,
  } = useGetAllCoursesQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <AdminLayout>
      <div className="row">
        <section className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-end mb-4">
            {allowCourseAdd && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setCreate(true)}
              >
                Add course
              </button>
            )}
          </div>
          {fetchingResources ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : data?.data?.courses ? (
            <section className="d-flex flex-column">
              <CoursesTable
                resources={data?.data?.courses}
                // metadata={data?.data?.metadata}
                metadata={null}
                setFilter={setFilter}
                setSkip={setSkip}
                filtering={fetchingResources || isFetching}
              />
            </section>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon">
                <BagIcon />
                <h4 className="my-2">No course yet</h4>
                <p></p>
              </div>
            </div>
          )}
          <CreateCourse createResource={createResource} setCreate={setCreate} />
        </section>
      </div>
    </AdminLayout>
  );
};
export default AllCourses;
