/** @format */

import React, { useState } from "react";
import { formatDateWithoutTime } from "utils/date";
import {   capitalize, mapStatusClass, truncateString,mapStatusHeading, replaceSpace } from "utils/strings";
import { Dropdown } from "react-bootstrap";
import { AnalysisModal } from "./AnalysisModal";




export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "Title",
    accessor: "title",
    disableFilters: true,
    sortable: false,

  },
  {
    Header: "File URL",
    accessor: "file_url",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer" className="text-primary text-bold">
          {truncateString(value)}
        </a>
      );
    },
  },

  {
    Header: "Uploaded By",
    accessor: "uploaded_by.email",
    disableFilters: true,
    sortable: false,
  },

  {
    Header: "Uploaded At",
    accessor: "created_at",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return formatDateWithoutTime(value)
    },

  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      let _class = mapStatusClass(replaceSpace(value));
      return (
        <span className={`badge light badge-${_class}`}>
          <i className={`fa fa-circle text-${_class} me-1`} />
          {capitalize(mapStatusHeading(value))}
        </span>
      );
    },
  },
  {
    Header: "Actions",
    accessor: "",
    disableFilters: true,
    sortable: false,
    Cell: ({ row }) => {
      const [showAnalysisModal, setShowAnalysisModal] = useState(false)
      
      return(
        <>
        <AnalysisModal 
          showAnalysisModal = {showAnalysisModal}
          setShowAnalysisModal = {setShowAnalysisModal}
          data = {row.original}
        />
        <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setShowAnalysisModal(true)}>
                Show Analysis
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </>
      )
    },
  },
];
