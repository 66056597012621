import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const FellowProficiencyLevelChart = ({ proficiency_level_data }) => {
  const filteredData = proficiency_level_data.filter(item => item.name !== null);

  const series = filteredData.map(item => item.count);
  const labels = filteredData.map(item => item.name);

  const [chartData] = useState({
    series: series,
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true,
          tools: {
            selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          },
          export: {
            csv: {
              filename: "all_applicants_by_proficiency_data"
            },
            png: {
              filename: "all_applicants_by_proficiency",
              width: 1366,
              height: 720,
              scale: 8
            },
            svg: {
              filename: "all_applicants_by_proficiency",
              width: 1366,
              height: 720,
              scale: 8
            }
          }
        }
      },
      labels: labels,
      colors: ['#50B172', '#26A65B', '#66BB6A', '#AECF34', '#388E3C'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  });

  return (
    <div>
    {
      filteredData.length > 0 ? (
        <div className="pie-chart">
        <Chart options={chartData.options} series={chartData.series} type="pie" width={500} />
      </div>
  
      ): (
        <p>No data available under this category</p>
      )
    }
   
  </div>
  );
};

export default FellowProficiencyLevelChart;
