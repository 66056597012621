/** @format */

import { Path } from "utils/constant.data";
import Login from "views/auth/Login";
import AdminOnboardPage from "views/auth/Onboard";

import AdminHome from "views/admin/pages/dashboard";
import AdminProviders from "views/admin/pages/Providers";
import AdminCohorts from "views/admin/pages/Cohorts";
import AdminFellows from "views/admin/pages/Fellows";
import AdminOrganisations from "views/admin/pages/Organizations";
import AdminPartners from "views/admin/pages/Partners";
import AdminForum from "views/admin/pages/Forum";
import AdminChatRoom from "views/admin/pages/Forum/room";
import AdminWebinars from "views/admin/pages/Webinar";
import AdminFAQs from "views/admin/pages/FAQs";
import AdminLearningTracker from "views/admin/pages/LearningTracker";
import AdminRoles from "views/admin/pages/Roles";
import AdminBroadcast from "views/admin/pages/Broadcast";
import AdminNotifications from "views/admin/pages/Broadcast/notifications";
import AdminEmail from "views/admin/pages/Email";
import AdminCreateEmail from "views/admin/pages/Email/createEmail";
import AdminUsers from "views/admin/pages/Users";
import AdminCourseModules from "views/admin/pages/Course/CourseModules";

import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";

import ProviderAnalytics from "views/admin/pages/Analytics/ProviderAnalytics";
import AdminLearningContent from "views/admin/pages/Course/AdminLearningContent";
import AdminCommunityResources from "views/admin/pages/Course";
import AllCourses from "views/admin/pages/Course/AllCourses";
import AdminJobs from "views/admin/pages/Jobs";
import AdminSurveys from "views/admin/pages/Surveys";
import AdminAssesment from "views/admin/pages/Assesment";
import AdminCertificates from "views/admin/pages/Certificates";
import AdminMatching from "views/admin/pages/Matching";
import AdminMatchingReports from "views/admin/pages/Matching/Reports";
import AttendanceAnalytics from "views/admin/pages/Analytics/AttendanceAnalytics";
import AssessmentAnalytics from "views/admin/pages/Analytics/AssessmentAnalytics";

const PublicRoutes = [
  {
    path: Path.LOGIN,
    element: <Login />,
    title: Path.LOGIN,
  },
  {
    path: Path.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    title: Path.FORGOT_PASSWORD,
  },
  {
    path: Path.RESET_PASSWORD,
    element: <ResetPassword />,
    title: Path.RESET_PASSWORD,
  },
  {
    path: Path.ONBOARD_USER,
    element: <AdminOnboardPage />,
    title: Path.ONBOARD_USER,
  },
];

const AdminRoutes = [
  {
    path: Path.ADMIN_DASHBOARD,
    element: <AdminHome />,
    title: Path.ADMIN_DASHBOARD,
  },
  {
    path: Path.ADMIN_FELLOWS,
    element: <AdminFellows />,
    title: Path.ADMIN_FELLOWS,
  },
  {
    path: Path.ADMIN_PROVIDERS,
    element: <AdminProviders />,
    title: Path.ADMIN_PROVIDERS,
  },
  {
    path: Path.ADMIN_ORGANISATIONS,
    element: <AdminOrganisations />,
    title: Path.ADMIN_ORGANISATIONS,
  },
  {
    path: Path.ADMIN_PARTNERS,
    element: <AdminPartners />,
    title: Path.ADMIN_PARTNERS,
  },

  {
    path: Path.ADMIN_ROOMS,
    element: <AdminForum />,
    title: Path.ADMIN_ROOMS,
  },
  {
    path: Path.ADMIN_FORUM_ROOM,
    element: <AdminChatRoom />,
    title: Path.ADMIN_FORUM_ROOM,
  },
  {
    path: Path.ADMIN_WEBINAR,
    element: <AdminWebinars />,
    title: Path.ADMIN_WEBINAR,
  },
  {
    path: Path.ADMIN_NOTIFICATIONS,
    element: <AdminNotifications />,
    title: Path.ADMIN_NOTIFICATIONS,
  },
  {
    path: Path.ADMIN_COMMUNITY_RESOURCES,
    element: <AdminCommunityResources />,
    title: Path.ADMIN_COMMUNITY_RESOURCES,
  },
  {
    path: Path.ADMIN_LEARNING_CONTENT,
    element: <AdminLearningContent />,
    title: Path.ADMIN_LEARNING_CONTENT,
  },
  {
    path: Path.ADMIN_COURSE_MODULES,
    element: <AdminCourseModules />,
    title: Path.ADMIN_COURSE_MODULES,
  },
  {
    path: Path.ADMIN_BROADCAST,
    element: <AdminBroadcast />,
    title: Path.ADMIN_BROADCAST,
  },
  {
    path: Path.ADMIN_EMAILS,
    element: <AdminEmail />,
    title: Path.ADMIN_EMAILS,
  },
  {
    path: Path.ADMIN_EMAILS,
    element: <AdminEmail />,
    title: Path.ADMIN_EMAILS,
  },
  {
    path: Path.ADMIN_SEND_EMAIL,
    element: <AdminCreateEmail />,
    title: Path.ADMIN_SEND_EMAIL,
  },
  {
    path: Path.ADMIN_FAQS,
    element: <AdminFAQs />,
    title: Path.ADMIN_FAQS,
  },
  {
    path: Path.ADMIN_COHORTS,
    element: <AdminCohorts />,
    title: Path.ADMIN_COHORTS,
  },
  {
    path: Path.ADMIN_LEARNING_TRACKER,
    element: <AdminLearningTracker />,
    title: Path.ADMIN_LEARNING_TRACKER,
  },
  {
    path: Path.ADMIN_USERS,
    element: <AdminUsers />,
    title: Path.ADMIN_USERS,
  },
  {
    path: Path.ADMIN_ROLES,
    element: <AdminRoles />,
    title: Path.ADMIN_ROLES,
  },
  {
    path: Path.ADMIN_PROVIDER_ANALYTICS,
    element: <ProviderAnalytics />,
    title: Path.ADMIN_PROVIDER_ANALYTICS,
  },
  {
    path: Path.ADMIN_ATTENDANCE_ANALYTICS,
    element: <AttendanceAnalytics />,
    title: Path.ADMIN_ATTENDANCE_ANALYTICS,
  },
  {
    path: Path.ADMIN_ASSESSMENTS_ANALYTICS,
    element: <AssessmentAnalytics />,
    title: Path.ADMIN_ASSESSMENTS_ANALYTICS,
  },
  {
    path: Path.ADMIN_ALL_COURSES,
    element: <AllCourses />,
    title: Path.ADMIN_ALL_COURSES,
  },
  {
    path: Path.ADMIN_JOBS,
    element: <AdminJobs />,
    title: Path.ADMIN_JOBS,
  },
  {
    path: Path.ADMIN_SURVEYS,
    element: <AdminSurveys />,
    title: Path.ADMIN_SURVEYS,
  },
  {
    path: Path.ADMIN_ASSESMENTS,
    element: <AdminAssesment />,
    title: Path.ADMIN_ASSESMENTS,
  },
  {
    path: Path.ADMIN_CERTIFICATES,
    element: <AdminCertificates />,
    title: Path.ADMIN_CERTIFICATES,
  },
  {
    path: Path.ADMIN_MATCHING,
    element: <AdminMatching />,
    title: Path.ADMIN_MATCHING,
  },
  {
    path: Path.ADMIN_MATCHING_REPORTS,
    element: <AdminMatchingReports />,
    title: Path.ADMIN_MATCHING_REPORTS,
  },
];

export { PublicRoutes, AdminRoutes };
