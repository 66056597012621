/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Button } from "react-bootstrap";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import swal from "sweetalert";
import { StatusModal } from "./helpers/StatusModal";
import { useExportProvidersQuery } from "store/services/adminAPI";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

const ProvidersTable = ({
  providers,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState("");
  const [allowEdit] = useFeatureCheck(Checks.update_provider_status);

  const [allowExport] = useFeatureCheck(Checks.export_providers);
  const [exportFilter, setExportFilter] = useState(null);
  const [exportSkip, setExportSkip] = useState(true);
  const [_exporting, setExporting] = useState(false);
  const { data: exportData, isLoading: exporting } = useExportProvidersQuery(
    exportFilter,
    {
      skip: exportSkip,
    }
  );

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = providers.map((_F) => _F?.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
    setSelectAll(!selectAll);
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `page=${_page}${
      values?.status?.length > 0 ? `&status=${values?.status?.join(",")}` : ""
    }${
      values?.selectedStates?.length > 0
        ? `&state=${values?.selectedStates?.join(",")}`
        : ""
    }${
      values?.selectedLGAs?.length > 0
        ? `&lga=${values?.selectedLGAs?.join(",")}`
        : ""
    }${
      values?.selectedCourses?.length > 0
        ? `&courses=${values?.selectedCourses?.join(",")}`
        : ""
    }`;


    // state
    // status
    // lga
    // staff_count

    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    if (exportData) {
      if (exportData?.status) {
        setExportSkip(true);
        swal({
          title: "Success",
          text: exportData?.message,
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-block",
              closeModal: true,
            },
          },
          icon: "success",
        });
      } else {
        // swal({
        //   title: "Error",
        //   text: "Error occured exporting records",
        //   buttons: {
        //     confirm: {
        //       text: "Try Again",
        //       value: true,
        //       visible: true,
        //       className: "btn btn-danger btn-block",
        //       closeModal: true,
        //     },
        //   },
        //   icon: "error",
        // });
      }
      setExporting(false);
    }
  }, [exportData]);

  // eslint-disable-next-line no-unused-vars
  const handleFellowsExport = async () => {
    setExporting(true);
    if (modalFilter === null) {
      setExportSkip(false);
      setExportFilter("");
    } else {
      const values = extractFilterValues(modalFilter);

      let payload = `${
        values?.selectedStates?.length > 0
          ? `state=${values.selectedStates.join(",")}`
          : ""
      }${
        values?.status?.length > 0
          ? `&status=${values.status.join(",")}`
          : ""
      }${
        values?.selectedLGAs?.length > 0
          ? `&lga=${values.selectedLGAs.join(",")}`
          : ""
      }`;
      setExportFilter(payload);

    }
  };

  useEffect(() => {
    setData([...providers]);
    setSkip(true);
  }, [providers, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (exportFilter) {
      setExportSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportFilter]);

  const columns = useMemo(
    () => COLUMNS(selectedIds, handleRowSelection),
    [selectedIds, handleRowSelection]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreProviders = () => {
    setClear(!clear);
    setFiltered(false);
    setSearch("");
    setSearched(false);
    setFilter("page=1");
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreProviders={restoreProviders}
        clearFilter={clear}
      />

      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <div className="mr-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll()}
              />
            </div>

            <h4 className="card-title">Providers </h4>
            {selectedIds?.length > 0 && (
              <div className="ml-2">
                <select
                  className="form-control bulk-select"
                  id="action"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                >
                  <option value="">Choose Bulk Option</option>
                  {allowEdit && (
                    <option value="update_status">
                      Update Provider Status
                    </option>
                  )}
                </select>
              </div>
            )}
            {(filtered || searched) && (
              <button
                onClick={() => {
                  setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1`);
                  setModalFilter(null);
                  setExportFilter("");
                  setExportSkip(true);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by company name, Reg number and email "
            />

            <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button>

           

            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>

            {allowExport && (
              <Button
                disabled={exporting || _exporting}
                onClick={() => handleFellowsExport()}
                className="ms-2 pull-right btn-primary"
              >
                {exporting || _exporting ? (
                  <span
                    role="status"
                    aria-hidden="true"
                    className="spinner-border spinner-border-sm ms-2"
                  ></span>
                ) : (
                  "Export"
                )}
              </Button>
            )}
          </div>
        </div>

        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          if (modalFilter) {
                            handleAllFilter(modalFilter, _newPage);
                          } else {
                            setFilter(`page=${_newPage}`);
                          }
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          if (modalFilter) {
                            handleAllFilter(modalFilter, _newPage);
                          } else {
                            setFilter(`page=${_newPage}`);
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <StatusModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No providers {filtered ? "for the specified filter" : "yet"}
                  </h4>
                  <button
                    onClick={() => restoreProviders()}
                    className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ProvidersTable;
