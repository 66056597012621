/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { Path } from "utils/constant.data";

export const AdminRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user && userRole === "ADMIN" ? (
    <Outlet />
  ) : (
    <Navigate to={Path.LOGIN} replace />
  );
};

export const GuestRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user ? (
    <Navigate
      to={userRole === "ADMIN" ? Path.ADMIN_DASHBOARD : Path.LOGIN}
      replace
    />
  ) : (
    <Outlet />
  );
};
