/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import VerificationModal from "./VerificationModal";

import { useFormik } from "formik";

import axios_instance from "store/constant/axiosInstance";

export const ProgressModal = ({
    showProgressModal,
    handleCloseProgressModal,
    clearProgressModal,
}) => {
    const [loading, setLoading] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setSelectedFile(null);
    }, [clearProgressModal]);

    const clearForm = () => {
        formik.resetForm();
        setSelectedFile(null);
        setError(null)
    };

    const handleSubmit = async (values, { resetForm }) => {

        if (!selectedFile) {
            toast.error("❌ Please attach a file!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            return;
        }

        if (values.title && selectedFile) {
            setShowVerificationModal(true);
            handleCloseProgressModal();
        }
    };

    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedFileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
        ];

        if (file) {
            const fileType = file.type;
            if (allowedFileTypes.includes(fileType)) {
                setSelectedFile(file);
            } else if (file.size > 10 * 1024 * 1024) {
                toast.error("❌ File size exceeds the 2MB limit.!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setSelectedFile(null);
            } else {
                toast.error("❌ Only xls files are allowed. !", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setSelectedFile(null);
            }
        }
    };

    const handleVerificationModalClose =() => {
        setShowVerificationModal(false)
        clearForm()

    }

    const handleVerificationConfirmation = async (proceed) => {
        if (proceed) {
            try {
                setLoading(true);

                const formData = new FormData()

                selectedFile && formData.append("file", selectedFile);
                formData.append("title", formik.values.title);

                await axios_instance.post("admin/upload/learning/tracker", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                toast.success(`✔️ Progress updated successfully!`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
                setLoading(false);
                clearForm();
                setShowVerificationModal(false)

        
            } catch(error) {
                toast.error("❌ Error updating progress!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setLoading(false);
            setError(`❌ ${error.response.data.message}`);
            setTimeout(() => {
                setShowVerificationModal(false)
                clearForm();
            }, 5000);

            }
        }
    };

    return (
        <>
            <Modal
                show={showProgressModal}
                onHide={handleCloseProgressModal}
                className="modal fade"
                id="progressModal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Upload Progress Sheet</h5>
                        <Button
                            variant=""
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleCloseProgressModal}
                        >
                            <span>×</span>
                        </Button>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="basic-form">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group mb-3">
                                <label>Title</label>
                                <input
                                    type="text"
                                    className="form-control bg-transparent"
                                    placeholder="Enter Title"
                                    id="title"
                                    name="title"
                                    {...formik.getFieldProps("title")}
                                />
                                {formik.touched.title && formik.errors.title ? (
                                    <div className="text-danger mt-2 fs-12">
                                        {formik.errors.title}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="progressFile" className="form-label">
                                    Upload Progress Sheet (Excel or CSV)
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="progressFile"
                                    accept=".xls, .xlsx, .csv"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary btn-sl-sm " type="submit">
                                    {loading ? (
                                        <span
                                            role="status"
                                            aria-hidden="true"
                                            className="spinner-border spinner-border-sm ms-2 text-white"
                                        ></span>
                                    ) : (
                                        <>
                                            <span className="me-2">
                                                <i className="fa fa-upload"></i>
                                            </span>
                                            Upload
                                        </>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <VerificationModal
                show={showVerificationModal}
                handleClose={handleVerificationModalClose}
                handleConfirmation={handleVerificationConfirmation}
                loading={loading}
                error={error}
            />
        </>
    );
};

const FormSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
});
