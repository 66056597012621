/** @format */

import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { Style } from "utils/select";
import Select from "react-select";
import { useGetAllCoursesQuery } from "store/services/notification";
import DatePicker from "react-datepicker";
import { useGetCohortsQuery } from "store/services/adminAPI";
import {
  useGetSingleCourseModuleQuery,
  useCreateAssesmentMutation,
} from "store/services/notification";
import { formatDateToYYYYMMDD } from "utils/date";
import { extractErrorMessage } from "utils/strings";
import { weekNumbers } from "utils/constant.data";

const CreateAssignment = ({ createResource, setCreate }) => {
  const [createAssesment, { isLoading }] = useCreateAssesmentMutation();
  const { data, isLoading: loadingCourses } = useGetAllCoursesQuery();
  const { data: allCohorts, isLoading: loadingCohorts } = useGetCohortsQuery();
  const [skip, setSkip] = useState(true);
  const [selectedCategory, setCategory] = useState(null);
  const [selectedModule, setModule] = useState(null);
  const [selectedCohort, setCohort] = useState(null);
  const [selectedWeek, setWeek] = useState(null);

  const { data: modules, isLoading: loadingModules } =
    useGetSingleCourseModuleQuery(selectedCategory?.value, {
      skip: skip,
    });

  const handleSubmit = async (values, { resetForm }) => {
    await createAssesment({
      ...values,
      // week_number: selectedModule?.week_number,
      start_date: formatDateToYYYYMMDD(values.start_date),
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ Resource Created!`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text:
            extractErrorMessage(error?.data?.errors ?? []) ??
            error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      link: "",
      description: "",
      course_id: "",
      module_id: "",
      cohort_id: "",
      week_number: "",
      start_date: null,
      duration: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const Categories = data?.data?.courses
    ?.filter((_) => {
      return _?.status;
    })
    ?.map((role) => ({
      value: role?.id,
      label: role?.title,
      code: role?.code,
    }));

  const Cohorts = allCohorts?.data?.cohorts?.map((role) => ({
    value: role?.id,
    label: role?.name + " " + role?.rank,
  }));

  const Modules = modules?.data?.results
    ?.filter((_) => {
      return _?.status === "active";
    })
    ?.map((_) => {
      return {
        value: _?.id,
        label: _?.name,
        week_number: _?.week_number,
      };
    });

  useEffect(() => {
    if (selectedCategory?.value) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [selectedCategory?.value]);

  //   console.log("data?.data?.courses", data?.data?.courses);
  //   console.log("modules", modules?.data);

  return (
    <Modal className="modal fade" show={createResource}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Assesment </h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>

        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="mb-1">Title</label>

                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Title"
                    {...formik.getFieldProps("title")}
                  />

                  {formik.touched.title && formik.errors.title ? (
                    <div classtitle="text-danger mt-2 fs-12">
                      {formik.errors.title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label className="mb-1">Assesment Link</label>

                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    placeholder="Course Link"
                    {...formik.getFieldProps("link")}
                  />

                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.link}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">Assesment Description</label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="description"
                    name="description"
                    {...formik.getFieldProps("description")}
                    rows="3"
                  ></textarea>

                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>

              {loadingCourses ? (
                <p>Loading Courses.....</p>
              ) : (
                Categories && (
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label> Course</label>
                      <Select
                        value={selectedCategory}
                        onChange={(_) => {
                          setCategory(_);
                          formik.setFieldValue("course_id", _?.value);
                        }}
                        options={Categories}
                        styles={Style}
                      />

                      {formik.touched.course_id && formik.errors.course_id ? (
                        <div className="text-danger mt-2 fs-12">
                          {formik.errors.course_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}

              {loadingCohorts ? (
                <p>Loading Cohorts.....</p>
              ) : (
                Cohorts && (
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label>Cohorts</label>
                      <Select
                        value={selectedCohort}
                        onChange={(_) => {
                          setCohort(_);
                          formik.setFieldValue("cohort_id", _?.value);
                        }}
                        options={Cohorts}
                        styles={Style}
                      />

                      {formik.touched.cohort_id && formik.errors.cohort_id ? (
                        <div className="text-danger mt-2 fs-12">
                          {formik.errors.cohort_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}

              {formik.values.cohort_id && (
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Week NUmber</label>
                    <Select
                      value={selectedWeek}
                      onChange={(_) => {
                        setWeek(_);
                        formik.setFieldValue("week_number", _?.value);
                      }}
                      options={weekNumbers}
                      styles={Style}
                    />

                    {formik.touched.week_number && formik.errors.week_number ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.week_number}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              {loadingModules ? (
                <p>Loading Modules.....</p>
              ) : (
                modules && (
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label>Modules</label>
                      <Select
                        value={selectedModule}
                        onChange={(_) => {
                          setModule(_);
                          formik.setFieldValue("module_id", _?.value);
                        }}
                        options={Modules}
                        styles={Style}
                      />

                      {formik.touched.module_id && formik.errors.module_id ? (
                        <div className="text-danger mt-2 fs-12">
                          {formik.errors.module_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}

              {/* <p> Cohort:: {JSON.stringify(formik.values)}</p> */}
              {/* 
              <p> selectedModule:: {JSON.stringify(selectedModule)}</p>
              <p> selectedCohort:: {JSON.stringify(selectedCohort)}</p> */}
              {/* <p> selectedCategory:: {JSON.stringify(selectedCategory)}</p> */}

              <div className="col-lg-12">
                <div className="form-group mb-3 w-full w-100">
                  <label className="d-block">Start Date</label>
                  <DatePicker
                    selected={formik.values.start_date}
                    onChange={(start_date) =>
                      formik.setFieldValue("start_date", start_date)
                    }
                    className="form-control bg-transparent w-full w-100"
                  />
                  {formik.touched.start_date && formik.errors.start_date ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.start_date}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label>Duration (in days)</label>
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    placeholder="Duration"
                    id="duration"
                    name="duration"
                    {...formik.getFieldProps("duration")}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />

                  {formik.touched.duration && formik.errors.duration ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.duration}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAssignment;

const FormSchema = Yup.object().shape({
  course_id: Yup.string().required("Course is required"),
  module_id: Yup.string().required("Course Module is required"),
  cohort_id: Yup.string().required("Cohort is required"),

  title: Yup.string()
    .required("Assigment title is required")
    .min(10, "Assigment title should be at least 10 characters"),
  description: Yup.string().required("Assignment description is required"),
  link: Yup.string()
    .required("Assignment Link is required")
    .url("Invalid URL. Please enter a valid link."),
  duration: Yup.number()
    .required("Please set a duration for the Assignment")
    .positive("Duration must be a positive number"),
  start_date: Yup.string().required(
    "Please set a start date for the Assignment"
  ),
});
