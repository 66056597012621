/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { useEffect} from "react";
// import { Dropdown } from "react-bootstrap";
import AdminLayout from "../Admin";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import CourseBlog from "../components/Dashboard/Dashboard/CourseBlog";
import { getProviderProfile } from "store/actions/profile";

const AdminHome = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);

  useEffect(() => {
    getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getProfileInfo = () => {
    dispatch(getProviderProfile());
  };

  // const [dropSelect, setDropSelect] = useState("This Month");

  return (
    <AdminLayout>
      {profileState?.isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-12">
                <CourseBlog />
              </div>
            </div>
          </div>
          
        </div>
      )}
    </AdminLayout>
  );
};

export default AdminHome;
