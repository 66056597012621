/** @format */

import React, { useState, useEffect } from "react";
import AdminLayout from "views/admin/Admin";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import AdminWebinarsTable from "views/admin/table/Webinars/AdminWebinarsTable";
import CreateWebinar from "./components/CreateWebinar";
import { useGetAdminWebinarsQuery } from "store/services/adminAPI";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";

const AdminWebinars = () => {
  usePermissionCheck(Page.WEBINAR);
  const [skip, setSkip] = useState(false);
  const [create, setCreate] = useState(false);
  const [filter, setFilter] = useState(`page=1`);

  const { data, isLoading } = useGetAdminWebinarsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <AdminLayout>
      <div className="row">
        <section className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-end mb-4">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setCreate(true)}
            >
              Add Webinar
            </button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : data?.data?.results?.length > 0 ? (
            <section className="d-flex flex-column">
              <AdminWebinarsTable
                webinars={data?.data?.results}
                metadata={data?.data?.metadata}
                setFilter={setFilter}
                setSkip={setSkip}
              />
            </section>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon">
                <BagIcon />
                <h4 className="my-2">No webinar yet</h4>
                <p></p>
              </div>
            </div>
          )}
          <CreateWebinar create={create} setCreate={setCreate} />
        </section>
      </div>
    </AdminLayout>
  );
};
export default AdminWebinars;
