import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useUpdateUserEmailMutation } from "store/services/adminAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangeEmailModal = ({ hideEmailChangeModal, showEmailChangelModal, fellowId }) => {
  const [updateEmail, { isLoading }] = useUpdateUserEmailMutation();



  const handleSubmit = async (values, ) => {
      await updateEmail({
        account_id: fellowId,
        new_email: values.new_email,
        account_type: "FELLOW",
        redirect_url: "https://app.3mtt.training/reset-email"
        })
        .unwrap()
        .then(()=>{
            toast.success(`Email updated successfully!`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });
              hideEmailChangeModal();
        })
        .catch((error)=>{
            Swal.fire({
                title: "Error Occurred",
                text: error?.message || "Failed to update email",
                icon: "error",
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });

        })}

        const formik = useFormik({
            initialValues: {
              new_email: "",
            },
            validationSchema:ChangeEmailValidationSchema,
            onSubmit: handleSubmit
          });
          
  return (
    <Modal show={showEmailChangelModal} onHide={hideEmailChangeModal} className="modal fade" id="emailModal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Change Email</h5>
          <Button variant="" type="button" className="close" data-dismiss="modal" onClick={hideEmailChangeModal}>
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-3">
              <label>New Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="example@domain.com"
                {...formik.getFieldProps("new_email")}

              />
              {formik.touched.new_email && formik.errors.new_email && (
                <div className="text-danger mt-2 fs-12">{formik.errors.new_email}</div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span role="status" aria-hidden="true" className="spinner-border spinner-border-sm ms-2"></span>
                ) : (
                  "Update Email"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeEmailModal;

const ChangeEmailValidationSchema = Yup.object().shape({
    new_email: Yup.string()
      .email("Invalid email address")
      .required("New email is required"),
  });