/** @format */

import React from "react";





export const COLUMNS = (selectedIds,handleRowSelection) => [
  {
    Header: "ALC",
    accessor: "branch.provider.name",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
          <p>{value}</p>
      );
    },

  },
  {
    Header: "Branch",
    accessor: "branch.branch_name",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
          <p>{value}</p>
      );
    },

  },
  {
    Header: "State",
    accessor: "branch.state",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{value}</p>
    );
    },
  },
  {
    Header: "LGA",
    accessor: "branch.lga",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{value}</p>
    );
    },
  },
  {
    Header: "Cohort",
    accessor: "cohort.rank",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{`Cohort ${value}`}</p>
    );
    },
  },
  {
    Header: "Course",
    accessor: "course.title",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return (
        <p>{value}</p>
    );
    },
  },
];
