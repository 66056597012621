/** @format */

import { Suspense } from "react";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.scss";

import Page404 from "./views/general/404Page";
import { ToastContainer } from "react-toastify";
import { GuestRoute, AdminRoute } from "config/auth";
import { Path } from "utils/constant.data";
import { Route, Routes, Navigate } from "react-router-dom";
import { AdminRoutes, PublicRoutes } from "utils/routes";
import { getLocalStorage } from "utils/secure";
import { FeatureFlagsProvider } from "context/FeatureFlags";

function App() {
  // const user = JSON.parse(window.localStorage.getItem("3MTAUSER")) || null;

  const user = localStorage.getItem("3MTAUSER")
  ? getLocalStorage("3MTAUSER")
  : null;

  return (
    <FeatureFlagsProvider>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route element={<GuestRoute user={user} />}>
            {PublicRoutes.map((_data) => (
              <Route
                key={_data.title}
                exact
                path={_data.path}
                element={_data.element}
              />
            ))}
            <Route path="/" element={<Navigate replace to={Path.LOGIN} />} />
          </Route>
          <Route element={<AdminRoute user={user} />}>
            {AdminRoutes.map((data) => (
              <Route
                key={data.title}
                exact
                path={data.path}
                element={data.element}
              />
            ))}
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </FeatureFlagsProvider>
  );
}

export default App;
