/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { Path } from "utils/constant.data";
import { encrypt } from "utils/secure";

const initialState = {
  isAuth: false,
  currentUser: null,
  isLoading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsAuth, setCurrentUser, setLoading } = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;

export const setUser = (payload) => async (dispatch) => {
  dispatch(setCurrentUser(payload));
};

export const login = (payload) => async (dispatch) => {
  if (payload?.access_token && payload?.user_data?.account_type === "ADMIN") {
    await dispatch(setIsAuth(true));
    await dispatch(setCurrentUser(payload?.user_data));


    window.localStorage.setItem("3MTAAT", encrypt(payload?.access_token));
    window.localStorage.setItem(
      "3MTAUSER",
      encrypt(JSON.stringify(payload?.user_data))
    );

    window.open(
      payload?.user_data?.account_type === "ADMIN"
        ? Path.ADMIN_DASHBOARD
        : Path.LOGIN,
      "_self"
    );
  }
};

export const logOut = () => async (dispatch) => {
  window.localStorage.removeItem("3MTAAT");
  window.localStorage.removeItem("3MTAUSER");
  window.open(Path.LOGIN, "_self");
};
