import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import {
  Nigerian_States,
  LocalGov,
} from "utils/constant.data";
import { debounce } from "lodash";
import { useGetCohortsQuery, useGetProviderBranchesQuery } from "store/services/adminAPI";
import { useGetAllCoursesQuery } from "store/services/notification";
import axios_instance from "store/constant/axiosInstance";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  clearFilter,
}) => {
  const [selectedStates, setStates] = useState([]);
  const [selectedLGAs, setLGAs] = useState([]);
  const [selectedCourse, setCourses] = useState([]);
  const [selectedProvider, setProvider] = useState(null);
  const [selectedCohorts, setCohorts] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const { data } = useGetCohortsQuery("");
  const { data: courses } = useGetAllCoursesQuery();

  const { data: branches, isLoading: branchesLoading } = useGetProviderBranchesQuery(
    { id: selectedProvider?.value },
    { skip: !selectedProvider }
  );

  useEffect(() => {
    if (clearFilter) {
      resetAllFilters();
    }
  }, [clearFilter]);

  const resetAllFilters = () => {
    setStates([]);
    setLGAs([]);
    setCourses([]);
    setProvider(null);
    setCohorts(null);
    setSelectedBranch(null);
    
  };

  const handleProviderChange = (provider) => {
    setProvider(provider);
    setSelectedBranch(null);
  }

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    axios_instance
      .get(`admin/providers?status=Selected&provider_name=${inputValue}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.results;
        results?.length > 0
          ? callback(
              results.map((i) => {
                return {
                  label: i.name,
                  value: i.id,
                };
              })
            )
          : callback([]);
      })
      .catch((error) => {
        toast.error("❌ Error searching for provider");
        callback([]);
      });
  }, 3000);

  const handleFilter = () => {
    if (
      selectedStates.length === 0 &&
      selectedLGAs.length === 0 &&
      selectedCourse.length === 0 &&
      !selectedProvider &&
      !selectedCohorts &&
      !selectedBranch
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        cohort_id: selectedCohorts?.value || "",
        selectedStates: selectedStates,
        selectedLGAs: selectedLGAs,
        selectedCourse: selectedCourse,
        provider_id: selectedProvider?.value || "",
        branch_id: selectedBranch?.value || "",
      });
    }
  };

  const getLGData = () => {
    const LGs = [];
    selectedStates.forEach((state) => {
      LocalGov?.forEach((val) => {
        if (val?.state?.toLowerCase() === state.value?.toLowerCase()) {
          LGs.push(...val?.lgas);
        }
      });
    });

    return LGs.length > 0
      ? LGs.map((state) => ({
          value: state,
          label: state,
        }))
      : [];
  };

  const StateOptions = Nigerian_States.map((state) => ({
    value: state,
    label: state,
  }));

  const CohortOptions =
    data?.data?.cohorts?.length > 0
      ? data?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  const CourseOptions = courses?.data?.courses?.length > 0 ? courses?.data?.courses?.map((course) => ({
    value: course?.id,
    label: course?.title
  })) : [];

  const BranchOptions = branches?.data?.results?.length > 0 ? branches.data.results.map((branch) => ({
    value: branch.id,
    label: branch.branch_name
  })) : [];

  return (
    <Modal
      show={showModal}
      onHide={() => setShowFilter(false)}
      className="modal fade"
      id="postModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Filter Matches</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowFilter(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="basic-form">
            <form onSubmit={(e) => e.preventDefault()}>
              <Col md={12}>
                <p className="p-1 m-0">Filter with Provider</p>
                <div className="form-group mb-3">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={(inputValue, callback) =>
                      debouncedLoadOptions(inputValue, callback)
                    }
                    onChange={handleProviderChange}
                    value={selectedProvider}
                    placeholder={<div>Type name to search</div>}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    styles={Style}
                  />
                </div>
              </Col>
              {selectedProvider && (
                <Col md={12}>
                  <p className="p-1 m-0">Select Provider Branch</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedBranch}
                      onChange={setSelectedBranch}
                      options={BranchOptions}
                      styles={Style}
                      isLoading={branchesLoading}
                      placeholder={branchesLoading ? "Loading branches..." : "Select a branch"}
                    />
                  </div>
                </Col>
              )}
              {CourseOptions.length > 0 && (
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Course</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedCourse}
                      onChange={setCourses}
                      options={CourseOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>
              )}
              {CohortOptions?.length > 0 && (
                <Col md={12}>
                  <p className="p-1 m-0">Filter by Cohort</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedCohorts}
                      onChange={setCohorts}
                      options={CohortOptions}
                      styles={Style}
                    />
                  </div>
                </Col>
              )}
              <Col md={12}>
                <p className="p-1 m-0">Filter by State of Residence</p>
                <div className="form-group mb-3">
                  <Select
                    value={selectedStates}
                    onChange={setStates}
                    options={StateOptions}
                    isMulti
                    styles={Style}
                  />
                </div>
              </Col>
              <Col md={12}>
                <p className="p-1 m-0">Filter by LGAs</p>
                <div className="form-group mb-3">
                  <Select
                    value={selectedLGAs}
                    onChange={setLGAs}
                    options={getLGData()}
                    isMulti
                    styles={Style}
                  />
                </div>
              </Col>
            </form>
          </div>

          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              className="ms-2 me-2 py-3 btn-pigment-green-outline "
              style={{ width: "100% !important" }}
              type="button"
              onClick={resetAllFilters}
            >
              Clear Filter
            </Button>

            <Button
              onClick={handleFilter}
              type="button"
              className="ms-2 pull-right py-3 btn-pigment-green"
            >
              {filtering ? "Filtering ...." : "Filter Matches"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;