/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize, truncateString } from "utils/strings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import { useDeleteAdminFAQsMutation } from "store/services/adminAPI";
import { ReactComponent as MoreIcon } from "images/svg/more.svg";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useUpdateAdminFAQsMutation } from "store/services/adminAPI";

const AdminFAQsTable = ({ messages, metadata, setFilter, setSkip, types }) => {
  useEffect(() => {
    setSkip(true);
  }, [messages, setSkip]);

  const FAQ_COLUMNS = [
    {
      Header: "ID",
      accessor: "id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(truncateString(cell?.row?.original?.id))}`;
      },
    },
    {
      Header: "Body",
      accessor: "question",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(truncateString(cell?.row?.original?.question))}`;
      },
    },
    {
      Header: "Answer",
      accessor: "answer",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(truncateString(cell?.row?.original?.answer))}`;
      },
    },

    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const [showProfile, setShowProfile] = useState(false);
        const [update, setUpdate] = useState(false);
        const { question, answer, id } = cell?.row?.original;

        const [deleteAdminFAQs] = useDeleteAdminFAQsMutation();
        const [updateAdminFAQs, { isLoading }] = useUpdateAdminFAQsMutation();

        const handleSubmit = async (values, { resetForm }) => {
          await updateAdminFAQs({
            id,
            ...values,
          })
            .unwrap()
            .then((data) => {
              setSkip(false);
              toast.success(`✔️ ${data?.message} !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
              });
              setUpdate(false);
            })
            .catch((error) => {
              Swal.fire({
                title:
                  replaceUnderscoresWithSpaces(error?.data?.code) ||
                  "Error Occured",
                text: error?.data?.detail,
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        const formik = useFormik({
          initialValues: {
            faq_type_id: id,
            question,
            answer,
          },
          validationSchema: FormSchema,
          onSubmit: handleSubmit,
          enableReinitialize: true,
        });

        const _types =
          types?.data && types?.data?.length > 0
            ? types?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))
            : [];

        return (
          <>
            {/* Profile Modal */}
            <Modal
              show={showProfile}
              onHide={() => setShowProfile(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{capitalize(id)}</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowProfile(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <div className="col-xl-12">
                    <div className="card  course-dedails-bx">
                      <div className="card-header border-0 pb-0">
                        <h2>{capitalize(question)}</h2>
                      </div>
                      <div className="card-body pt-0">
                        <div className="description">
                          <p>{capitalize(answer)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100 justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      onClick={(e) => setShowProfile(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Profile Modal */}

            {/* Update Modal */}
            <Modal
              show={update}
              onHide={() => setUpdate(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update FAQ</h5>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setUpdate(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <form className="comment-form" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Type</strong>
                          </label>

                          <select
                            id="faq_type_id"
                            name="faq_type_id"
                            className="form-control"
                            {...formik.getFieldProps("faq_type_id")}
                          >
                            <option value="">Select type</option>

                            {_types
                              ? _types.map((_) => {
                                  return (
                                    <option value={_.value}>{_.label}</option>
                                  );
                                })
                              : null}
                          </select>

                          {formik.errors.faq_type_id ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.faq_type_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Question</strong>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="question"
                            name="question"
                            placeholder="Title"
                            {...formik.getFieldProps("question")}
                          />

                          {formik.touched.question && formik.errors.question ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.question}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Answer</strong>
                          </label>

                          <textarea
                            className="w-100 form-control"
                            placeholder="Your answer"
                            id="answer"
                            name="answer"
                            {...formik.getFieldProps("answer")}
                            rows="3"
                          ></textarea>

                          {formik.touched.answer && formik.errors.answer ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.answer}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12 d-flex justify-content-end">
                        <div className="form-group mb-3">
                          <button
                            type="submit"
                            className="submit btn btn-pigment-green"
                          >
                            {isLoading ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              `Update FAQ`
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
            {/* Update Modal */}

            <Dropdown className="dropdown ms-auto text-right">
              <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
              >
                <MoreIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                <Dropdown.Item onClick={() => setShowProfile(true)}>
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setUpdate(true)}>
                  Update
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={async () =>
                    Swal.fire({
                      title: "Notice",
                      iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                      text: "Are you sure you want to delete this record?",
                      showCloseButton: true,
                      customClass: {
                        icon: "no-border",
                      },
                      cancelButtonText: "Delete",
                      showCancelButton: true,
                      confirmButtonText: "Cancel",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(async (result) => {
                      if (!result.isConfirmed) {
                        await deleteAdminFAQs(id)
                          .unwrap()
                          .then((data) => {
                            setSkip(false);
                            toast.success(`✔️ ${data?.message} !`, {
                              position: "top-right",
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                            });
                          })
                          .catch((error) => {
                            Swal.fire({
                              title:
                                replaceUnderscoresWithSpaces(
                                  error?.data?.code
                                ) || "Error Occured",
                              text: error?.data?.detail,
                              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                              showCloseButton: true,
                              customClass: {
                                icon: "no-border",
                              },
                              confirmButtonText: "Try Again",
                            });
                          });
                      }
                    })
                  }
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => FAQ_COLUMNS, []);
  const data = useMemo(() => messages, [messages]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title text-pigment-green">All FAQs</h4>
        </div>
        <div className="card-body">
          {data?.length > 0 ? (
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                  <button
                    className={`btn btn-outline ${hasPrev ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page - 1) || 1;
                      setFilter(`page=${_newPage}`);
                    }}
                  >
                    Previous
                  </button>

                  {metadata?.count && (
                    <div className="d-flex">
                      <p className="mb-0">
                        Page <strong>{metadata?.page}</strong> of{" "}
                        <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                        &nbsp;
                      </p>
                      <p className="mb-0">
                        Total <strong>{metadata?.count}</strong>
                      </p>
                    </div>
                  )}

                  <button
                    className={`btn btn-outline ${hasNext ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page || 0) + 1;
                      setFilter(`page=${_newPage}`);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon  text-primary">
                <BagIcon />
                <h4 className="my-2">No faqd for the specified filter</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminFAQsTable;

const FormSchema = Yup.object().shape({
  faq_type_id: Yup.string().required("Type is required"),
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answeris required"),
});
