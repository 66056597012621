/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    onboardUser: builder.mutation({
      query: (body) => ({
        url: "admin/auth/onboard/portal",
        method: "POST",
        body,
      }),
    }),
    registerUserEmail: builder.mutation({
      query: (body) => ({
        url: "auth/register/session/initialize",
        method: "POST",
        body,
      }),
    }),
    setUserPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/set_password",
        method: "POST",
        body,
      }),
    }),
    verifyUserOTP: builder.mutation({
      query: (body) => ({
        url: "auth/register/session/confirm",
        method: "POST",
        body,
      }),
    }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: "admin/auth/login",
        method: "POST",
        body,
      }),
    }),
    verifyUser: builder.query({
      query: (reference) => `payments/verify/${reference}`,
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "auth/forgot_password/initialize",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "auth/forgot_password/confirm",
        method: "POST",
        body,
      }),
    }),

    resendOTP: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/otp/resend",
        method: "POST",
        body,
      }),
    }),
    sendOTPasPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/otp/resend/sms",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useRegisterUserEmailMutation,
  useSetUserPasswordMutation,
  useVerifyUserOTPMutation,
  useLoginUserMutation,
  useVeifyUserQuey,
  useForgotPasswordMutation,
  useResetPasswordMutation,

  useResendOTPMutation,
  useSendOTPasPasswordMutation,
  useOnboardUserMutation
} = authApi;
