/** @format */

import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { GlobalFilter } from "./helpers/GlobalFilter";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";

export const AdminWebinarsTable = ({ webinars }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => webinars, [webinars]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    // gotoPage,
    // pageCount,
    pageOptions,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    setGlobalFilter,
    // page,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title text-pigment-green">Webinars</h4>
        </div>
        <div className="card-body">
          {data.length > 0 ? (
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <div className="d-flex justify-content-end">
                  <div className="search--box">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>

                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon  text-primary">
                <BagIcon />
                <h4 className="my-2">No webinars for the specified filter</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AdminWebinarsTable;
