/** @format */
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { extractErrorMessage, ProviderOptions } from "utils/strings";
import { toast } from "react-toastify";
import { useBulkUpdateProviderStatusMutation } from "store/services/adminAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";

export const StatusModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
}) => {
  const [status, setStatus] = useState("");
  const [bulkUpdateProviderStatus, { isLoading }] =
    useBulkUpdateProviderStatusMutation();

  const handleSubmit = async () => {
    await bulkUpdateProviderStatus({
      status: status,
      providers: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedIds([]);
        setAction("");
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <Modal
      show={action === "update_status" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Provider Details</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="card  course-dedails-bx">
              <div className="card-header border-0 pb-0">
                <h5>Update Provider(s) Status</h5>
              </div>
              <div className="card-body pt-0">
                <div className="description">
                  <select
                    className="form-control bulk-select"
                    id="inputState"
                    defaultValue="option-1"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {ProviderOptions.map((_fellow) => {
                      return (
                        <option value={_fellow?.value}>{_fellow?.label}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              type="button"
              disabled={
                status?.length > 0 && selectedIds?.length > 0 ? false : true
              }
              onClick={() => {
                Swal.fire({
                  title: "Confirm Action",
                  text: "Are you sure you want to proceed with this bulk action?",
                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                  showCloseButton: true,
                  customClass: {
                    icon: "no-border",
                  },

                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: "Proceed",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleSubmit();
                  }
                });
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Update Status`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
