/** @format */

import React from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { useCreateCourseMutation } from "store/services/notification";

const CreateCourse = ({ createResource, setCreate }) => {
  const [createCourse, { isLoading }] = useCreateCourseMutation();

  const handleSubmit = async (values, { resetForm }) => {
    await createCourse({
      ...values,
      link: "https://learn.saylor.org/course/CS101",
      duration: 12,
      modules: [],
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ Course Created!`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      code: "",
      description: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal className="modal fade" show={createResource}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create New Course</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Title</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Course Title"
                    {...formik.getFieldProps("title")}
                  />

                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Code</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    placeholder="Course Code"
                    {...formik.getFieldProps("code")}
                  />

                  {formik.touched.code && formik.errors.code ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.code}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong> Learning Content Link</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    placeholder="Course Link"
                    {...formik.getFieldProps("link")}
                  />

                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.link}
                    </div>
                  ) : null}
                </div>
              </div> */}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Description</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Course description"
                    id="description"
                    name="description"
                    {...formik.getFieldProps("description")}
                    rows="3"
                  ></textarea>

                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create  Course`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCourse;

const FormSchema = Yup.object().shape({
  title: Yup.string().required("Course title is required"),
  code: Yup.string().required("Course code is required"),
  description: Yup.string().required("Course description is required"),
});
