/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import loginbg from "images/bg-1.svg";
import { useOnboardUserMutation } from "store/services/authAPI";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import MMLogo from "images/3MTT/3MM.png";
import { Path } from "utils/constant.data";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

const AdminOnboardPage = () => {
  const navigate = useNavigate();

  const [isShown, setIsSHown] = useState(false);
  const [show, setShow] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invitation_code = searchParams.get("invitation_code");

  const [onboardUser, { isLoading: authenticating }] = useOnboardUserMutation();

  const handleSubmit = async (values) => {
    if (invitation_code) {
      await onboardUser({
        ...values,
        invitation_code,
      })
        .unwrap()
        .then((data) => {
          Swal.fire({
            title: "Notice",
            icon: "success",
            text: data?.detail,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
          }).then(() => {
            navigate(Path.LOGIN);
          });
        })
        .catch((error) => {
          const formattedError = extractErrorMessage(error)
          const btnText = "Try Again";

          Swal.fire({
            title: error?.data?.code
              ? replaceUnderscoresWithSpaces(error?.data?.code)
              : "Error Occured",
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            text: formattedError || "Error Occured",
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            cancelButtonText: btnText,
            showConfirmButton: false,
            showCancelButton: true,
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        iconHtml: `<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        text: "Invalid code",
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        cancelButtonText: "Ok",
        showConfirmButton: false,
        showCancelButton: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: OnboardSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>

                      <h4 className="text-center mb-4 ">Admin Onboarding</h4>

                      <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Email </strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="e.g. example@domainname.com"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>First Name</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            {...formik.getFieldProps("first_name")}
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Last Name</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            {...formik.getFieldProps("last_name")}
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group mb-3">
                          <label>
                            {" "}
                            <strong>Password</strong>
                          </label>

                          <div className="password__container">
                            <input
                              type={isShown ? "text" : "password"}
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                              {...formik.getFieldProps("password")}
                            ></input>
                            <button type="button" onClick={togglePassword}>
                              <div class="icon">
                                {isShown ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </div>
                            </button>
                          </div>

                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>

                        <div className="form-group mb-3">
                          <label>
                            {" "}
                            <strong>Confirm Password</strong>
                          </label>

                          <div className="password__container">
                            <input
                              type={show ? "text" : "password"}
                              id="confirm_password"
                              name="confirm_password"
                              placeholder="Enter password"
                              {...formik.getFieldProps("confirm_password")}
                            ></input>
                            <button
                              type="button"
                              onClick={() => setShow((show) => !show)}
                            >
                              <div class="icon">
                                {show ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </div>
                            </button>
                          </div>

                          {formik.touched.confirm_password &&
                          formik.errors.confirm_password ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.confirm_password}
                            </div>
                          ) : null}
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {authenticating ? "Onboarding....." : "Onboard"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOnboardPage;

const OnboardSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm Password is Required"),
});
