import React from 'react';
import ReactApexChart from 'react-apexcharts';

const options = {
  legend: { show: false },
  chart: { height: 350, type: 'treemap' },
  plotOptions: {
    treemap: {
      colorScale: {
        ranges: [
          { from: 0, to: 50, color: '#50B172' },
          { from: 51, to: 5000, color: '#26A65B' }
        ]
      }
    }
  }
};




const ProviderFellowsChart = ({provider_fellows_data}) => {
  const filteredData = provider_fellows_data.filter(item => item.name !== null);

    const series = [
        {
          name: "series-1",
          data: filteredData
        }
      ];
  return (
    <div>
      {
        filteredData.length > 0 ? (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="treemap" height={500} />
    </div>
           ): (
            <p>No data available under this category</p>
          )
        }
      </div>
  );
};

export default ProviderFellowsChart;