/** @format */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "context/ThemeContext";
import { Path } from "utils/constant.data";
import { getLocalStorage } from "utils/secure";

import MMLogo from "images/3MTT/main.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);
  // const user = JSON.parse(window.localStorage.getItem("3MTAUSER")) || null;
  const user = getLocalStorage("3MTAUSER") || null;

  return (
    <div className="nav-header">
      {user && (
        <Link
          to={
            user?.account_type === "ADMIN" ? Path.ADMIN_DASHBOARD : Path.LOGIN
          }
          className="brand-logo jc"
        >
          <figure className="main-logo">
            <img src={MMLogo} alt="" />
          </figure>
        </Link>
      )}

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
